<template>
  <div
    v-if="statusData.message"
    class="tw-font-bold tw-inline-block !tw-mb-0"
  >
    <div
      :style="`
        background: ${statusData.color};
        color: white;
      `"
      class="tw-inline-block tw-px-4 tw-py-0.5 tw-border tw-rounded tw-shadow-sm"
    >
      {{ statusData.message }}
      <a
        v-if="statusData.url"
        :href="statusData.url"
        target="_blank"
      >
        <i class="tw-ml-1 fa fa-link tw-text-white" />
      </a>
    </div>
  </div>
</template>

<script>
import { getApplicationStatus } from '@/services/apiService'

let fetchApplicationStatusInterval

export default {
  name: 'ApplicationStatus',
  data () {
    return {
      statusData: {}
    }
  },
  created () {
    this.init()
  },
  beforeDestroy () {
    clearInterval(fetchApplicationStatusInterval)
  },
  methods: {
    async init () {
      const response = this.fetchApplicationStatus()
      // Do not fetch application status periodically locally
      if (!['qa', 'prod'].includes(process.env.VUE_APP_ENVIRONMENT)) return response
      fetchApplicationStatusInterval = setInterval(() => {
        this.fetchApplicationStatus()
      }, 60 * 5 * 1000) // Retrieve application status every 5 minutes
      return response
    },
    async fetchApplicationStatus () {
      const response = await getApplicationStatus()
      this.statusData = response.data
      return response
    }
  }
}
</script>
