<template>
  <div :key="uniqueKey" class="tw-relative tw-px-6 tw-py-4 tw-bg-white tw-rounded tw-shadow-lg">
    <slot name="toolbar" />
    <div v-if="canSearch" class="tw-mb-2 tw-flex tw-flex-wrap sm:tw-flex-row tw-gap-6">
      <div class="tw-py-2 tw-flex tw-flex-row tw-flex-grow tw-border-b">
        <i class="tw-py-1 tw-mr-2.5 tw-text-gray-200 far fa-search" />
        <input
          type="text"
          title="Zoeken"
          :placeholder="searchPlaceholder"
          class="tw-w-full tw-outline-none"
          @input="debouncedFilter"
        />
      </div>
      <slot name="filters" :filter-by="filterBy" />
    </div>

    <div v-if="infiniteScroll && showResultCount && !loading && results.length" class="tw-flex tw-justify-end">
      {{ count }} {{ count === 1 ? 'resultaat' : 'resultaten' }}
    </div>

    <div :class="{ 'tw-overflow-auto': responsive }">
      <div v-if="loading" class="tw-absolute tw-h-10 tw-top-1/2 tw-left-1/2">
        <i
          class="fal fa-spinner-third fa-spin tw-text-3xl"
        />
      </div>
      <table :class="['tw-w-full tw-table-auto tw-border-collapse', { 'tw-opacity-40 tw-pointer-events-none': loading }]">
        <thead class="tw-text-sm">
          <tr>
            <th v-if="canSelect">
              <input v-show="selectAllByQuery || (count < maxSelectAllAmount && !selectRecordObject)" v-model="allSelected" type="checkbox" />
            </th>
            <th v-for="(header, index) in headers" :key="index" class="tw-text-sm">
              <button
                :disabled="!header.orderable"
                :class="['tw-whitespace-nowrap', { 'hover:tw-opacity-60': header.orderable }]"
                @click="orderBy(headerOrderKey(header))"
              >
                {{ header.text || header.value }}
                <i v-if="params.ordering === headerOrderKey(header)" :class="'fa fa-arrow-up'" />
                <i v-if="params.ordering === `-${headerOrderKey(header)}`" :class="'fa fa-arrow-down'" />
              </button>
            </th>
          </tr>
        </thead>
        <tbody class="tw-text-sm">
          <template v-for="(result, index) in results">
            <tr
              :key="index"
              :draggable="dragAndDrop"
              :class="[
                { 'tw-cursor-move': dragAndDrop },
                { ' tw-bg-success tw-bg-opacity-10': (index + 1) % 2 === 0 },
                'tw-border-b tw-border-gray-200 hover:tw-bg-gray-cc hover:tw-bg-opacity-25'
              ]"
              @dragstart="onDragStart($event, result)"
            >
              <td v-show="canSelect">
                <input
                  type="checkbox"
                  v-model="selectedItems"
                  :disabled="returnQueryNotSelections"
                  :value="selectRecordObject ? result : result.id"
                />
              </td>
              <td v-for="(header, index) in headers" :key="index">
                <slot :name="`item-${header.value}`" :item="result" :index="index">
                  <component
                    :is="header.renderComponent || 'div'"
                    :object="result"
                    @action="$emit('action')"
                  >
                    {{ get(result, header.value) || '-' }}
                  </component>
                </slot>
              </td>
            </tr>
            <slot name="record-extend" :record="result" />
          </template>
        </tbody>
      </table>
    </div>
    <div v-if="!loading" class="tw-mt-4 md:tw-grid md:tw-grid-cols-3 tw-gap-4">
      <InfiniteLoading
        v-if="infiniteScroll"
        :identifier="infiniteKey"
        spinner="waveDots"
        class="tw-col-span-3"
        @infinite="infiniteLoad"
      >
        <div slot="no-more"><!-- Empty div to not render anything --></div>
        <template #no-results>Geen resultaten gevonden</template>
        <template #error="{ trigger }">
          Kan records niet laden,
          <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
            probeer het opnieuw.
          </button>
        </template>
      </InfiniteLoading>
      <template v-else>
        <div class="tw-flex-grow md:tw-text-left">
          <!-- We need an empty div to atleast maintain the layout of 3 items. -->
          <template v-if="canSelect">
            <span>{{ selectedItems.length }} items geselecteerd</span><br>
            <template v-if="selectAllByQuery || (count < maxSelectAllAmount && !selectRecordObject)">
              <button type="button" class="link tw-font-semibold" @click="selectAll">
                Alle items selecteren
              </button>
              -
            </template>
            <button type="button" class="link tw-font-semibold" @click="clearSelection">
              Selectie leegmaken
            </button>
          </template>
        </div>
        <div v-if="paginate" class="tw-flex tw-flex-grow md:tw-text-right tw-justify-center tw-items-center tw-mt-8 md:tw-mt-0">
          <nav class="tw-flex tw-flex-row tw-space-x-3 md:tw-justify-center">
            <button
              type="button"
              title="Ga naar de vorige pagina"
              :disabled="!previous"
              class="page-button"
              @click="changePage(previous)"
            >
              <i class="fa fa-chevron-left" />
            </button>
            <template v-if="current_page - 1 > 1">
              <button
                type="button"
                :disabled="!first"
                class="page-button"
                @click="changePage(first)"
              >
                1
              </button>
              <!-- If current page is 3, show 1, 2, 3 -->
              <span v-if="current_page !== 3" class="tw-mx-2">...</span>
            </template>
            <button
              v-for="page in pages"
              type="button"
              :key="page.num"
              :disabled="page.active"
              :class="['page-button', { 'page-active': page.active }]"
              @click="changePage(page.url)"
            >
              {{ page.num }}
            </button>
            <template v-if="num_pages - current_page > 1">
              <!-- If current page is 1, show 1, 2, 3 -->
              <span v-if="num_pages !== current_page + 2" class="tw-mx-2">...</span>
              <button
                type="button"
                :disabled="!last"
                class="page-button"
                @click="changePage(last)"
              >
                {{ num_pages }}
              </button>
            </template>
            <button
              type="button"
              title="Ga naar de volgende pagina"
              :disabled="!next"
              class="page-button"
              @click="changePage(next)"
            >
              <i class="fa fa-chevron-right" />
            </button>
          </nav>
        </div>
        <div class="tw-flex tw-justify-center md:tw-justify-end tw-items-center tw-mt-4 md:tw-mt-0 tw-flex-grow">
          <span v-if="results.length === 0">Geen resultaten gevonden</span>
          <span v-else>{{ count }} {{ count === 1 ? 'resultaat' : 'resultaten' }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import get from 'lodash/get'
import debounce from 'lodash/debounce'
import uniqueId from 'lodash/uniqueId'
import InfiniteLoading from 'vue-infinite-loading'

export default {
  name: 'DataTable',
  components: {
    InfiniteLoading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    results: {
      type: Array,
      default: () => []
    },
    headers: {
      type: Array,
      required: true
    },
    paginate: {
      type: Boolean,
      default: true
    },
    canSelect: {
      type: Boolean,
      default: false
    },
    canSearch: {
      type: Boolean,
      default: false
    },
    searchPlaceholder: {
      type: String,
      default: 'Zoeken'
    },
    count: {
      type: Number
    },
    current_page: {
      type: Number
    },
    num_pages: {
      type: Number
    },
    first: {
      type: String
    },
    previous: {
      type: String
    },
    next: {
      type: String
    },
    last: {
      type: String
    },
    allRecordIds: {
      type: Array
    },
    dragAndDrop: {
      type: Boolean,
      default: false
    },
    responsive: {
      type: Boolean,
      default: true
    },
    selectRecordObject: {
      // By default, each selection in the table is the record's id
      // If set to true, each selection in the table will correspond to the whole object.
      // WARNING: Select all across the table will no longer work in this case.
      type: Boolean,
      default: false
    },
    selections: {
      type: [Array, Boolean, Object],
      default: () => []
    },
    infiniteScroll: {
      type: Boolean,
      default: false
    },
    selectAllByQuery: {
      // Disables individual options selection beyond maxSelectAllAmount, select all sends the params
      type: Boolean,
      default: false
    },
    maxSelectAllAmount: {
      type: Number,
      default: 50
    },
    showResultCount: {
      type: Boolean,
      default: true
    },
    infiniteKey: {
      // identifier used to set/reset the infinite loader, useful when we re-fetch the entire data again, and want to re-fetch pages.
      type: String,
      default: uniqueId('infinite_table_')
    },
    minSearchChars: {
      type: Number,
      default: null
    }
  },
  model: {
    prop: 'selections',
    event: 'selected'
  },
  data () {
    return {
      params: {},
      allSelected: false
    }
  },
  watch: {
    allSelected (val) {
      val ? this.selectAll() : this.clearSelection()
    },
    selectedItems: debounce(
      function (val) {
        if (this.returnQueryNotSelections) return
        // val.length is checked for separately to make sure we don't match undefined === undefined, or 0 === 0
        if (val.length && (val.length === this.allRecordIds?.length)) this.allSelected = true
      },
      300
    ),
    allRecordIds () {
      this.clearSelection()
    }
  },
  computed: {
    uniqueKey () {
      return uniqueId('table_')
    },
    selectedItems: {
      get () {
        return this.returnQueryNotSelections ? this.allSelected : this.selections
      },
      set (value) {
        this.$emit('selected', value)
      }
    },
    filterKeys () {
      const filterKeys = []
      this.headers.forEach(header => {
        if (header.filterable) filterKeys.push(header.filterByKey || header.value)
      })
      return filterKeys.length ? filterKeys : ['query']
    },
    startPage () {
      // When on the first page
      if (this.current_page === 1) return 1

      // When on the last page, we should only show 2 pages, last and second-last page because we don't have n-2 url
      if (this.current_page === this.num_pages) return this.num_pages - 1

      // When inbetween
      return this.current_page - 1
    },
    pages () {
      const pages = []
      for (
        let num = this.startPage;
        // When on the first page, show only the first and second page, because we don't have n+2 url
        num <= Math.min(this.current_page === 1 ? 2 : this.startPage + 2, this.num_pages);
        num++
      ) {
        pages.push({
          num,
          active: num === this.current_page,
          url: this.getPageUrl(num)
        })
      }
      return pages
    },
    returnQueryNotSelections () {
      return this.selectAllByQuery && this.count >= this.maxSelectAllAmount && this.allSelected
    }
  },
  methods: {
    get, // Used to access value of deep nested keys
    headerOrderKey (header) {
      return header.orderByKey || header.value
    },
    orderBy (key) {
      const params = this.params

      switch (params.ordering) {
        case key:
          this.$set(params, 'ordering', `-${key}`) // descending order
          break

        case `-${key}`:
          this.$delete(params, 'ordering') // no order
          break

        default:
          this.$set(params, 'ordering', key) // ascending order
          break
      }

      this.$emit('changedOrder', { params })
    },
    debouncedFilter: debounce(
      function (event) {
        const queryValue = event.target.value.trim()
        // The query value is valid in case the value is empty or the value length is higher than the defined minimum
        const isQueryValueValid = queryValue.length === 0 || queryValue.length >= this.minSearchChars
        if (isQueryValueValid) this.filterBy(this.filterKeys, queryValue)
      },
      1000
    ),
    filterBy (keys, value) {
      keys.forEach(key => {
        this.$set(this.params, key, value)
      })
      this.$emit('filter', { params: this.params })
    },
    getPageUrl (num) {
      switch (num) {
        case 1:
          return this.first
        case this.num_pages:
          return this.last
        case this.current_page - 1:
          return this.previous
        case this.current_page + 1:
          return this.next
        default:
          return null
      }
    },
    changePage (url) {
      this.$emit('changePage', { url })
    },
    selectAll () {
      this.allSelected = true
      if (this.returnQueryNotSelections) this.selectedItems = { ...this.params }
      else this.selectedItems = this.allRecordIds || this.results
      this.$emit('allSelected', true)
    },
    clearSelection () {
      this.selectedItems = []
      this.allSelected = false
      this.$emit('allSelected', false)
    },
    onDragStart (event, item) {
      event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.effectAllowed = 'move'
      event.dataTransfer.setData('itemId', item.id)
    },
    infiniteLoad ($state) {
      this.$emit('infiniteLoad', $state, this.next)
    }
  }
}
</script>

<style scoped>
th, td {
  /* align-top is to avoid the items centering vertically when other cells have a lot of content */
  @apply !tw-px-2 !tw-py-2 tw-align-top tw-whitespace-pre-wrap;
}
.page-button {
  @apply tw-px-1.5 tw-border-2 tw-rounded tw-font-semibold tw-text-success tw-border-success disabled:tw-text-gray-200 disabled:tw-border-gray-200 hover:tw-opacity-60;
}
.page-active {
  @apply !tw-border-success tw-bg-success tw-text-white tw-pointer-events-none;
}
</style>
