/**
 * properties.js
 * Property store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */
import $ from 'jquery'
import Vue from 'vue'
import { ProjectApi } from '@/api/properties'
import {
  getPropertiesGroup,
  getPropertyCollaborators
} from '@/services/properties'
import {
  getProjectCollaborators,
  getProjectById
} from '@/services/projects'
// Initial state
const state = {
  groups: null,

  properties: {},
  propertyCollaborators: {},
  propertyDescriptions: {},
  propertyMedia: {},
  propertyPictures: {},
  propertyCommunication: {},
  propertySignaturePackages: {},
  propertyContentBlocks: {},

  projects: {},
  projectCollaborators: {},
  projectDescriptions: {},
  projectMedia: {},
  projectPictures: {},

  projectQueryData: [],

  renterProperties: null,
  hmodhPropertiesForProperty: {},
  hmodhPropertiesForHmodh: {}
}

// Getters
const getters = {
  getPropertyById: (state) => (id) => {
    return state.properties[parseInt(id)]
  },
  getPropertyCollaborators: (state) => (id) => {
    return state.propertyCollaborators[parseInt(id)]
  },
  getPropertyDescriptions: (state) => (id) => {
    return state.propertyDescriptions[parseInt(id)] || []
  },
  getPropertySignaturePackages: (state) => (id) => {
    return state.propertySignaturePackages[parseInt(id)] || null
  },
  getPropertyTitle: (state) => (id, language) => {
    const descriptions = state.propertyDescriptions[parseInt(id)] || null
    if (descriptions) {
      const titleWebDescription = descriptions.filter((description) => {
        return description.medium === 0 && description.type === 0
      })
      if (titleWebDescription) {
        return titleWebDescription[0].text[`text_${language.toLowerCase()}`]
      }
    }
    return null
  },
  getPropertyDescription: (state) => (id, language) => {
    const descriptions = state.propertyDescriptions[parseInt(id)] || null
    if (descriptions) {
      const titleWebDescription = descriptions.filter((description) => {
        return description.medium === 0 && description.type === 1
      })
      if (titleWebDescription) {
        return titleWebDescription[0].text[`text_${language.toLowerCase()}`]
      }
    }
    return null
  },
  getEntityMedia: (state) => (payload) => {
    if (payload.entityType === 'property') {
      return state.propertyMedia[parseInt(payload.id)] || null
    } else {
      return state.projectMedia[parseInt(payload.id)] || null
    }
  },
  getPropertyPictures: (state) => (id) => {
    return state.propertyPictures[parseInt(id)]
  },
  getProjectById: (state) => (id) => {
    return state.projects[parseInt(id)]
  },
  getProjectCollaborators: (state) => (id) => {
    return state.projectCollaborators[parseInt(id)]
  },
  getProjectDescriptions: (state) => (id) => {
    return state.projectDescriptions[parseInt(id)] || []
  },
  getProjectPictures: (state) => (id) => {
    return state.projectPictures[parseInt(id)]
  },
  /* Get all rental properties for a contact */
  getRenterPropertiesById: (state) => (contactId) => {
    if (state.renterProperties) {
      const propertyStore = []
      for (const propertyId of Object.keys(state.renterProperties)) {
        const renterId = parseInt(state.renterProperties[parseInt(propertyId)])
        if (parseInt(contactId) === renterId) {
          const property = state.properties[parseInt(propertyId)]
          propertyStore.push(property)
        }
      }
      return propertyStore
    }
    return state.renterProperties
  }
}

// Actions
const actions = {
  // Property Group
  getGroups ({ commit }) {
    return new Promise((resolve, reject) => {
      getPropertiesGroup()
        .then(response => {
          const groups = response.data.results
          commit('setGroups', { groups })
          resolve(groups)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  createGroup ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/v3/property-groups',
        type: 'POST',
        traditional: true,
        data: payload
      }).done((group) => {
        commit('setGroup', group)
        resolve(group)
      }).fail((error) => {
        reject(error)
      })
    })
  },

  editGroup ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/v3/property-groups/' + payload.id,
        type: 'PATCH',
        traditional: true,
        data: payload
      }).done(group => {
        commit('setGroup', group)
        resolve(group)
      }).fail((error) => {
        reject(error)
      })
    })
  },

  /****************************
   *        Properties        *
   ****************************/

  /**
   * Get a Property by it's ID
   * @param {str} id: The id for the property
   * @returns {Promise<Property>}
   */
  loadProperty ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/property/${id}`
      }).done(property => {
        commit('setProperty', property)
        resolve(property)
      }).fail(error => {
        reject(error)
      })
    })
  },

  loadPropertyDescriptions ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/property/${id}/descriptions`,
        dataType: 'json'
      }).done(descriptions => {
        commit('setPropertyDescriptions', { id: id, descriptions: descriptions.results })
        resolve(descriptions)
      }).fail(error => {
        reject(error)
      })
    })
  },

  loadPropertyCollaborators ({ commit }, id) {
    return new Promise((resolve, reject) => {
      const payload = {
        propertyId: id,
        params: {
          include_inactive: 1
        }
      }
      getPropertyCollaborators(payload)
        .then(response => {
          const collaborators = response.data?.results
          commit('setPropertyCollaborators', { id, collaborators })
          resolve(collaborators)
        }).catch(error => {
          reject(error)
        })
    })
  },

  loadEntityMedia ({ commit }, payload) {
    let url = null
    if (payload.entityType === 'property') {
      url = `/api/v3/property/${payload.id}/media`
    } else {
      url = `/api/v3/project/${payload.id}/media`
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url
      }).done(mediaObjects => {
        commit('setEntityMedia', { id: payload.id, entityType: payload.entityType, media: mediaObjects.results })
        resolve(mediaObjects.results)
      }).fail(error => {
        reject(error)
      })
    })
  },
  saveEntityMedia ({ commit }, payload) {
    const data = new FormData()
    data.append('type', payload.type)
    if (payload.file) {
      data.append('file', payload.file)
    }
    if (payload.supplier) {
      data.append('supplier', payload.supplier)
    }
    if (payload.identifier) {
      data.append('identifier', payload.identifier)
    }
    let url = null
    if (payload.entityType === 'property') {
      url = `/api/v3/property/${payload.entity}/media`
    } else {
      url = `/api/v3/project/${payload.entity}/media`
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: url,
        data: data,
        contentType: false,
        processData: false,
        traditional: true
      }).done(mediaObjects => {
        commit('setEntityMedia', { id: payload.entity, entityType: payload.entityType, media: mediaObjects })
        resolve(mediaObjects)
      }).fail(error => {
        reject(error)
      })
    })
  },
  updateEntityMedia ({ commit }, payload) {
    let url = null
    if (payload.entityType === 'property') {
      url = `/api/v3/property/${payload.entityId}/media/${payload.mediaId}`
    } else {
      url = `/api/v3/project/${payload.entityId}/media/${payload.mediaId}`
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'PATCH'
      }).done(media => {
        commit('updateEntityMedia', {
          entityId: payload.entityId,
          entityType: payload.entityType,
          media: media
        })
        resolve(media.results)
      }).fail(error => {
        reject(error)
      })
    })
  },
  deleteEntityMedia ({ commit }, payload) {
    let url = null
    if (payload.entityType === 'property') {
      url = `/api/v3/property/${payload.entityId}/media/${payload.mediaId}`
    } else {
      url = `/api/v3/project/${payload.entityId}/media/${payload.mediaId}`
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        url: url,
        type: 'DELETE'
      }).done(() => {
        commit('deleteEntityMedia', {
          entityId: payload.entityId,
          entityType: payload.entityType,
          media: payload.mediaId
        })
        resolve()
      }).fail(error => {
        reject(error)
      })
    })
  },
  loadPropertyPictures ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/property/${id}/pictures`
      }).done(pictures => {
        commit('setPropertyPictures', { id: id, pictures: pictures.results })
        resolve(pictures.results)
      }).fail(error => {
        reject(error)
      })
    })
  },

  loadPropertySignaturePackages ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/property/${id}/esignatures/packages`
      }).done(packages => {
        commit('setPropertySignaturePackages', { id: id, packages: packages.results })
        resolve(packages.results)
      }).fail(error => {
        reject(error)
      })
    })
  },

  /****************************
   *        Projects          *
   ****************************/

  /**
   * Get a Project by it's ID
   * @param payload Object { id: <project_id>, requestFreshData: bool }
   * @returns {Promise<Project>}
   */
  loadProject ({ commit }, id) {
    return new Promise((resolve, reject) => {
      getProjectById(id)
        .then(response => {
          commit('setProject', response.data)
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
    })
  },
  loadProjectCollaborators ({ commit }, id) {
    return new Promise((resolve, reject) => {
      const payload = {
        projectId: id,
        params: {
          include_inactive: 1
        }
      }
      getProjectCollaborators(payload)
        .then(response => {
          const collaborators = response.data?.results
          commit('setProjectCollaborators', { id, collaborators })
          resolve(collaborators)
        }).catch(error => {
          reject(error)
        })
    })
  },
  loadProjectDescriptions ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/project/${id}/descriptions`
      }).done(descriptions => {
        commit('setProjectDescriptions', { id: id, descriptions: descriptions.results })
        resolve(descriptions.results)
      }).fail(error => {
        reject(error)
      })
    })
  },
  loadProjectPictures ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/v3/project/${id}/pictures`
      }).done(pictures => {
        commit('setProjectPictures', { id: id, pictures: pictures.results })
        resolve(pictures.results)
      }).fail(error => {
        reject(error)
      })
    })
  },

  /**
   * Query for projects based on specific query filters
   * param payload Object containing query filter fields
   * @returns {Promise<[Project]>}
   */
  queryProjects ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      const query = {}
      if (payload.query.reference) {
        query.reference = payload.query.reference
      }
      if (payload.query.status) {
        query.status = payload.query.status
      }
      if (payload.query.street) {
        query.street = payload.query.street
      }
      if (payload.query.number) {
        query.number = payload.query.number
      }
      if (payload.query.box) {
        query.box = payload.query.box
      }
      if (payload.query.city) {
        query.city = payload.query.city.id
      }
      if (payload.query.types) {
        query.types = payload.query.types
      }
      if (payload.query.collaborator) {
        query.collaborator = payload.query.collaborator.id
      }
      if (payload.query.office) {
        query.office = payload.query.office.id
      }

      const api = new ProjectApi()

      api.queryData(query)
        .then((projects) => {
          const projectIds = projects.map(x => x.id)
          commit('setProjectQueryData', projectIds)
          commit('mergeProjects', projects)
          resolve(projectIds)
        }).catch((error) => {
          reject(error)
        })
    })
  },

  /****************************
   *        Owner Reports     *
   ****************************/

  createOwnerReportsForProperty (context, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        data: payload.data,
        url: `/api/property/${payload.entity.id}/owner-reports/create`,
        traditional: true
      }).done(job => {
        resolve(job)
      }).fail((error) => {
        reject(error)
      })
    })
  },

  createOwnerReportsForProject (context, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        data: payload.data,
        url: `/api/project/${payload.entity.id}/owner-reports/create`,
        traditional: true
      }).done(job => {
        resolve(job)
      }).fail(error => {
        reject(error)
      })
    })
  }
}

// Mutations
const mutations = {
  // Property Group
  setGroups (state, payload) {
    if (payload.groups.length > 0) {
      state.groups = {}
      payload.groups.forEach((group) => {
        Vue.set(state.groups, group.id, group)
      })
    }
  },

  setGroup (state, group) {
    if (!state.groups) {
      state.groups = {}
    }
    Vue.delete(state.groups, group.id)
    Vue.set(state.groups, group.id, group)
  },

  // Property
  setProperty (state, property) {
    Vue.set(state.properties, property.id, property)
  },
  setPropertyDescriptions (state, payload) {
    Vue.set(state.propertyDescriptions, payload.id, payload.descriptions)
  },
  setPropertyCollaborators (state, payload) {
    Vue.set(state.propertyCollaborators, payload.id, payload.collaborators)
  },
  setEntityMedia (state, payload) {
    if (payload.entityType === 'property') {
      Vue.set(state.propertyMedia, payload.id, payload.media)
    } else {
      Vue.set(state.projectMedia, payload.id, payload.media)
    }
  },
  updateEntityMedia (state, payload) {
    let mediaObjects = []
    if (payload.entityType === 'property') {
      mediaObjects = state.propertyMedia[payload.entityId]
    } else {
      mediaObjects = state.projectMedia[payload.entityId]
    }

    const indexToChange = mediaObjects.findIndex(x => x.id === payload.media.id)
    mediaObjects[indexToChange] = payload.media
    if (payload.entityType === 'property') {
      Vue.delete(state.propertyMedia, payload.entityId)
      Vue.set(state.propertyMedia, payload.entityId, mediaObjects)
    } else {
      Vue.delete(state.projectMedia, payload.entityId)
      Vue.set(state.projectMedia, payload.entityId, mediaObjects)
    }
  },
  deleteEntityMedia (state, payload) {
    let mediaObjects = []
    console.log(payload)
    if (payload.entityType === 'property') {
      mediaObjects = state.propertyMedia[payload.entityId]
    } else {
      mediaObjects = state.projectMedia[payload.entityId]
    }
    const indexToDelete = mediaObjects.findIndex(x => x.id === payload.media)
    mediaObjects.splice(indexToDelete, 1)
    if (payload.entityType === 'property') {
      Vue.delete(state.propertyMedia, payload.entityId)
      Vue.set(state.propertyMedia, payload.entityId, mediaObjects)
    } else {
      Vue.delete(state.projectMedia, payload.entityId)
      Vue.set(state.projectMedia, payload.entityId, mediaObjects)
    }
  },
  setPropertyPictures (state, payload) {
    Vue.set(state.propertyPictures, payload.id, payload.pictures)
  },
  setPropertySignaturePackages (state, payload) {
    Vue.set(state.propertySignaturePackages, payload.id, payload.packages)
  },
  setProject (state, project) {
    /** We need to delete before setting a project. When pressing F5 in project list and going to a project, then editing the
     * projects name, the name is not reactively updated. For some reason Vuex doesn't pick up the name change with only
     * a 'Vue.set'. We have no idea why, as it works for everything else. It remains an unsolved mystery.
     */

    Vue.delete(state.projects, project.id)
    Vue.set(state.projects, project.id, project)
  },

  setProjectCollaborators (state, payload) {
    Vue.set(state.projectCollaborators, payload.id, payload.collaborators)
  },
  setProjectDescriptions (state, payload) {
    Vue.set(state.projectDescriptions, payload.id, payload.descriptions)
  },
  setProjectPictures (state, payload) {
    Vue.set(state.projectPictures, payload.id, payload.pictures)
  },
  mergeProjects (state, projects) {
    projects.forEach((project) => {
      state.projects[project.id] = project
    })
  },
  setProjectQueryData (state, queryData) {
    state.projectQueryData = queryData
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
