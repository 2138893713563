/**
 * Generic API that can be implemented or extended to create specific API endpoints
 */
import $ from 'jquery'

class BaseApi {
  constructor (options) {
    this.loading = false
    this.data = []
    this.url = options.url
    this.name = options.name
    this.timeout = options.timeout
    this.preload = options.preload
  }

  _getDataFromLocalStorage () {
    console.debug('API: Loading data from local storage ' + this.name)
    const raw = localStorage.getItem(this.name + '-data')
    if (raw !== 'undefined' && raw) {
      this.data = JSON.parse(raw)
      console.debug('API: Loaded ' + this.data.length + ' records from local storage ' + this.name)
      return new Promise((resolve, reject) => {
        resolve(this.data)
      })
    } else {
      console.error('API: Error loading data from local storage ' + this.name)
      console.error(raw)
    }
  }

  _storeData () {
    localStorage.setItem(this.name + '-data', JSON.stringify(this.data))
    localStorage.setItem(this.name + '-ts', this.ts)
  }

  _isStale () {
    return (Date.now() - this.ts) > this.timeout
  }

  _getDataFromApi (id = null, query = null) {
    let dataUrl = ''
    if (id) {
      dataUrl = this.url + '/' + id
    } else if (query) {
      dataUrl = this.url + '?q=1'
      Object.keys(query).forEach((key) => {
        let s = ''
        if (query[key].constructor === Array) {
          query[key].forEach((el) => {
            s += '&' + key + '=' + el
          })
        } else {
          s += '&' + key + '=' + query[key]
        }
        dataUrl += s
      })
    } else {
      dataUrl = this.url
    }
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: dataUrl
      }).done((data) => {
        console.debug('API: Finished API call ' + this.name)
        let item = null
        if (Object.hasOwnProperty.call(data, 'results')) {
          this.data = data.results
          item = data.results
        } else {
          item = data
          if (id) {
            const idx = this.data.findIndex((r) => r.id === parseInt(id))
            if (idx > -1) {
              this.data[idx] = data
            } else {
              this.data.push(data)
            }
          } else {
            this.data.push(data)
          }
        }
        this.ts = Date.now()
        this._storeData()
        resolve(item)
      }).fail((error) => {
        reject(error)
      })
    })
  }

  /**
   * Query the data from the API
   * @param query Query options
   */
  queryData (query) {
    return this._getDataFromApi(null, query)
  }

  /**
   * Get the data from the API
   * @param requestFreshData If true the API will fetch new data and skips the local cache
   * @returns {Promise<any>}
   */
  getData (requestFreshData = false) {
    this.ts = localStorage.getItem(this.name + '-ts')
    if (this._isStale() || requestFreshData) {
      console.debug('API: Data is stale, triggering API ' + this.name)
      return this._getDataFromApi()
    } else {
      console.debug('API: Returning data from local cache ' + this.name)
      if (this.data.length > 0) {
        return new Promise((resolve, reject) => {
          return resolve(this.data)
        })
      } else {
        return this._getDataFromLocalStorage()
      }
    }
  }

  /**
   * Deprecated method, use getItem with the <em>requestFreshData</em> flag instead.
   * Get an updated item from the API, skipping the local cache
   * @deprecated
   * @param id Id of the item you want to get from the API
   */
  updateItem (id) {
    console.debug('API: Forcing item update through API ' + this.name)
    return this._getDataFromApi(id)
  }

  /**
   * Get a specific item by ID from the API
   * @param id Id of the item you want to get
   * @param requestFreshData If true, skip the local cache and fetch from the API endpoint
   * @returns {Promise<any>}
   */
  getItem (id, requestFreshData = false) {
    this.ts = localStorage.getItem(this.name + '-ts')
    if (this._isStale() || requestFreshData) {
      console.debug('API: Data is stale, triggering API ' + this.name)
      return this._getDataFromApi(id)
    } else {
      console.debug('API: Returning data from local cache ' + this.name)
      const item = this.data.find(function (r) {
        return r.id === parseInt(id)
      })
      if (item) {
        return new Promise((resolve, reject) => {
          return resolve(item)
        })
      } else {
        return this._getDataFromApi(id)
      }
    }
  }

  /**
   * Save an existing item to the database or create a new item.
   * @param item Object containing the data to post. If the field 'id' has a valid record Id we update an existing
   * record, otherwise we create a new object.
   * @returns {Promise<Contact>}
   */
  saveItem (item) {
    if (item.id) {
      console.log(item)
      return $.ajax({
        type: 'PATCH',
        traditional: true,
        url: this.url + '/' + item.id,
        data: item
      })
    } else {
      return $.ajax({
        type: 'POST',
        traditional: true,
        url: this.url,
        data: item
      })
    }
  }

  /**
   * Delete an existing item from the database
   * @param item Object containing the item to delete
   * @returns {*}
   */
  deleteItem (item) {
    if (item.id) {
      return $.ajax({
        type: 'DELETE',
        url: this.url + '/' + item.id
      })
    }
  }
}

export default BaseApi
