<template>
  <div
    class="tw-relative tw-inline-block tw-text-left"
  >
    <button
      type="button"
      :title="label"
      class="tw-px-4 tw-py-2 tw-w-full tw-rounded-md tw-text-sm focus:tw-outline-none tw-border tw-border-gray-200 tw-bg-white tw-text-tg-color hover:tw-bg-gray-200 hover:tw-border-gray-300 focus:tw-bg-gray-200 focus:tw-border-gray-300 active:tw-bg-gray-200 active:tw-border-gray-300"
      @click="toggle"
      @keydown.enter.prevent="onEnter"
      @keydown.up.prevent="onArrowUp"
      @keydown.down.prevent="onArrowDown"
      @keydown.esc.stop="onEsc"
    >
      <i v-if="labelIcon" :class="`tw-mr-1 fa fa-${labelIcon}`" />
      {{ label }}
      <i class="fa fa-caret-down tw-ml-2" />
    </button>
    <transition
      enter-active-class="tw-transition tw-ease-out tw-duration-100"
      enter-class="tw-transform tw-opacity-0 tw-scale-95"
      enter-to-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-active-class="tw-transition tw-ease-in tw-duration-75"
      leave-class="tw-transform tw-opacity-100 tw-scale-100"
      leave-to-class="tw-transform tw-opacity-0 tw-scale-95"
    >
      <div
        v-if="isMenuOpen"
        class="tw-origin-top-right tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-rounded-md tw-shadow-lg tw-text-sm tw-overflow-hidden tw-border tw-z-20"
      >
        <div
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
          class="tw-rounded-md tw-bg-white tw-shadow-xs"
        >
          <div class="tw-py-1">
            <button
              v-for="(option, index) in options"
              :key="`${index}-option`"
              :ref="`${index}-option`"
              :title="option.label"
              :class="[
                { 'tw-font-bold': index === optionIndex },
                'tw-px-4 tw-py-2 hover:tw-font-bold focus:tw-font-normal'
              ]"
              @click="option.action"
            >
              <i v-if="option.labelIcon" :class="`tw-mr-1 fa fa-${option.labelIcon}`" /> {{ option.label }}
            </button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'BaseDropDownButton',
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    labelIcon: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      isMenuOpen: false,
      optionIndex: -1,
      currentOption: null
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    toggle () {
      this.isMenuOpen = !this.isMenuOpen
      this.optionIndex = -1
      this.currentOption = null
    },
    onEnter () {
      if (this.optionIndex === -1) {
        this.toggle()
      } else {
        this.options[this.optionIndex].action()
        this.onEsc()
      }
    },
    onArrowUp () {
      if (this.optionIndex === 0) {
        this.isMenuOpen = false
        this.optionIndex = -1
      } else {
        this.optionIndex -= this.optionIndex === -1 ? 0 : 1
      }
    },
    onArrowDown () {
      if (this.optionIndex === -1) {
        this.isMenuOpen = true
        this.optionIndex = 0
      } else {
        this.optionIndex += this.optionIndex + 1 === this.options.length ? 0 : 1
      }
    },
    onEsc () {
      this.optionIndex = -1
      this.isMenuOpen = false
    },

    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) {
        this.onEsc()
      }
    }
  }
}
</script>
