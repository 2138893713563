/**
 * account.js
 * Account store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */

/**
 * Local store for the Account state. This state contains all user and account related state
 */
import { calculatePermissions } from '@/utils/permissions'
import { getUser } from '@/services/user'

/**
 * Initial state of the Account.
 * @type {{user: {}}}
 */
const state = {
  user: null,
  userPromise: {}
}

// Getters
const getters = {
  collaborator: state => {
    return state.user?.collaborator
  }
}

// Actions
const actions = {
  async loadUser ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit('setUserPromise', getUser())
      return state.userPromise
        .then(response => {
          commit('setCurrentUser', response.data)
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}

// Mutations
const mutations = {
  setCurrentUser (state, user) {
    state.user = user
  },
  setPermissions (state) {
    // @param {Object} collaborator The collaborator we want to add permissions to
    const perms = calculatePermissions(state.user.collaborator)
    const hasPerm = function (permission, ignoreStaff = false) {
      // @param {string} permission The permission that needs to be checked
      // @param {boolean} ignoreStaff flag that indicates that the staff
      // status should be ignored for this permission check
      // Check if collaborator has all permission
      if (state.user.collaborator.is_staff && !ignoreStaff) {
        return true
        // Collaborator does not have all permissions so check if he/she has the given permission
      } else if (state.user.collaborator.perms.indexOf(permission) > -1) {
        return true
      } else {
        // Collaborator does not have the given permission but might have the global one
        if (permission.includes('__')) {
          // Permission has a scope so check if the user has the global variant of it
          const action = permission.split('__')[0]
          return state.user.collaborator.perms.indexOf(action + '__GLOBAL') > -1
        } else {
          // Permission does not have a scope
          // Might be a check regardless of scope
          // Usage Example: VIEW_BUDGET.
          //  * This check could be done to know if the budget button should be shown or not.
          //  * VIEW_BUDGET is normally scoped but to show the button we need to check the permission regardless of scope.
          //  * hasPerm will check if the user has atleast one permission in any scope that pertains to this permission
          let hasPermission = false
          state.user.collaborator.perms.forEach((perm) => {
            if (perm.includes('__')) {
              const action = perm.split('__')[0]
              if (permission === action) {
                // User has a permission if this type so which means the check is valid.
                hasPermission = true
              }
            }
          })
          // Otherwise user does not have the permission
          return hasPermission
        }
      }
    }
    state.user.collaborator.perms = perms
    state.user.collaborator.hasPerm = hasPerm
  },

  setUserPromise (state, promise) {
    state.userPromise = promise
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
