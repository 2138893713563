export const budgetRoutes = [
  {
    path: '/budget',
    name: 'BudgetList',
    component: () => import(/* webpackChunkName: "BudgetList" */ '@/views/budget/BudgetList'),
    meta: {
      navigationGroupName: 'Budget'
    }
  },
  {
    path: '/budget/invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "InvoiceList" */ '@/views/budget/InvoiceList'),
    meta: {
      navigationGroupName: 'Budget'
    }
  },
  {
    path: '/budget/book-invoices',
    name: 'BookInvoices',
    component: () => import(/* webpackChunkName: "InvoiceBooking" */ '@/views/budget/InvoiceBooking'),
    meta: {
      navigationGroupName: 'Budget'
    }
  },
  {
    path: '/budget/:id',
    name: 'Budget',
    component: () => import(/* webpackChunkName: "BudgetView" */ '@/views/budget/BudgetView'),
    meta: {
      navigationGroupName: 'Budget'
    }
  },
  {
    path: '/budget/reports/usage',
    name: 'UsageReport',
    component: () => import(/* webpackChunkName: "UsageReport" */ '@/views/budget/UsageReport'),
    meta: {
      navigationGroupName: 'Budget'
    }
  }
]
