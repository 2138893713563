var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-relative tw-z-[100]"},[_c('div',{staticClass:"tw-fixed tw-flex tw-flex-col tw-gap-2 tw-items-end tw-bottom-14 tw-right-6"},[_c('FormulateForm',{directives:[{name:"show",rawName:"v-show",value:(_vm.showChat),expression:"showChat"}],staticClass:"tw-p-4 tw-bg-white tw-max-w-md tw-shadow-card tw-rounded-xl tw-overflow-hidden",attrs:{"name":"mia"},on:{"submit":_vm.chat},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}},[_c('div',{ref:"messages",staticClass:"tw-min-h-100 tw-max-h-130 tw-flex tw-flex-col tw-items-end tw-gap-4 tw-overflow-scroll"},[_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,class:[
            'tw-px-2 tw-prose tw-max-w-[90%]',
            message.role === 'user'
              ? 'tw-rounded-bl-xl tw-rounded-tl-xl tw-rounded-tr-lg tw-ml-auto tw-bg-success tw-text-white'
              : 'tw-rounded-br-xl tw-rounded-tr-xl tw-rounded-tl-lg tw-mr-auto tw-bg-gray-e9'
          ]},[_c('VueMarkdown',{attrs:{"html":true,"source":message.content}})],1)}),(_vm.loading)?_c('div',{staticClass:"tw-px-2 tw-rounded-br-xl tw-rounded-tr-xl tw-rounded-tl-lg tw-mr-auto tw-bg-gray-e9"},[_c('i',{staticClass:"fas fa-ellipsis-h"})]):_vm._e()],2),_c('div',{staticClass:"tw-mt-4 tw-sticky tw-bottom-0 tw-bg-white tw-flex tw-flex-row tw-gap-2"},[_c('FormulateInput',{attrs:{"type":"text","name":"message","placeholder":"Typ een bericht","input-class":['tw-mt-0 tw-text-sm'],"outer-class":"tw-m-0 tw-flex-grow"}}),_c('FormulateInput',{attrs:{"type":"submit","title":"Versturen","input-class":['tw-px-4 hover:tw-bg-darken-success'],"outer-class":"tw-m-0"}},[_c('i',{staticClass:"fas fa-paper-plane"})])],1)]),_c('button',{staticClass:"tw-h-12 tw-w-12 tw-text-xl tw-text-white tw-rounded-full tw-shadow-card tw-bg-success hover:tw-bg-darken-success",attrs:{"type":"button"},on:{"click":_vm.toggleChat}},[_c('i',{class:[
          'far',
          _vm.showChat ? 'fa-times' : 'fa-comments'
        ]})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }