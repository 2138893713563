import axios from 'axios'

// Client for Mapbox service
const mapboxClient = axios.create({
  baseURL: process.env.VUE_APP_MAPBOX_API_URL,
  params: {
    access_token: process.env.VUE_APP_MAPBOX_API_KEY
  }
})

// Mapbox Address search
export function mapboxAddressSearch ({ params }) {
  const { query, ...otherParams } = params
  return mapboxClient.get(`/geocoding/v5/mapbox.places/${query}.json`, {
    params: {
      proximity: 'ip',
      types: 'address',
      language: 'nl,fr,en',
      limit: 10, // Max limit
      ...otherParams
    }
  })
}

// Mapbox Cities search
export function mapboxCitySearch ({ params }) {
  // Mapbox classifies some belgian cities as localities, for eg: Wilrijk (https://dewaele.atlassian.net/browse/DEW-8421)
  const { query, ...otherParams } = params
  return mapboxClient.get(`/geocoding/v5/mapbox.places/${query}.json`, {
    params: {
      proximity: 'ip',
      types: 'place,locality',
      language: 'nl,fr,en',
      limit: 10, // Max limit
      ...otherParams
    }
  })
}
