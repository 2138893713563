import Bloodhound from 'bloodhound-js'

const contacts = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/contacts?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
contacts.initialize()

const contactGroups = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/contacts/groups?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
contactGroups.initialize()

const cities = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/city?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
cities.initialize()

const citiesWithChildren = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/city?include_children=1&serialize_children=1&query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
citiesWithChildren.initialize()

const collaborators = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/collaborators/search?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
collaborators.initialize()

const properties = new Bloodhound({
  limit: 19,
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/v3/property?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
properties.initialize()

const projects = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/v3/project/search?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
projects.initialize()

const transactions = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/transaction?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
transactions.initialize()

const offices = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/api/office?query=%query',
    wildcard: '%query',
    transform: function (data) {
      return data.results
    }
  }
})
offices.initialize()

export { contacts, contactGroups, cities, citiesWithChildren, collaborators, properties, projects, transactions, offices }
