<template>
  <span data-toggle="popover" :data-placement="placement" :title="title" data-html="true" data-trigger="hover" :data-content="content">
    <slot><i class="glyphicon glyphicon-question-sign"></i></slot>
  </span>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'HelpToolTip',
  props: {
    title: {
      default: 'Help Tooltip title'
    },
    content: {
      default: 'Tooltip content'
    },
    placement: {
      default: 'right'
    }
  },
  mounted () {
    $('[data-toggle="popover"]').popover()
  }
}
</script>

<style scoped>

</style>
