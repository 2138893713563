<template>
  <div class="form-group no-margin-bottom">
    <label class="col-sm-2 no-padding control-label" style="padding-right: 15px!important; padding-top: 5px!important;">
      <HelpToolTip :title="title" :content="help" placement="bottom">
        {{ title }}:
      </HelpToolTip>
    </label>
    <div class="col-sm-10">
      <div class="col-xs-1 no-padding" style="padding-top: 5px!important;">
        <a class="btn btn-xs btn-primary" @click="addRecipientForm">
          <i class="fa fa-plus"></i>
        </a>
      </div>
      <div class="col-xs-11 no-padding">
        <div class="row" v-for="recipient in recipients" :key="recipient.cid">
          <RecipientPicker :recipient="recipient"
                           @select="selectRecipient"
                           @remove-recipient="removeRecipient">
          </RecipientPicker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecipientPicker from './RecipientPicker'
import HelpToolTip from '../HelpToolTip'

export default {
  name: 'MailRecipient',
  props: ['title', 'recipients', 'type', 'help'],
  components: {
    HelpToolTip,
    RecipientPicker
  },
  methods: {
    addRecipientForm () {
      this.$emit('add-recipient', this.type)
    },
    selectRecipient (recipient, selectedRecipient) {
      this.$emit('select-recipient', recipient, selectedRecipient)
    },
    removeRecipient (recipient) {
      this.$emit('remove-recipient', recipient)
    }
  }
}
</script>

<style scoped>
  .no-margin-bottom {
    margin-bottom: 0;
  }
</style>
