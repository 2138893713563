<template>
<!-- focus="false" is to prevent the modal from interfering with the other input modals like link creation modal inEditor.vue -->
  <div aria-hidden="true" class="modal inmodal fade tw-overflow-y-auto" role="dialog" focus="false"> <!-- tw-overflow-auto to allow modal to scroll vertically -->
    <div :class="['modal-dialog', modalSize]">
      <div class="modal-content">
        <div class="modal-header">
          <button v-if="showCloseButton" class="close" type="button" @click="hide">
            <span aria-hidden="true">&times;</span>
            <span class="sr-only">Close</span>
          </button>
          <button v-if="showMinimizeButton" class="close" type="button" @click="hide">
            <i class="glyphicon glyphicon-chevron-down"></i>
            <span class="sr-only">Minimize</span>
          </button>
          <h4 class="modal-title">{{title}}</h4>
          <small class="font-bold">
            <slot name="header"></slot>
          </small>
        </div>
        <div class="modal-body">
          <slot><p>This is the default modal content.</p></slot>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button @click="hide" class="btn btn-white" type="button">Sluiten</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import 'bootstrap'

export default {
  name: 'Modal',
  props: {
    title: {
      default: 'Default IAM Modal'
    },
    size: {
      default: 'lg'
    },
    showCloseButton: {
      default: true
    },
    showMinimizeButton: {
      default: false
    }
  },
  computed: {
    modalSize () {
      return `modal-${this.size}`
    }
  },
  methods: {
    show () {
      $(this.$el).modal({
        backdrop: 'static',
        keyboard: false
      })
      this.$emit('shown')
    },
    hide () {
      $(this.$el).modal('hide')
      this.$emit('hide')
    }
  }
}
</script>
