<template>
  <div
    :class="
      `formulate-input-element formulate-input-element--${context.type} tw-relative tw-flex`
    "
    :data-type="context.type"
  >
    <select
      v-model="dialCode"
      :class="[computedClasses.width, 'tw-text-base tw-bg-white tw-absolute tw-m-2 tw-h-8']"
      @change="onDialCodeChange"
    >
      <option
        v-for="dialCode in DIAL_CODES"
        :key="dialCode.country"
        :value="dialCode.code"
        :disabled="dialCode.disabled"
      >
        {{ dialCode.code }} {{ dialCode.country }}
      </option>
    </select>
    <input
      v-model="number"
      type="tel"
      :id="context.attributes.id"
      :name="context.attributes.name"
      :placeholder="context.attributes.placeholder"
      :class="[computedClasses.padding, ...context.attributes.class]"
      @blur="onBlur"
    />
  </div>
</template>

<script>
import DIAL_CODES from '@/utils/dialCodes'
import { DEFAULT_DIAL_CODE, formatPhone, parsePhoneNumber } from '@/utils/helpers'

export default {
  name: 'BaseInputDialCodeTel',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  constants: { DIAL_CODES },
  data () {
    return {
      dialCode: DEFAULT_DIAL_CODE,
      number: ''
    }
  },
  computed: {
    model: {
      get () {
        return this.context.model
      },
      set (val) {
        this.context.model = val
      }
    },
    computedClasses () {
      const dialCodeLength = this.dialCode?.length // Includes "+"
      // Classes need to be pre-declared and not computed dynamically, for tailwind to work properly.
      switch (dialCodeLength) {
        case 2:
          return { padding: 'tw-pl-[50px]', width: 'tw-w-[37px]' }
        case 3:
          return { padding: 'tw-pl-[60px]', width: 'tw-w-[47px]' }
        case 4:
          return { padding: 'tw-pl-[70px]', width: 'tw-w-[57px]' }
        default:
          return { padding: 'tw-pl-[50px]', width: 'tw-w-[38px]' }
      }
    }
  },
  watch: {
    model: {
      immediate: true,
      handler (value) {
        const { dialCode, number } = parsePhoneNumber(value)
        this.dialCode = dialCode
        this.number = number
      }
    }
  },
  methods: {
    onBlur (event) {
      const number = event.target.value
      const phoneNumber = formatPhone(this.dialCode, number)

      if (!phoneNumber) this.number = ''
      this.model = phoneNumber
      this.context.blurHandler(event)
    },
    onDialCodeChange (event) {
      const dialCode = event.target.value
      const phoneNumber = formatPhone(dialCode, this.number)

      if (!phoneNumber) this.number = ''
      this.model = phoneNumber
    }
  }
}
</script>
