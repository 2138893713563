<template>
  <div class="formulate-file tw-h-10 !tw-mt-1">
    <img
      v-if="isImage"
      :src="file.previewData || (file.path && file.path[0].url)"
      :alt="file.name"
      class="tw-object-contain tw-max-h-10"
    />
    <div
      :title="file.name"
      class="formulate-file-name"
    >
      <a :href="file.previewData || (file.path && file.path[0].url)" target="_blank" rel="noopener noreferrer">
        {{ file.name }}
      </a>
    </div>
    <div
      v-if="file.progress !== false"
      :data-just-finished="file.justFinished"
      :data-is-finished="!file.justFinished && file.complete"
      :class="classes.fileProgress"
    >
      <div
        :class="classes.fileProgressInner"
        :style="{ width: file.progress + '%' }"
      />
    </div>
    <div
      v-if="showRemoveFile && ((file.complete && !file.justFinished) || file.progress === false)"
      :class="classes.fileRemove"
      @click="file.removeFile"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseFileUploadDisplay',
  props: {
    showRemoveFile: {
      // Jan's opinion is that it's confusing for the user to offer the clear functionality here.
      // So, for the time being, we don't show the remove file button.
      type: Boolean,
      default: false
    },
    file: {
      type: Object
    },
    classes: {
      type: Object
    },
    isImage: {
      type: Boolean
    }
  }
}
</script>
