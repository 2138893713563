export const profileRoutes = [
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/Profile')
  },
  {
    path: '/profile/immo-connect/:status',
    name: 'ImmoConnect',
    component: () => import(/* webpackChunkName: "Profile" */ '@/views/profile/ImmoConnect')
  }
]
