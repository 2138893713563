import { httpClient } from './axiosClients'

export function getNotifications (payload = {}) {
  const { url = '/api/notifications' } = payload
  return httpClient.get(url)
}

export function getNotificationCount () {
  return httpClient.get('/api/notifications/count')
}

export function updateNotification (notificationId, payload = {}) {
  return httpClient.patch(`/api/notifications/${notificationId}`, payload)
}

export function markNotificationsAsRead (payload = {}) {
  return httpClient.patch('/api/notifications/mark-read', payload)
}
