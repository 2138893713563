<template>
  <div :style="divStyle">
    <span v-if="val">
      <component :is="selectionRenderComponent" v-model="val" />
      <button
        ref="removeButton"
        v-show="showButton"
        type="button"
        class="btn btn-xs btn-danger"
        style="margin-left: 5px;"
        @click="deselect"
      >
        <i class="fa fa-times" />
      </button>
    </span>
    <span v-show="!val">
      <SuggestionPicker
        ref="picker"
        v-model="val"
        :source="source"
        :type="type"
        :placeholder="placeholder"
        :suggestionRenderComponent="suggestionRenderComponent"
        @select="select"
      />
    </span>
  </div>
</template>
<script>
import SuggestionPicker from './SuggestionPicker'

export default {
  name: 'PickerMixin',
  components: {
    SuggestionPicker
  },
  props: {
    value: {
      default: null
    },
    placeholder: {
      default: ''
    },
    type: {
      default: ''
    },
    canClear: {
      default: true
    },
    multiple: {
      default: false
    }
  },
  data () {
    return {
      val: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.val = value
      }
    }
  },
  computed: {
    divStyle () {
      if (this.val) return 'margin-top:7px; margin-bottom:5px'
      return ''
    },
    showButton () {
      return this.val && this.canClear
    }
  },
  created () {
    if (this.value && !this.val) this.val = this.value
  },
  methods: {
    select (value) {
      this.val = value
      this.$emit('input', value)
      this.$emit('select', value)
    },
    deselect () {
      this.val = null
      this.$refs.picker.clear()
      this.$emit('input', null)
      this.$emit('deselect', null)
    },
    disable () {
      this.$refs.picker.disable()
    },
    enable () {
      this.$refs.picker.enable()
    },
    setError () {
      this.$refs.picker.setError()
    },
    removeError () {
      this.$refs.picker.removeError()
    },
    clear () {
      this.deselect()
    }
  }
}
</script>
