<template>
  <label :for="context.id" :class="context.classes.label">
    <i v-if="labelIcon.length > 0" :class="labelIcon" />
    {{ context.label }}
    <i v-if="infoText" :title="infoText" class="fas fa-info-circle" />
    <span v-if="showRequiredLabel" aria-label="required" class="input-error">
      *
    </span>
  </label>
</template>

<script>
export default {
  name: 'BaseInputLabel',
  props: {
    context: {
      type: Object,
      required: true
    },
    labelIcon: {
      type: String,
      default: ''
    },
    showRequiredLabel: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: String,
      default: null
    }
  }
}
</script>
