/* eslint-disable no-console */
import axios from 'axios'
import $ from 'jquery'
import axiosRetry from 'axios-retry'
import rateLimit from 'axios-rate-limit'
import createAuthRefreshInterceptor from 'axios-auth-refresh'
import { getCookie } from '@/utils/helpers'

// Global axios defaults
axios.defaults.timeout = 30000
axios.defaults.headers['Accept-Language'] = 'nl'
axios.defaults.timeoutErrorMessage = 'Time-out van verzoek overschreden'

axiosRetry(axios, {
  retries: 3,
  // Exponential back-off retry delay between requests
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // Retry on network errors, 5xx errors, and timeouts
    return axiosRetry.isNetworkOrIdempotentRequestError(error) || error.code === 'ECONNABORTED'
  }
})

// httpClient for user related authenticated routes
export const httpClient = axios.create()

function login () {
  const returnUrl = window.location.origin
  window.location.href = `/authentication/login?social_partner=google&success_url=${returnUrl}&failure_url=${returnUrl}`
}

// To setup global options for jQuery ajax
$(document)
  .ajaxSend(function (event, jqxhr, settings, exception) {
    jqxhr.setRequestHeader('X-CSRFToken', getCookie('csrftoken'))
  })
  .ajaxError(function (event, jqxhr, settings, exception) {
    if (jqxhr.status === 401) login()
    return Promise.reject(jqxhr)
  })

httpClient.interceptors.request.use(request => {
  const csrftoken = getCookie('csrftoken')
  request.headers['X-CSRFToken'] = csrftoken
  return request
})

httpClient.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401) login()
    return Promise.reject(error)
  }
)

export const bidregistrationClient = axios.create({
  baseURL: process.env.VUE_APP_BIDREGISTRATION_API_URL
})

// calendarClient for calendar service
export const calendarClient = axios.create({
  baseURL: process.env.VUE_APP_CALENDAR_API_URL
})

// certificationClient for certification service
export const certificationClient = axios.create({
  baseURL: process.env.VUE_APP_CERTIFICATION_API_URL
})

// baseRegistersClient for Basisregisters Vlaanderen service
export const baseRegistersClient = rateLimit(
  // Rate limits to a maximum of 15 requests per second (maxRPS) (tested rate limit for the API)
  axios.create({
    baseURL: process.env.VUE_APP_BASE_REGISTERS_API_URL,
    headers: {
      'x-api-key': process.env.VUE_APP_BASE_REGISTERS_TOKEN
    }
  }),
  { maxRPS: 15 }
)

// Client for Gutenborg service
export const gutenborgClient = axios.create({
  baseURL: process.env.VUE_APP_GUTENBORG_SERVICE_URL,
  headers: {
    Authorization: `Token ${process.env.VUE_APP_GUTENBORG_TOKEN}`
  }
})

// Stores the jwt tokens
let jwt = {}

async function fetchJWT () {
  const response = await httpClient.get('/api/user')
  const { access, refresh } = response.data
  jwt = { access, refresh }
  return response
}

// Function that will be called to refresh JWT authorization
async function JWTRefresh () {
  try {
    const { refresh } = jwt

    // If refresh token does not exist, fetch a fresh JWT token.
    if (!refresh) return await fetchJWT()

    // We use bare axios here instead of the instance httpClient
    // to avoid the interceptor from asking us to login again, in case of a 401.
    const response = await axios.post(
      '/api/token/refresh',
      { refresh },
      { skipAuthRefresh: true }
    )
    const { access } = response.data
    jwt.access = access
    return response
  } catch (error) {
    // Ask to log in, when the fetchJWT request or the refresh token doesn't work
    window.location.href = '/authentication/login'
  }
}

const JWTClients = [bidregistrationClient, calendarClient, certificationClient]

JWTClients.forEach(client => {
  // We use interceptor so as to always fetch the latest token
  client.interceptors.request.use(request => {
    const { access } = jwt
    if (access) request.headers.Authorization = `Bearer ${access}`
    return request
  })

  // Instantiate the interceptor (you can chain it as it returns the calendarClient instance)
  createAuthRefreshInterceptor(client, JWTRefresh, {
    statusCodes: [401, 403]
  })
})
