<template>
  <div
    :class="
      `formulate-input-element formulate-input-element--${context.type} tw-relative`
    "
    :data-type="context.type"
    @keydown.esc.stop="onEsc"
  >
    <button
      type="button"
      v-bind="context.attributes"
      aria-label="show options"
      class="tw-relative tw-text-left"
      @click="showOptions = !showOptions"
    >
      <template v-if="model.length">
        <div
          aria-label="selections count"
          class="tw-px-1.5 tw-mr-0.5 tw-inline-block tw-text-sm tw-bg-success tw-text-white tw-rounded-full"
        >
          {{ model.length }}
        </div>
        geselecteerd
      </template>
      <span v-else :aria-placeholder="context.placeholder">{{ context.placeholder || '' }}</span>
      <i class="far fa-chevron-down fa-xs tw-px-1 tw-absolute tw-right-0 tw-top-1/2 tw-transform tw--translate-y-1/2" />
    </button>
    <ul
      v-show="showOptions"
      tabindex="-1"
      aria-label="options"
      :aria-expanded="showOptions"
      class="tw-text-base tw-absolute tw-max-h-96 tw-w-full tw-bg-white tw-rounded-md tw-overflow-y-auto tw-shadow-card tw-z-20"
    >
      <BaseRecursiveRender
        v-for="(option, index) in context.options"
        :key="`option_${index}`"
        tag="li"
        :item="option"
        recursive-key="children"
        :aria-label="option.label"
      >
        <template #render="{ item }">
          <button
            type="button"
            :title="item.label"
            :disabled="item.disabled"
            :class="[
              item.children ? 'tw-font-semibold' : 'tw-font-normal',
              {'hover:tw-bg-gray-e9 focus:tw-bg-gray-e9': !item.disabled },
              'tw-px-2 tw-py-1.25 tw-w-full tw-text-left tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-text-sm'
            ]"
            @click.stop="selectItem(item)"
          >
            <span class="tw-truncate tw-flex-grow">{{ item.label || '' }}</span>
            <i
              v-if="item.value && model.includes(item.value)"
              :aria-checked="true"
              class="fas fa-check-circle tw-my-auto tw-text-success"
            />
          </button>
        </template>
      </BaseRecursiveRender>
    </ul>
  </div>
</template>

<script>
import union from 'lodash/union'
import difference from 'lodash/difference'

export default {
  name: 'BaseInputMultipleSelect',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      showOptions: false,
      arrowCounter: -1
    }
  },
  watch: {
    model: {
      immediate: true,
      handler (value) {
        if (!Array.isArray(value)) this.model = []
      }
    }
  },
  computed: {
    model: {
      get () {
        return this.context.model
      },
      set (val) {
        this.context.model = val
      }
    }
  },
  mounted () {
    document.addEventListener('click', this.handleClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    getChildValues (children = []) {
      const values = []
      children.forEach(child => {
        if (child.value) values.push(child.value)
        if (child.children) values.push(...this.getChildValues(child.children))
      })
      return values
    },
    selectItem (item) {
      const childValues = this.getChildValues(item.children)

      const values = [...childValues]
      if (item.value) values.push(item.value)

      const valuesExist = values.every(value => this.model.includes(value))
      this.model = valuesExist ? difference(this.model, values) : union(this.model, values)
      return values
    },
    onEsc () {
      this.showOptions = false
      this.context.blurHandler()
    },
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) this.onEsc()
    }
  }
}
</script>
