export const marketingRoutes = [
  {
    path: '/marketing/flyer',
    name: 'CreateFlyer',
    component: () => import(/* webpackChunkName: "Flyer" */ '@/views/marketing/Flyer'),
    meta: {
      navigationGroupName: 'Marketing'
    }
  },
  {
    path: '/marketing/shop',
    name: 'Shop',
    component: () => import(/* webpackChunkName: "Shop" */ '@/views/marketing/Shop'),
    meta: {
      navigationGroupName: 'Marketing'
    }
  },
  {
    path: '/marketing/shop/orders',
    name: 'ShopOrders',
    component: () => import(/* webpackChunkName: "ShopOrders" */ '@/views/marketing/ShopOrders'),
    meta: {
      navigationGroupName: 'Marketing'
    }
  },
  {
    path: '/marketing/shop/orders/:id',
    name: 'ShopOrderDetails',
    component: () => import(/* webpackChunkName: "ShopOrderDetails" */ '@/views/marketing/ShopOrderDetails'),
    meta: {
      navigationGroupName: 'Marketing'
    }
  },
  {
    path: '/marketing/shop/business-card/:productId',
    name: 'ShopBusinessCard',
    component: () => import(/* webpackChunkName: "ShopBusinessCard" */ '@/views/marketing/ShopBusinessCard'),
    props: true,
    meta: {
      navigationGroupName: 'Marketing'
    }
  }
]
