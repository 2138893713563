<!--PropertyItem.vue
This component allows selecting of a Property by search/autocomplete and can show a menu that
allows for selecting of related contacts, files

A PropertySelection object is used to store the selected property and it's parameters.
  A blanc PropertySelection object can be created like this:
    {cid: 'id-' + Math.random().toString(36).substr(2, 16)}

  When handling events you can store extra information (such as recipients) in the PropertySelection object.

Properties
  - property
    PropertySelection object to use
  - showAttachments
    Show the attachment menu when a property is selected
  - showContent
    Show the content selection menu (detail, description, link, etc)

Events sent by the component

 - remove
    Fired when the property selection is removed
    Handler: function (property)
 - select
    Fired when a property has been selected
    Handler: function (currentProperty, newProperty)
 - select-attachment
    Fired when an attachment is selected
    Handler: function (file) {}
 - select-recipient
    Fired when a contact is selected as recipient
    Handler: function (currentRecipient, newRecipient)
 - add-to
    Fired when a contact is selected as receiver
    Handler: function (currentRecipient, newRecipient)
 - add-to-email-body
    Fired when a piece of text is selected
    Handler: function (html)
-->

<template>
  <div>
    <div class="col-xs-1 no-padding" style="padding-top:15px!important;">
      <button @click="emitRemoveProperty" class="btn btn-xs btn-danger" type="button">
        <i class="fa fa-minus"></i>
      </button>
    </div>

    <div class="col-xs-8 no-padding" style="padding-top: 10px !important">
      <PropertyPicker ref="picker" v-model="selectedProperty" :canClear="false" @input="emitSelect" />
    </div>

    <div class="col-xs-3 no-padding" v-bind:style="menuStyle" v-if="selectedProperty">
      <!-- foldersKey is used to identify the folder submenu
      To deal with the property's folders or files being changed after the modal was minimized,
      We need to have a way to re-load the files and folders, yet only load them once, on mouseover -->
      <button
        type="button"
        data-toggle="dropdown"
        class="btn btn-warning btn-xs pull-left dropdown-toggle"
        @click="loadDetails"
      >
        <i :class="['fa', loadingDetails ? 'fa-spinner fa-spin' : 'fa-bars']" />
      </button>
      <ul class="dropdown-menu">
        <li class="dropdown-submenu">
          <a v-if="
              (shortlist && !shortlist.length) &&
              (coldlist && !coldlist.length) &&
              (breaklist && !breaklist.length)
            "
            class="disabled"
          >
            Adresseer kandidaten
          </a>
          <a v-else href="#" tabindex="-1"> Adresseer kandidaten</a>
          <ul class="dropdown-menu up">
            <li>
              <a v-if="shortlist && shortlist.length" @click="emitMailEntities('shortlist')">Adresseer
                shortlist</a>
              <a v-else class="disabled">Adresseer shortlist</a>
            </li>
            <li>
              <a v-if="coldlist && coldlist.length" @click="emitMailEntities('coldlist')">Adresseer coldlist</a>
              <a v-else class="disabled">Adresseer coldlist</a>
            </li>
            <li>
              <a v-if="breaklist && breaklist.length" @click="emitMailEntities('breaks')">Adresseer breaks</a>
              <a v-else class="disabled">Adresseer breaks </a>
            </li>
          </ul>
        </li>

        <li>
          <a v-if="owners && owners.length" @click="emitMailEntities('owners')">Adresseer eigenaar(s)</a>
          <a v-else class="disabled">Adresseer eigenaar(s)</a>
        </li>
        <li>
          <a v-if="buyers && buyers.length" @click="emitMailEntities('buyers')">Adresseer koper(s)</a>
          <a v-else class="disabled">Adresseer koper(s)</a>
        </li>
        <li>
          <a v-if="renters && renters.length" @click="emitMailEntities('renters')">Adresseer huurder(s)</a>
          <a v-else class="disabled">Adresseer huurder(s)</a>
        </li>
        <li>
          <a
            v-if=" (notaryOwner && Object.entries(notaryOwner).length) || (notaryBuyer && Object.entries(notaryBuyer).length)"
            @click="emitMailEntities('notaries')"
          >
            Adresseer notarissen
          </a>
          <a v-else class="disabled">Adresseer notarissen</a>
        </li>
        <template v-if="showContent">
          <li>
            <hr/>
          </li>

          <!-- Add property detail language selection fields -->
          <li class="dropdown-submenu">
            <a>Voeg pandfiche(s) toe</a>
            <ul class="dropdown-menu up">
              <li v-for="language in languages" :key="'detail' + language">
                <a @click="emitMailContent('detail', language)">
                  {{ language }}
                </a>
              </li>
            </ul>
          </li>

          <!-- Short description language selection fields -->
          <li class="dropdown-submenu">
            <a :class="{ disabled: !anyDescriptionsExist(descriptionTypes.short.value) }">
              Voeg korte beschrijving(en) in
            </a>
            <ul class="dropdown-menu up">
              <li v-for="language in languages" :key="'short' + language">
                <a
                  v-if="descriptionExists(descriptionTypes.short.value, language)"
                  @click="emitMailContent(descriptionTypes.short.label, language)"
                >
                  {{ language }}
                </a>
                <a v-else class="disabled">{{ language }}</a>
              </li>
            </ul>
          </li>

          <!--Long description language selection fields -->
          <li class="dropdown-submenu">
            <a :class="{disabled: !anyDescriptionsExist(descriptionTypes.long.value)}">Voeg beschrijving(en) in</a>
            <ul class="dropdown-menu up">
              <li v-for="language in languages" :key="'long' + language">
                <a
                  v-if="descriptionExists(descriptionTypes.long.value, language)"
                  @click="emitMailContent(descriptionTypes.long.label, language)"
                >
                  {{ language }}
                </a>
                <a v-else class="disabled">{{ language }}</a>
              </li>
            </ul>
          </li>

          <li><a @click="emitMailContent('link', 'nl')">Voeg link in</a></li>
        </template>
        <!-- To ensure the best performance and not accidentally DDOS ourselves by making 100s of requests as the user hover
        over all the folders/files, we use the `once` modifier for the callback to be triggered only once. -->
        <li class="dropdown-submenu">
          <a :key="foldersKey" @mouseover.once="loadPropertyFolders">Voeg bijlage toe</a>
          <ul class="dropdown-menu up">
            <li v-if="loadingFolders">
              <a class="tw-italic tw-cursor-not-allowed">
                Map laden...
              </a>
            </li>
            <template v-else>
              <li
                v-for="folder in folders"
                :key="folder.id"
                class="dropdown-submenu"
              >
                <a @mouseover.once="loadPropertyFiles(folder.id)">
                  {{ folder.name }}
                </a>
                <ul class="dropdown-menu tw-overflow-auto tw-max-h-80">
                  <li v-if="loadingFiles[folder.id]">
                    <a class="tw-italic tw-cursor-not-allowed">
                      Bestanden laden...
                    </a>
                  </li>
                  <template v-else-if="files[folder.id] && files[folder.id].length">
                    <li
                      v-for="file in files[folder.id]"
                      :key="file.id"
                      @click.stop="emitAttachment(file)"
                    >
                      <a>
                        <span class="tw-font-bold tw-truncate">{{ file.filename }}</span>
                        <br>
                        <span class="tw-italic tw-pb-1 tw-truncate">{{ file.title }}</span>
                      </a>
                    </li>
                  </template>
                  <li v-else>
                    <a class="tw-italic tw-cursor-not-allowed">
                      Geen bestanden gevonden
                    </a>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PropertyPicker from '@/components/properties/PropertyPicker'

import { mapActions, mapGetters } from 'vuex'
import { getPropertyFileFolders, getPropertyFiles, getPropertyOwners, getPropertyRenters, getPropertyBuyers } from '@/services/properties'
import { errorModal } from '@/modalMessages'
import { getRelationsForLeadsMailclient } from '@/services/sales'

export default {
  name: 'PropertyItem',
  components: {
    PropertyPicker
  },
  props: {
    property: {
      default () {
        return {}
      }
    },
    showContent: {
      type: Boolean,
      default: true
    }
  },

  mounted () {
    const p = this.property.property
    if (p && p.id) {
      this.selectedProperty = JSON.parse(JSON.stringify(p))
    }
  },
  data () {
    return {
      selectedProperty: null,
      loadingFolders: false,
      loadingFiles: {},
      foldersKey: null, // To let the mouseover events work again, when the user opens the menu
      folders: [],
      files: {},
      candidateCache: [],
      selectedLanguage: 'nl',
      languages: ['nl', 'fr', 'en'],
      shortlist: [],
      coldlist: [],
      breaklist: [],
      owners: [],
      renters: [],
      buyers: [],
      loadingDetails: false,
      detailsLoaded: false
    }
  },
  methods: {
    ...mapActions('properties', [
      'loadProperty',
      'loadPropertyPictures',
      'loadPropertyDescriptions'
    ]),

    async loadDetails () {
      this.foldersKey = Math.random()
      if (!this.detailsLoaded) {
        await this.setPropertyAttributes()
        this.loadingDetails = false
      }
    },

    /* Sets all attributes for a property if any changes occur for the selected property */
    async setPropertyAttributes () {
      try {
        this.loadingDetails = true
        const propertyId = this.selectedProperty.id
        const response = await Promise.all([
          this.loadProperty(propertyId),
          this.loadPropertyBuyers(propertyId),
          this.loadPropertyRenters(propertyId),
          this.loadPropertyDescriptions(propertyId),
          this.loadPropertyPictures(propertyId),
          this.loadShortlist(propertyId),
          this.loadColdlist(propertyId),
          this.loadBreaklist(propertyId),
          this.loadPropertyOwners(propertyId)
        ])
        this.detailsLoaded = true
        return response
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het ophalen van pandgegevens, probeer het opnieuw.')
      } finally {
        this.loadingDetails = false
      }
    },

    async loadPropertyOwners (propertyId) {
      try {
        const response = await getPropertyOwners(propertyId)
        this.owners = response.data
        return response
      } catch {
        errorModal('Eigenaars kunnen niet worden geladen')
      }
    },

    async loadPropertyBuyers (propertyId) {
      try {
        const response = await getPropertyBuyers(propertyId)
        this.buyers = response.data
        return response
      } catch {
        errorModal('Kopers kunnen niet worden ingeladen')
      }
    },

    async loadPropertyRenters (propertyId) {
      try {
        const response = await getPropertyRenters(propertyId)
        this.renters = response.data
        return response
      } catch {
        errorModal('Huurders kunnen niet worden ingeladen')
      }
    },

    async loadShortlist (propertyId) {
      const payload = {}
      payload.params = { property_id: propertyId, status: 0 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.shortlist = response.data?.results
      return response
    },

    async loadColdlist (propertyId) {
      const payload = {}
      payload.params = { property_id: propertyId, status: 4 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.coldlist = response.data?.results
      return response
    },

    async loadBreaklist (propertyId) {
      const payload = {}
      payload.params = { property_id: propertyId, status: 2 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.breaklist = response.data?.results
      return response
    },

    async loadPropertyFolders () {
      try {
        this.loadingFolders = true
        this.folders = [{ id: 'unset', name: 'Bestanden' }]
        const response = await getPropertyFileFolders(this.selectedProperty.id)
        if (response?.data?.results?.length) this.folders.push(...response.data.results)
        return response
      } catch (error) {
        errorModal('Fout bij het laden van pandmappen.')
      } finally {
        this.loadingFolders = false
      }
    },
    async loadPropertyFiles (folder_id) {
      try {
        this.$set(this.loadingFiles, folder_id, true)
        const propertyId = this.selectedProperty.id
        const response = await getPropertyFiles({ propertyId, params: { folder_id, page_size: 1000 } })
        const validAttachments = response?.data?.results.filter(file => !file.access_should_be_logged)
        this.$set(this.files, folder_id, validAttachments)
        return response
      } catch (error) {
        errorModal('Fout bij het laden van pandbestanden.')
      } finally {
        this.$set(this.loadingFiles, folder_id, false)
      }
    },

    /* Gets all 'recipient entities' for a given list type for the selected property */
    getRecipientEntities (listType) {
      if (listType === 'shortlist') {
        return this.shortlist
      } else if (listType === 'coldlist') {
        return this.coldlist
      } else if (listType === 'breaks') {
        return this.breaklist
      } else if (listType === 'buyers') {
        return this.buyers
      } else if (listType === 'renters') {
        return this.renters
      }
    },

    /* Gets all 'to entities' for a list type for the selected property */
    getToEntities (listType) {
      // list type owners or buyers

      if (listType === 'owners') {
        return this.owners
      } else if (listType === 'notaries') {
        return this.notaries
      }
    },
    /* Emits all entities for the mail */
    emitMailEntities (type) {
      const recipientLists = ['shortlist', 'coldlist', 'breaks', 'buyers', 'renters']
      const toLists = ['owners', 'notaries']
      let toEntities = []
      let recipientEntities = []

      if (recipientLists.includes(type)) {
        recipientEntities = this.getRecipientEntities(type) || []
      } else if (toLists.includes(type)) {
        toEntities = this.getToEntities(type) || []
      } else {
        console.error('Invalid list type specified')
      }
      recipientEntities.forEach(recipientEntity => this.$emit('select-recipient', null, recipientEntity))
      toEntities.forEach(toEntity => this.$emit('add-to', null, toEntity))
    },

    getLink () {
      const prop = this.getPropertyById(this.selectedProperty.id)
      const url = prop.redirect_url
      const reference = prop.reference
      const street = prop.street
      const cityName = prop.city.name
      const number = prop.number
      return `<a href="${url}">${reference} ${street} ${number} ${cityName}</a>`
    },

    /* Emits content for the mail */
    emitMailContent (contentType, language) {
      let output = null
      this.selectedLanguage = language

      const contentTypes = {
        detail: this.details,
        link: this.getLink(),
        [this.descriptionTypes.short.label]: this.shortDescription,
        [this.descriptionTypes.long.label]: this.longDescription
      }

      const contentTypeMissing = !Object.hasOwnProperty.call(contentTypes, contentType)
      if (contentTypeMissing) {
        console.error('Invalid content type specified')
        return
      }

      output = contentTypes[contentType]
      if (output) this.$emit('add-to-email-body', output)
    },

    /** Emits attachments for the mail */
    emitAttachment (file) {
      // propertyId is used to identify what property the files belong to.
      const attachment = { property_id: this.property?.property?.id, ...file }
      this.$emit('select-attachment', attachment)
    },

    /** Emits that a property has been added */
    emitSelect () {
      this.$emit('select', this.property, this.selectedProperty)
    },

    /** Emits that a property has been removed */
    emitRemoveProperty () {
      this.$emit('remove', this.property)
    },
    /** Check if any descriptions of a certain type exist */
    anyDescriptionsExist (type) {
      const description = this.descriptions.filter(description => description.type === type)[0]
      if (!(description && description.text)) return false
      if (description.text.text_nl || description.text.text_fr || description.text.text_en) return true
      return false
    },
    /** Check if a description of a specific type and language exists */
    descriptionExists (type, language) {
      const description = this.descriptions.filter(description => description.type === type)[0]
      if (!description) return false
      if (language === 'nl' && description.text.text_nl) return true
      if (language === 'fr' && description.text.text_fr) return true
      if (language === 'en' && description.text.text_en) return true
      return false
    },
    /** Get a description of a specific type */
    getDescription (descriptionType) {
      const language = this.selectedLanguage
      for (const description of this.descriptions) {
        if (description.type !== descriptionType.value) continue
        let selectedDescription = description.text.text_nl
        if (language === 'fr' && description.text.text_fr) selectedDescription = description.text.text_fr
        if (language === 'en' && description.text.text_en) selectedDescription = description.text.text_en
        return selectedDescription
      }
      return null
    }
  },
  computed: {
    ...mapGetters('properties', [
      'getPropertyById',
      'getPropertyDescriptions',
      'getPropertyPictures'
    ]),
    ...mapGetters('sales', ['getLeadsForProperty']),

    descriptionMediums () {
      return {
        web: 0,
        print: 1
      }
    },
    descriptionTypes () {
      return {
        title: {
          value: 0,
          label: 'title_desc'
        },
        short: {
          value: 1,
          label: 'short_desc'
        },
        long: {
          value: 2,
          label: 'long_desc'
        }
      }
    },
    menuStyle () {
      if (this.selectedProperty) {
        return { 'padding-top': '15px!important', 'padding-bottom': '7px!important' }
      } else {
        return { 'padding-top': '15px!important', 'padding-bottom': '29px!important' }
      }
    },
    notaryOwner () {
      return this.getPropertyById(this.selectedProperty.id)?.notary_of_owners
    },
    notaryBuyer () {
      return this.getPropertyById(this.selectedProperty.id)?.notary_of_buyers
    },
    notaries () {
      const notaries = []
      if (this.notaryOwner) notaries.push(this.notaryOwner)
      if (this.notaryBuyer) notaries.push(this.notaryBuyer)
      return notaries
    },
    publishAddress () {
      const property = this.getPropertyById(this.selectedProperty.id)
      return property.publish_address
    },
    displayAddressDetails () {
      const property = this.getPropertyById(this.selectedProperty.id)
      if (property.public_address) {
        return property.public_address
      } else {
        return ''
      }
    },
    descriptions () {
      return this.getPropertyDescriptions(this.selectedProperty.id)
        .filter(description => description.medium === this.descriptionMediums.web)
    },
    shortDescription () {
      return this.getDescription(this.descriptionTypes.short)
    },
    longDescription () {
      return this.getDescription(this.descriptionTypes.long)
    },
    pictures () {
      return this.getPropertyPictures(this.selectedProperty.id)
    },
    thumbnail () {
      const pictures = this.pictures
      let thumbnailUrl = null
      if (pictures && pictures.length > 0) {
        pictures.forEach(function (picture) {
          if (picture.is_main_picture) {
            thumbnailUrl = picture.url_thumbnail
          }
        })
      }
      return thumbnailUrl
    },
    price () {
      const prop = this.getPropertyById(this.selectedProperty.id)
      if (prop.publish_price) {
        let c = parseFloat(prop.price).toFixed(0)
        c = c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        return `<strong>${c} &euro;</strong>`
      } else {
        return ''
      }
    },
    details () {
      const prop = this.getPropertyById(this.selectedProperty.id)
      const thumbnail = this.thumbnail
      const priceOutput = this.price
      const status = prop[`status_display_${this.selectedLanguage}`]
      const url = prop.redirect_url
      const displayAddress = this.displayAddressDetails
      const type = prop.type[`name_${this.selectedLanguage}`]
      let output = null

      if (thumbnail) {
        output = `<table>
            <tr>
                <td>
                    <img style="width: 150px" alt="image" src="${thumbnail}">
                </td>
                <td style="padding-left: 5px">
                    <strong>${status}</strong><br/>
                    <a href=${url}>
                        ${type}
                    </a><br/>
                    ${displayAddress}
                    ${priceOutput}<br />
                </td>
            </tr>
           </table>`
      } else {
        output = `<table>
            <tr>
                <td>
                    <strong>${status}</strong><br/>
                    <a href="${url}">${type}</a><br/>
                    ${displayAddress}
                    ${priceOutput}
                </td>
            </tr>
           </table>`
      }
      return output
    }
  }
}
</script>

<style scoped>
.disabled {
  color: #b4b4b4;
  cursor: not-allowed;
}

.disabled:hover {
  color: #b4b4b4 !important;
}
</style>
