import BaseApi from './api'

class CollaboratorApi extends BaseApi {
  constructor () {
    super({
      url: '/api/collaborator',
      timeout: 1 * 1000,
      name: 'iam-organization-colleagues'
    })
  }
}

export { CollaboratorApi }
