<template>
  <component :is="tag" v-bind="$attrs" v-on="$listeners">
    <slot name="render" :item="item" />
    <template v-if="item[recursiveKey]">
      <BaseRecursiveRender
        v-for="(child, index) in item[recursiveKey]"
        :key="`${index}_${uniqueId()}`"
        :tag="tag"
        :item="child"
        :recursive-key="recursiveKey"
        class="tw-ml-3 tw-border-l"
      >
        <template #render="{ item }">
          <slot name="render" :item="item" />
        </template>
      </BaseRecursiveRender>
    </template>
  </component>
</template>

<script>
import uniqueId from 'lodash/uniqueId'

export default {
  name: 'BaseRecursiveRender',
  inheritAttrs: false,
  props: {
    tag: {
      type: String,
      default: 'div'
    },
    item: {
      type: Object,
      default () { return {} }
    },
    recursiveKey: {
      type: String
    }
  },
  methods: { uniqueId }
}
</script>
