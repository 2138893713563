<template>
  <div>
    <div class="col-xs-1 no-padding" style="padding-top:15px!important;">
      <button type="button" class="btn btn-xs btn-danger" @click="emitRemoveProject">
        <i class="far fa-minus" />
      </button>
    </div>

    <div class="col-xs-6 no-padding" style="padding-top: 10px !important">
      <ProjectPicker
        ref="picker"
        v-model="selectedProject"
        :canClear="false"
        @deselect="emitDeselectProject"
        @input="emitSelect"
      />
    </div>

    <div class="col-xs-3 no-padding" style="padding-top:15px!important;" :style="menuStyle" v-if="selectedProject">
      <!-- foldersKey is used to identify the folder submenu
      To deal with the property's folders or files being changed after the modal was minimized,
      We need to have a way to re-load the files and folders, yet only load them once, on mouseover -->
      <button
        type="button"
        data-toggle="dropdown"
        class="btn btn-warning btn-xs pull-left dropdown-toggle"
        @click="loadDetails"
      >
        <i :class="['fa', loadingDetails ? 'fa-spinner fa-spin' : 'fa-bars']" />
      </button>
      <ul class="dropdown-menu dropdown-menu-right" style="left: auto!important;">
        <li>
          <a v-if="shortlist && shortlist.length" @click="emitMailEntities('shortlist')">
            Adresseer shortlist
          </a>
          <a v-else class="disabled">Adresseer shortlist</a>
        </li>
        <li>
          <a v-if="coldlist && coldlist.length" @click="emitMailEntities('coldlist')">Adresseer coldlist</a>
          <a v-else class="disabled">Adresseer coldlist</a>
        </li>
        <li>
          <a v-if="breaklist && breaklist.length" @click="emitMailEntities('breaks')">Adresseer breaks</a>
          <a v-else class="disabled">Adresseer breaks </a>
        </li>
        <li>
          <a v-if="owners && owners.length" @click="emitMailEntities('owners')">Adresseer eigenaar(s)</a>
          <a v-else class="disabled">Adresseer eigenaar(s)</a>
        </li>
        <li>
          <hr/>
        </li>

        <li><a @click="emitMailContent('detail')">Voeg projectfiche toe</a></li>
        <li>
          <a v-if="shortDescription" @click="emitMailContent('short_desc')">Voeg korte beschrijving(en) in</a>
          <a v-else class="disabled">Voeg korte beschrijving(en) in</a>
        </li>
        <li>
          <a v-if="longDescription" @click="emitMailContent('desc')">Voeg beschrijving(en) in</a>
          <a v-else class="disabled">Voeg beschrijving(en) in</a>
        </li>
        <li><a @click="emitMailContent('link')">Voeg link in</a></li>
        <!-- To ensure the best performance and not accidentally DDOS ourselves by making 100s of requests as the user hover
        over all the folders/files, we use the `once` modifier for the callback to be triggered only once. -->
        <li class="dropdown-submenu">
          <a :key="foldersKey" @mouseover.once="loadProjectFolders">Voeg bijlage toe</a>
          <ul class="dropdown-menu up">
            <li v-if="loadingFolders">
              <a class="tw-italic tw-cursor-not-allowed">
                Mappen laden...
              </a>
            </li>
            <template v-else>
              <li
                v-for="folder in folders"
                :key="folder.id"
                class="dropdown-submenu"
              >
                <a @mouseover.once="loadProjectFiles(folder.id)">
                  {{ folder.name }}
                </a>
                <ul class="dropdown-menu tw-overflow-auto">
                  <li v-if="loadingFiles">
                    <a class="tw-italic tw-cursor-not-allowed">
                      Bestanden laden...
                    </a>
                  </li>
                  <template v-else-if="files[folder.id] && files[folder.id].length">
                    <li
                      v-for="file in files[folder.id]"
                      :key="file.id"
                      @click.stop="emitAttachment(file)"
                    >
                      <a>
                        <span class="tw-font-bold tw-truncate">{{ file.filename }}</span>
                        <br>
                        <span class="tw-italic tw-pb-1 tw-truncate">{{ file.title }}</span>
                      </a>
                    </li>
                  </template>
                  <li v-else>
                    <a class="tw-italic tw-cursor-not-allowed">
                      Geen bestanden gevonden
                    </a>
                  </li>
                </ul>
              </li>
            </template>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import ProjectPicker from '@/components/properties/ProjectPicker'
import { mapActions, mapGetters } from 'vuex'

import { getProjectFileFolders, getProjectFiles, getProjectOwners } from '@/services/projects'
import { errorModal } from '@/modalMessages'
import { getRelationsForLeadsMailclient } from '@/services/sales'

export default {
  name: 'ProjectItem',
  components: { ProjectPicker },
  props: {
    project: {
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      selectedProject: null,
      loadingFolders: false,
      loadingFiles: false,
      foldersKey: null, // To let the mouseover events work again, when the user opens the menu
      folders: [],
      files: {},
      shortlist: [],
      coldlist: [],
      breaklist: [],
      owners: [],
      loadingDetails: false,
      detailsLoaded: false
    }
  },
  created () {
    const p = this.project?.project
    if (p?.id) this.selectedProject = JSON.parse(JSON.stringify(p))
  },
  methods: {
    ...mapActions('properties', [
      'loadProjectPictures',
      'loadProjectDescriptions'
    ]),
    async loadDetails () {
      this.foldersKey = Math.random()
      if (!this.detailsLoaded) {
        await this.setProjectAttributes()
      }
    },
    async setProjectAttributes () {
      try {
        const projectId = this.selectedProject.id
        this.loadingDetails = true
        const response = await Promise.all([
          this.loadProjectOwners(projectId),
          this.loadProjectPictures(projectId),
          this.loadProjectDescriptions(projectId),
          this.loadProjectFiles(projectId),
          this.loadShortlist(projectId),
          this.loadColdlist(projectId),
          this.loadBreaklist(projectId)
        ])
        this.detailsLoaded = true
        return response
      } catch {
        errorModal('Fout bij ophalen van projectinformatie, probeer het opnieuw.')
      } finally {
        this.loadingDetails = false
      }
    },

    async loadProjectOwners (projectId) {
      try {
        const response = await getProjectOwners(projectId)
        this.owners = response.data
        return response
      } catch {
        errorModal('Projecteigenaars kunnen niet worden geladen')
      }
    },

    async loadShortlist (projectId) {
      const payload = {}
      payload.params = { project_id: projectId, status: 0 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.shortlist = response.data?.results
      return response
    },

    async loadColdlist (projectId) {
      const payload = {}
      payload.params = { project_id: projectId, status: 4 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.coldlist = response.data?.results
      return response
    },

    async loadBreaklist (projectId) {
      const payload = {}
      payload.params = { project_id: projectId, status: 2 }
      const response = await getRelationsForLeadsMailclient(payload)
      this.breaklist = response.data?.results
      return response
    },

    async loadProjectFolders () {
      try {
        this.loadingFolders = true
        this.folders = [{ id: 'unset', name: 'Bestanden' }]
        const response = await getProjectFileFolders(this.selectedProject.id)
        if (response?.data?.results?.length) this.folders.push(...response.data.results)
        return response
      } catch (error) {
        errorModal('Fout bij het laden van projectmappen.')
      } finally {
        this.loadingFolders = false
      }
    },
    async loadProjectFiles (folder_id) {
      try {
        this.loadingFiles = true
        const projectId = this.selectedProject.id
        const response = await getProjectFiles({ projectId, params: { folder_id, page_size: 1000 } })
        this.files[folder_id] = response?.data?.results
        return response
      } catch (error) {
        errorModal('Fout bij het laden van projectbestanden.')
      } finally {
        this.loadingFiles = false
      }
    },

    emitDeselectProject () {
      this.$emit('deselect-project')
    },
    emitRemoveProject () {
      this.$emit('remove', this.project)
    },
    /* Gets all 'recipient entities' for a given list type for the selected property */
    getRecipientEntities (listType) {
      if (listType === 'shortlist') {
        return this.shortlist
      } else if (listType === 'coldlist') {
        return this.coldlist
      } else if (listType === 'breaks') {
        return this.breaklist
      }
    },
    /* Gets all 'to entities' for a list type for the selected property */
    getToEntities (listType) {
      // list type owners or buyers
      if (listType === 'owners') {
        return this.owners
      }
    },
    /* Emits all entities for the mail */
    emitMailEntities (type) {
      const recipientLists = ['shortlist', 'coldlist', 'breaks']
      const toLists = ['owners']
      let toEntities = []
      let recipientEntities = []

      if (recipientLists.includes(type)) {
        recipientEntities = this.getRecipientEntities(type) || []
      } else if (toLists.includes(type)) {
        toEntities = this.getToEntities(type) || []
      } else {
        console.error('Invalid list type specified')
      }

      // Make entities unique
      recipientEntities = Array
        .from(new Set(recipientEntities.map(recipientEntity => recipientEntity.id)))
        .map(id => recipientEntities.find(recipientEntity => recipientEntity.id === id))
      toEntities = Array
        .from(new Set(toEntities.map(toEntity => toEntity.id)))
        .map(id => toEntities.find(toEntity => toEntity.id === id))

      recipientEntities.forEach(recipientEntity => this.$emit('select-recipient', null, recipientEntity))
      toEntities.forEach(toEntity => this.$emit('add-to', null, toEntity))
    },
    emitSelect () {
      this.$emit('select', this.project, this.selectedProject)
    },
    emitMailContent (contentType) {
      let output = null
      if (contentType === 'detail') {
        output = this.details
      } else if (contentType === 'short_desc') {
        output = this.shortDescription
      } else if (contentType === 'desc') {
        output = this.longDescription
      } else if (contentType === 'link') {
        output = this.link
      } else {
        console.error('Invalid content type specified')
      }
      this.$emit('add-to-email-body', output)
    },
    emitAttachment (file) {
      // projectId is used to identify what property the files belong to.
      const attachment = { project_id: this.project?.project?.id, ...file }
      this.$emit('select-attachment', attachment)
    }
  },
  computed: {
    ...mapGetters('properties', [
      'getProjectPictures',
      'getProjectFiles',
      'getProjectDescriptions'
    ]),
    /** Retrieve all leads for all properties subsiding under the project */
    descriptions () {
      return this.getProjectDescriptions(this.selectedProject.id)
    },
    shortDescription () {
      for (const description of this.descriptions) {
        if (description.type === 1) return description.text.text_nl
      }
      return null
    },
    longDescription () {
      for (const description of this.descriptions) {
        if (description.type === 2) return description.text.text_nl
      }
      return null
    },
    pictures () {
      return this.getProjectPictures(this.selectedProject.id)
    },
    link () {
      const url = this.selectedProject.redirect_url
      const reference = this.selectedProject.reference
      const street = this.selectedProject.street
      const number = this.selectedProject.number
      const cityName = this.selectedProject.city.name
      return `<a href="${url}">${reference} ${street} ${number} ${cityName}</a>`
    },
    thumbnail () {
      const pictures = this.pictures
      let thumbnailUrl = null
      const secondaryThumbnailUrls = []
      if (pictures.length > 0) {
        pictures.forEach(function (picture) {
          if (picture.is_main_picture) {
            thumbnailUrl = picture.url_thumbnail
          } else {
            secondaryThumbnailUrls.push(picture.url_thumbnail)
          }
        })
      }
      thumbnailUrl = thumbnailUrl || secondaryThumbnailUrls[0]

      if (thumbnailUrl) {
        return thumbnailUrl
      } else {
        return null
      }
    },
    details () {
      const thumbnailUrl = this.thumbnail
      const url = this.selectedProject.url
      const reference = this.selectedProject.reference
      const street = this.selectedProject.street
      const zipcode = this.selectedProject.city.zip_code
      const number = this.selectedProject.number
      const cityName = this.selectedProject.city.name
      let output = null

      if (thumbnailUrl) {
        output = `<table>
                        <tr>
                            <td>
                                <img style="width: 150px" alt="image" src="${thumbnailUrl}">
                            </td>
                            <td>
                                <a href="${url}">${reference}</a><br/><br/>
                                    ${street} ${number}<br/>
                                    ${zipcode} ${cityName}
                            </td>
                        </tr>
                    </table>`
      } else {
        output = `<table>
                        <tr>
                            <td>
                                <a href="${url}"> ${reference}</a><br/><br/>
                                ${street} ${number} <br/>
                                ${zipcode} ${cityName}
                            </td>
                        </tr>
                    </table>`
      }
      return output
    },
    menuStyle () {
      if (this.selectedProject) {
        return { 'padding-top': '15px!important', 'padding-bottom': '7px!important' }
      } else {
        return { 'padding-top': '15px!important', 'padding-bottom': '29px!important' }
      }
    }
  }
}
</script>

<style scoped>
.disabled {
  color: #b4b4b4;
  cursor: not-allowed;
}

.disabled:hover {
  color: #b4b4b4 !important;
}
</style>
