<template>
  <button
    type="button"
    :title="label"
    v-bind="$attrs"
    v-on="$listeners"
    :class="[style, 'tw-px-4 tw-py-2 tw-text-white tw-text-center tw-rounded-md tw-text-sm focus:tw-outline-none']"
  >
    <i v-if="labelIcon" :class="`tw-mr-1 fa fa-${labelIcon}`" /> {{ label }}
  </button>
</template>

<script>
export default {
  name: 'BaseButton',
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      required: true
    },
    labelIcon: {
      type: String,
      required: false
    },
    color: {
      type: String,
      default: 'default'
    }
  },
  computed: {
    buttonColor () {
      const colorExists = ['primary', 'warning', 'danger', 'default', 'info', 'success'].includes(this.color)
      if (colorExists) return this.color
      return 'default'
    },
    style () {
      const styles = {
        primary: 'tw-bg-primary tw-border-primary hover:tw-bg-darken-primary hover:tw-border-darken-primary focus:tw-bg-darken-primary focus:tw-border-darken-primary active:tw-bg-darken-primary active:tw-border-darken-primary',
        warning: 'tw-bg-warning tw-border-warning hover:tw-bg-darken-warning hover:tw-border-darken-warning focus:tw-bg-darken-warning focus:tw-border-darken-warning active:tw-bg-darken-warning active:tw-border-darken-warning',
        danger: 'tw-bg-danger tw-border-danger hover:tw-bg-darken-danger hover:tw-border-darken-danger focus:tw-bg-darken-danger focus:tw-border-darken-danger active:tw-bg-darken-danger active:tw-border-darken-danger',
        info: 'tw-bg-info tw-border-info hover:tw-bg-darken-info hover:tw-border-darken-info focus:tw-bg-darken-info focus:tw-border-darken-info active:tw-bg-darken-info active:tw-border-darken-info',
        success: 'tw-bg-success tw-border-success hover:tw-bg-darken-success hover:tw-border-darken-success focus:tw-bg-darken-success focus:tw-border-darken-success active:tw-bg-darken-success active:tw-border-darken-success',
        default: 'tw-border tw-border-gray-200 tw-bg-white tw-text-tg-color hover:tw-bg-gray-200 hover:tw-border-gray-300 focus:tw-bg-gray-200 focus:tw-border-gray-300 active:tw-bg-gray-200 active:tw-border-gray-300'
      }
      return styles[this.color] || styles.default
    }
  }
}
</script>
