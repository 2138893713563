<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <Modal
    :showCloseButton="false"
    :showMinimizeButton="true"
    ref="modal"
    size="xl"
    title="E-mail versturen"
  >
    <div>
      <form class="form-horizontal" method="get">
        <div class="row">
          <div class="col-sm-5">
            <MailRecipient
              :recipients="candidateRecipients"
              title="Kandidaten"
              type="candidate"
              help="Iedere kandidaat krijgt een individuele e-mail en er wordt een lead + activiteit aangemaakt."
              @add-recipient="addRecipientForm"
              @remove-recipient="removeRecipient"
              @select-recipient="selectRecipient"
            />
            <div class="tw-flex tw-flex-wrap tw-align-center tw-gap-x-2 tw-ml-[117px] tw-my-2">
              <div class="tw-flex">
                <input
                  type="radio"
                  id="leadstatus"
                  v-model="leadStatus"
                  value="0"
                  class="!tw-mr-1 !tw-my-0 tw-self-center"
                />
                <label
                  for="leadtype"
                  class="!tw-my-0 tw-self-center"
                >
                  Shortlist
                </label>
              </div>
              <div class="tw-flex">
                <input
                  type="radio"
                  id="leadstatus"
                  v-model="leadStatus"
                  value="4"
                  class="!tw-mr-1 !tw-my-0 tw-self-center"
                />
                <label
                  for="leadtype"
                  class="!tw-my-0 tw-self-center"
                >
                  Coldlist
                </label>
              </div>
            </div>

            <MailRecipient
              :recipients="toRecipients"
              title="Aan"
              type="to"
              help="Ieder contact bij Aan krijgt een individuele e-mail"
              @add-recipient="addRecipientForm"
              @remove-recipient="removeRecipient"
              @select-recipient="selectRecipient"
            />

            <div class="form-group no-margin-bottom margin-top-20">
              <label
                class="col-sm-2 no-padding control-label"
                style="padding-right: 15px!important; padding-top: 5px!important;"
              >
                <HelpToolTip
                  title="Groepen"
                  placement="bottom"
                  content="Ieder contact in deze groep krijgt een individuele mail"
                >
                  Groep:
                </HelpToolTip>
              </label>
              <div class="col-sm-9">
                <select v-model="selectedGroupId" class="form-control input-sm">
                  <option v-for="group in groups" :key="group.value" :value="group.value" v-text="group.name" />
                </select>
              </div>
              <div class="col-xs-1 no-padding" style="padding-top: 5px!important;">
                <button
                  v-if="selectedGroupId"
                  type="button"
                  class="btn btn-xs btn-danger"
                  style="float: right;"
                  @click="clearGroup"
                >
                  <i class="fa fa-minus" />
                </button>
              </div>
            </div>

            <div v-if="showGroupLeadsCheckbox" class="form-group no-margin-bottom margin-top-20">
              <div class="col-sm-2"/>
              <div class="col-sm-10">
                <label class="no-padding control-label">
                <input type="checkbox" v-model="createLeadsForGroup"/> Leads aanmaken voor ontvangers</label>
              </div>
            </div>

            <MailRecipient
              :recipients="ccRecipients"
              type="cc"
              title="CC"
              help="Ieder contact bij CC zal in CC staan bij iedere e-mail die verstuurd wordt"
              @add-recipient="addRecipientForm"
              @remove-recipient="removeRecipient"
              @select-recipient="selectRecipient"
            />

            <MailRecipient
              :recipients="bccRecipients"
              type="bcc"
              title="BCC"
              help="Ieder contact bij BCC zal in BCC staan bij iedere e-mail die verstuurd wordt"
              @add-recipient="addRecipientForm"
              @remove-recipient="removeRecipient"
              @select-recipient="selectRecipient"
            />

            <div class="form-group margin-top-20" style="margin-bottom: 0px">
              <label
                class="col-sm-2 no-padding control-label"
                style="padding-right: 15px!important; padding-top: 15px!important;"
              >
                Panden:
              </label>
              <div class="col-sm-10">
                <div class="col-xs-1 no-padding" style="padding-top: 15px!important;">
                  <a @click="addPropertyForm" class="btn btn-xs btn-primary">
                    <i class="fa fa-plus" />
                  </a>
                </div>
                <div class="col-xs-11 no-padding">
                  <div v-for="property in properties" :key="property.cid" class="row no-padding">
                    <PropertyItem
                      :property="property"
                      @add-to="selectTo"
                      @add-to-email-body="addToEmailBody"
                      @remove="removeProperty"
                      @select="selectProperty"
                      @select-attachment="addAttachment"
                      @select-recipient="selectRecipient"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group margin-top-20">
              <label
                class="col-sm-2 no-padding control-label"
                style="padding-right: 15px!important; padding-top: 15px!important;"
              >
                Projecten:
              </label>
              <div class="col-sm-10">
                <div class="col-xs-1 no-padding" style="padding-top: 15px!important;">
                  <a @click="addProjectForm" class="btn btn-xs btn-primary">
                    <i class="fa fa-plus" />
                  </a>
                </div>
                <div class="col-xs-11 no-padding">
                  <div v-for="project in projects" :key="project.cid" class="row no-padding">
                    <ProjectItem
                      :project="project"
                      @add-to="selectTo"
                      @add-to-email-body="addToEmailBody"
                      @remove="removeProject"
                      @select="selectProject"
                      @select-attachment="addAttachment"
                      @select-recipient="selectRecipient"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-7">
            <div class="form-group">
              <div class="col-sm-12">
                <input
                  v-model="subject"
                  type="text"
                  placeholder="Het onderwerp van je e-mail"
                  class="form-control"
                />
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-9">
                <select class="form-control" ref="templateSelect" v-model="selectedTemplate">
                  <option disabled selected value="">Kies indien gewenst een standaard tekst</option>
                  <template v-for="template in selectableTemplates">
                    <option
                      :key="template.id"
                      :value="template"
                      v-text="template.name"
                    />
                  </template>
                </select>
              </div>
              <div class="col-sm-3">
                <div class="btn-group" v-if="selectedTemplate">
                  <button
                    v-if="selectedTemplate.content_nl"
                    id="selectedTemplate_nl"
                    type="button"
                    data-id="selectedTemplate_nl"
                    class="btn btn-primary"
                    @click="selectTemplateLanguage('nl')"
                  >
                    NL
                  </button>
                  <button
                    v-if="selectedTemplate.content_fr"
                    id="selectedTemplate_fr"
                    type="button"
                    data-id="selectedTemplate_fr"
                    class="btn btn-danger"
                    @click="selectTemplateLanguage('fr')"
                  >
                    FR
                  </button>
                  <button
                    id="selectedTemplate_en"
                    type="button"
                    v-if="selectedTemplate.content_en"
                    data-id="selectedTemplate_en"
                    class="btn btn-success"
                    @click="selectTemplateLanguage('en')">
                    EN
                  </button>
                </div>
              </div>
            </div>

            <div class="form-group">
              <div class="col-sm-12">
                <Editor ref="emailEditor" v-model="email_body" placeholder="Inhoud e-mail" />
              </div>
            </div>

            <div class="form-group" v-if="attachments.length > 0">
              <label class="col-sm-3 control-label">Bijlagen:</label>
              <div class="col-sm-9">
                <ul class="unstyled" style="margin-top: 8px; padding-left: 0px;">
                  <Attachment
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :attachment="attachment"
                    @remove="removeAttachment"
                  />
                </ul>
              </div>
            </div>
            <div v-if="recipientsWithoutEmailAddress.length" class="tw-font-bold tw-text-error">Volgende contacten hebben geen e-mailadres en zullen geen e-mail ontvangen:
              <ul class="tw-list-disc tw-ml-4">
                <li v-for="(recipient, index) in recipientsWithoutEmailAddress" :key="index">
                  {{ recipient.recipient.display_name }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </div>

    <span slot="footer">
      <button
        :title="buttonTooltip"
        :disabled="!enableSend || sendingEmail"
        class="btn btn-primary"
        @click="openFeedbackModal"
      >
        Verzenden
      </button>
      <a @click="hide" class="btn btn-white">Sluiten</a>
    </span>

    <ActivityFeedbackModal
      ref="feedbackModal"
      :multiMode="true"
      :showFinishButton="true"
      @finished="sendEmail"
    />
  </Modal>
</template>

<script>
import Vue from 'vue'

import EventBus from '@/components/iam/bus'
import Modal from '@/components/iam/Modal'
import Editor from '@/components/iam/Editor'
import HelpToolTip from '@/components/iam/HelpToolTip'

import MailRecipient from '@/components/iam/mailclient/MailRecipient'
import PropertyItem from '@/components/iam/mailclient/PropertyItem'
import ProjectItem from '@/components/iam/mailclient/ProjectItem'
import Attachment from '@/components/iam/mailclient/Attachment'

import ActivityFeedbackModal from '@/components/sales/ActivityFeedbackModal'

import { successModal, errorModal, warningModal } from '@/modalMessages'
import { mapGetters } from 'vuex'
import { checkEmailAttachments, sendEmail, getEmailTemplates } from '@/services/apiService'
import { getContactGroups } from '@/services/contacts'

export default {
  name: 'MailClient',
  components: {
    HelpToolTip,
    ActivityFeedbackModal,
    MailRecipient,
    PropertyItem,
    ProjectItem,
    Attachment,
    Modal,
    Editor
  },
  data () {
    return {
      templates: [],
      recipients: [],
      properties: [],
      propertySelected: false,
      projects: [],
      subject: '',
      selectedTemplate: '',
      email_body: '',
      language: 'nl',
      attachments: [],
      previousFocus: null,
      callback: null,
      selectedGroupId: null,
      communication: '',
      leadVariables: {},
      createLeadsForGroup: false,
      groups: [],
      confirmationForActivity: null,
      leadStatus: '0',
      invoiceId: null,
      sendingEmail: false
    }
  },
  created () {
    this.init()
  },
  watch: {
    is_internal (value) {
      this.$refs.feedbackModal.toggleFeedbackReporting(value)
    },
    selectedTemplate (value) {
      if (value) this.selectTemplateLanguage('nl')
    },
    properties (value) {
      if (value.length === 0) {
        this.propertySelected = false
        this.createLeadsForGroup = false
      }
    },
    selectedGroupId (value) {
      if (!value) this.createLeadsForGroup = false
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    candidateRecipients () {
      return this.recipients.filter((r) => r.type === 'candidate')
    },
    toRecipients () {
      return this.recipients.filter((r) => r.type === 'to')
    },
    ccRecipients () {
      return this.recipients.filter((r) => r.type === 'cc')
    },
    bccRecipients () {
      return this.recipients.filter((r) => r.type === 'bcc')
    },
    recipientsWithoutEmailAddress () {
      return this.recipients.filter(recipient => recipient?.recipient?.email === '')
    },
    enableSend () {
      let validRecipient = this.recipients.length
      validRecipient = validRecipient || this.selectedGroupId != null
      return this.subject !== '' && this.email_body !== '' && validRecipient
    },
    buttonTooltip () {
      if (!this.enableSend) {
        return 'Je moet een contact, onderwerp en bericht invullen om een e-mail te kunnen versturen.'
      } else {
        return ''
      }
    },
    showGroupLeadsCheckbox () {
      return this.selectedGroupId && this.properties.length && this.propertySelected
    },
    shouldOpenFeedbackModal () {
      if (this.confirmationForActivity) return false
      return (this.projects.length || this.properties.length) && this.recipients.filter(recipient => recipient.type === 'candidate').length
    },
    selectableTemplates () {
      return this.templates.filter(template => template.user_can_select)
    }
  },
  methods: {
    async init () {
      try {
        EventBus.$on('mailclient-prefill-and-show', this.prefillHandler)
        EventBus.$on('mailclient-close', this.hide)
      } catch (error) {
        console.error(error)
      }
    },
    async loadTemplates () {
      if (this.templates.length) return
      const response = await getEmailTemplates()
      this.templates = response.data?.results
      return response
    },
    async show () {
      await Promise.all([this.loadTemplates(), this.loadGroups()])
      this.$refs.modal.show()
    },
    async loadGroups () {
      if (this.groups.length) return
      const response = await getContactGroups({ params: { page_size: 1000 } })
      const groups = response?.data?.results.map(group => {
        return { value: group.id, name: group.name }
      })
      this.groups = groups
    },
    hide () {
      this.clearModal()
      this.$refs.modal.hide()
      EventBus.$emit('mailclient-hidden')
    },
    clearModal () {
      this.clearEmailFields()
    },
    clearGroup () {
      this.selectedGroupId = null
    },
    addAttachment (file) {
      const fileExists = this.attachments.find(attachment => attachment.id === file.id)
      if (!fileExists) this.attachments.push(file)
    },
    removeAttachment (file) {
      const fileExists = this.attachments.find(attachment => attachment.id === file.id)
      if (fileExists) this.attachments.splice(this.attachments.indexOf(file), 1)
    },

    /** Recipients
       * type
       *  - candidate
       *  - to
       *  - cc
       *  - bcc
       **/
    addRecipientForm (type = 'candidate') {
      const c = { cid: 'id-' + Math.random().toString(36).substr(2, 16), type: type }
      this.recipients.push(c)
      return c
    },
    removeRecipient (recipient) {
      if (this.recipients.indexOf(recipient) !== -1) this.recipients.splice(this.recipients.indexOf(recipient), 1)
    },
    /**
       * Called when a recipient is added by a component on the mailclient
       * If currentRecipient is null we create a new recpient structure, else we update the current blank one
       */
    selectRecipient (currentRecipient, newRecipient) {
      if (currentRecipient) {
        const idx = this.recipients.indexOf(currentRecipient)
        Vue.set(this.recipients[idx], 'recipient', newRecipient)
      } else {
        const c = this.addRecipientForm()
        this.selectRecipient(c, newRecipient)
      }
      this.leadVariables.firstname = this.recipients[0]?.firstname
      this.$forceUpdate()
    },
    /**
       * Called when an other recipient is added by a component on the mailclient
       * If currentRecipient is null we create a new recpient structure, else we update the current blank one
       */
    selectTo (currentRecipient, newRecipient) {
      if (currentRecipient) {
        const idx = this.recipients.indexOf(currentRecipient)
        this.recipients[idx].recipient = newRecipient
      } else {
        const c = this.addToForm()
        this.selectRecipient(c, newRecipient)
      }
    },

    /** Other contacts **/
    addToForm (type = 'to') {
      const c = { cid: 'id-' + Math.random().toString(36).substr(2, 16), type: type }
      this.recipients.push(c)
      return c
    },

    /** Properties **/
    addPropertyForm () {
      this.properties.push({ cid: 'id-' + Math.random().toString(36).substr(2, 16) })
    },
    removeProperty (property) {
      this.properties.splice(this.properties.indexOf(property), 1)
      this.attachments = this.attachments.filter(attachment => attachment.property_id !== property?.property?.id)
    },
    selectProperty (currentProperty, newProperty) {
      const propertyExists = this.properties.find(property => property.property?.id === newProperty.id)
      const idx = this.properties.indexOf(currentProperty)

      if (propertyExists) {
        this.properties.splice(idx, 1)
        return warningModal('Pand reeds geselecteerd')
      }

      this.properties[idx].property = newProperty
      this.propertySelected = true

      this.$set(this.leadVariables, 'property_address', this.properties[0]?.property?.address || '...')
    },

    /** Projects **/
    addProjectForm () {
      this.projects.push({ cid: 'id-' + Math.random().toString(36).substr(2, 16) })
    },
    removeProject (project) {
      this.projects.splice(this.projects.indexOf(project), 1)
      this.attachments = this.attachments.filter(attachment => attachment.project_id !== project?.project?.id)
    },
    selectProject (currentProject, newProject) {
      const projectExists = this.projects.find(project => project.project?.id === newProject.id)
      const idx = this.projects.indexOf(currentProject)

      if (projectExists) {
        this.projects.splice(idx, 1)
        return warningModal('Project reeds geselecteerd')
      }

      this.projects[idx].project = newProject
    },

    addToEmailBody (html) {
      this.$refs.emailEditor.addToBody(html)
    },

    _processTemplate (template, variables) {
      let content = template
      if (variables) {
        for (var key in variables) {
          const re = new RegExp('{{' + key + '}}', 'g')
          content = content?.replace(re, variables[key])
        }
      }
      return content
    },

    selectTemplateLanguage (language, variables = this.leadVariables) {
      const subject = this.selectedTemplate[`subject_${language}`]
      if (subject) this.subject = subject
      const content = this._processTemplate(this.selectedTemplate[`content_${language}`], variables)
      this.email_body = content
      this.$refs.emailEditor.setBody(content)
      this.language = language
    },
    clearEmailFields () {
      this.recipients = []
      this.bcc = ''
      this.bcc_active = false
      this.properties = []
      this.projects = []
      this.subject = ''
      this.selectedTemplate = ''
      this.email_body = ''
      this.language = 'nl'
      this.attachments = []
      this.$refs.emailEditor.clear()
      this.callback = null
      this.selectedGroupId = null
      this.$refs.feedbackModal.clearForm()
      this.communication = ''
      this.sendingEmail = false
    },

    async openFeedbackModal () {
      try {
        if (this.attachments.length) {
          await checkEmailAttachments({ attachments: this.attachments })
        }
        if (this.shouldOpenFeedbackModal) {
          // set internal feedback to the email message by default
          this.$refs.feedbackModal.feedbackInternal = this.email_body?.replace(/<[^>]+>/g, '')
          this.$refs.feedbackModal.show()
          // Mail is then finally sent via the `finished` event emitted by the feedbackModal
        } else {
          this.communication = this.email_body?.replace(/<[^>]+>/g, '')
          await this.sendEmail()
        }
      } catch (error) {
        console.error(error)
        const errorCode = error.response?.data?.code
        if (errorCode === 'MAX_ATTACHMENT_SIZE_EXCEEDED') {
          errorModal(`Er kan maximaal ${error?.response?.data?.message} aan bestanden in één keer via e-mail worden doorgestuurd. De mail werd niet verstuurd.`)
        } else if (errorCode === 'ATTACHMENT_DOES_NOT_EXIST') {
          errorModal(`Het volgende bestand kan niet worden gedownload om via e-mail te verzenden: <strong>${error.response?.data?.message}</strong>. Gelieve het bestand opnieuw te uploaden of de helpdesk te contacteren voor meer informatie.`)
        } else {
          errorModal('Er ging iets mis bij het versturen van de mail. Gelieve het nogmaals te proberen.')
        }
      }
    },
    initializeCallback (context) {
      if (this.callback) {
        if (typeof this.callback === 'object') {
          if (this.callback.arguments) {
            this.callback.arguments.splice(0, 0, context)
            this.callback.callback.apply([], this.callback.arguments)
          } else {
            this.callback.callback(context)
          }
        } else {
          this.callback(context)
        }
      }
    },

    prepareEmail () {
      const recipient_ids = this.candidateRecipients.filter(rp => rp.recipient).map(rp => rp.recipient.id)
      const to_ids = this.toRecipients.filter(rp => rp.recipient.id).map(rp => rp.recipient.id)
      const cc_ids = this.ccRecipients.filter(rp => rp.recipient.id).map(rp => rp.recipient.id)
      const bcc_ids = this.bccRecipients.filter(rp => rp.recipient.id).map(rp => rp.recipient.id)

      const property_ids = this.properties.map(property => property.property.id)
      const project_ids = this.projects.map(project => project.project.id)

      const data = {
        to_ids,
        cc_ids,
        bcc_ids,
        recipient_ids,
        property_ids,
        project_ids,
        group_id: this.selectedGroupId,
        subject: this.subject,
        email_body: this.email_body,
        template_id: this.selectedTemplate?.id,
        language: this.language,
        attachments: this.attachments,
        feedback_internal: this.$refs.feedbackModal.feedbackInternal,
        feedback_reporting: this.$refs.feedbackModal.feedbackReporting,
        communication: this.communication,
        create_leads_for_group: this.createLeadsForGroup,
        confirmation_for_activity: this.confirmationForActivity,
        lead_status: this.leadStatus,
        invoice: this.invoiceId
      }

      const context = {
        subject: this.subject,
        email_body: this.email_body,
        language: this.language,
        feedback_internal: data.feedback_internal,
        feedback_reporting: data.feedback_reporting
      }

      this.$refs.feedbackModal.clearForm()
      return { data, context }
    },
    async sendEmail () {
      const { data, context } = this.prepareEmail()
      let response
      try {
        this.sendingEmail = true
        response = await sendEmail(data)
        successModal('De e-mail wordt verstuurd...')
        return response
      } catch (error) {
        console.error(error)
        errorModal('Helaas ging er iets mis bij het versturen van de mail. Gelieve het nogmaals te proberen.')
      } finally {
        this.initializeCallback(context)
        this.clearEmailFields()
      }
    },
    async prefillHandler (data) {
      await Promise.all([this.loadTemplates(), this.loadGroups()])
      if (data.contacts) {
        data.contacts.forEach((contact) => {
          if (contact.type === 'group') {
            this.selectedGroupId = contact.groupId
          } else {
            const c = this.addRecipientForm(contact.type)
            this.selectRecipient(c, contact.contact)
          }
        })
      }

      if (data.context?.properties) {
        for (const property of data.context.properties) {
          this.properties.push({
            cid: 'id-' + Math.random().toString(36).substr(2, 16),
            property
          })
        }
      }
      if (data.context?.project) {
        this.projects.push({
          cid: 'id-' + Math.random().toString(36).substr(2, 16),
          project: data.context.project
        })
      }
      if (data.context?.confirmatonForActivity) {
        this.confirmationForActivity = data.context?.confirmationForActivity
      }
      if (data.context?.invoiceId) {
        this.invoiceId = data.context?.invoiceId
      }
      if (data.subject) {
        this.subject = data.subject
      }
      if (data.template) {
        if (data.template.id) {
          this.selectedTemplate = this.templates.find(template => template.id === data.template.id)
        } else {
          this.selectedTemplate = this.templates.find(template => template.key === data.template.key)
        }
        if (!this.selectedTemplate) {
          return console.error('Could not load template with id ' + data.template?.id || data.template?.key)
        }
        // To wait for all DOM updates to resolve and the modal to be ready
        setTimeout(() => {
          const variables = data.template.variables
          this.selectTemplateLanguage(data.template.language, variables)
          if (variables) this.leadVariables = variables
        }, 500)
      }
      if (data.callback) {
        // Format:
        // { callback: <function>, arguments:<Array of arguments> }
        // arguments are optional
        // example: { callback: test, arguments: ['some text',]
        this.callback = data.callback
      }
      if (data.body) {
        this.$refs.emailEditor.setBody(data.body)
      }
      if (data.attachments) {
        this.attachments = data.attachments
      }

      this.$refs.modal.show()
    }
  }
}
</script>
