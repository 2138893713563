import Vue from 'vue'

function install (Vue, pluginOptions = { identifier: 'constants' }) {
  Vue.mixin({
    beforeCreate () {
      const { identifier } = pluginOptions
      const constants = this.$options[identifier]
      if (!constants) return

      // If computed is not defined, set it to an empty object.
      this.$options.computed = this.$options.computed || {}
      Object.keys(constants).forEach(key => {
        this.$options.computed[key] = {
          get: () => constants[key],
          set: () => console.error(`READ-ONLY: Changing the constant ${key} is not allowed.`)
        }
      })
    }
  })
}

export function installConstantsPlugin () {
  Vue.use({ install })
}
