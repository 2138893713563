<template>
  <div
    :data-type="context.type"
    :class="`formulate-input-element formulate-input-element--${context.type}`"
  >
    <Multiselect
      v-model="context.model"
      :options="tags"
      :loading="searching"
      :taggable="taggable"
      :multiple="true"
      :searchable="true"
      :internal-search="false"
      v-bind="context.attributes"
      label="name"
      select-label=""
      selected-label=""
      deselect-label="Verwijderen"
      @search-change="searchTags"
      @tag="context.rootEmit('tag', $event)"
      @select="context.rootEmit('select', $event)"
    >
      <template v-if="!showTagsOnClose" #selection="{ values, isOpen }">
        <span v-if="values.length && !isOpen">
          Zoek naar een tag
        </span>
      </template>
      <template #tag="{ option, remove }">
        <span
          aria-label="selection"
          :style="`
            background: ${option.color};
            color: ${getTagTextColor(option.color)};
          `"
          class="tag"
        >
          {{ option.name }}
          <button v-if="allowRemove" type="button" @click="remove(option)">
            <i class="far fa-times" />
          </button>
        </span>
      </template>
      <template #option="{ option, search }">
        <span
          v-if="option.name"
          aria-label="option"
          :style="`
            background: ${option.color};
            color: ${getTagTextColor(option.color)};
          `"
          class="tag"
        >
          {{ option.name }}
        </span>
        <span v-else-if="taggable" class="tw-text-darken-success">
          <i class="far fa-plus tw-mr-1" />
          Tag maken voor '{{ search }}'
        </span>
      </template>
      <template #noResult>Geen tag gevonden</template>
      <template #noOptions>Geen tag gevonden</template>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { getTagTextColor } from '@/utils/helpers'
import { getContactTags } from '@/services/contacts'

export default {
  name: 'BaseInputTagSelect',
  components: {
    Multiselect
  },
  props: {
    context: {
      type: Object,
      required: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    allowRemove: {
      type: Boolean,
      default: true
    },
    showTagsOnClose: {
      type: Boolean,
      default: true
    },
    excludeAutomatedTags: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searching: false,
      tags: []
    }
  },
  methods: {
    getTagTextColor,
    async searchTags (query = '') {
      this.searching = true
      const params = { query: query }
      if (this.excludeAutomatedTags) params.allow_manual_assignment = 1
      const response = await getContactTags({ params })
      this.tags = response.data?.results || []
      this.searching = false
      return response
    }
  }
}
</script>

<style scoped>
@import '~@/styles/new/tag-select.css';
</style>
