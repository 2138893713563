<template>
  <div class="tw-inline-block tw-relative">
    <button
      ref="triggerButton"
      type="button"
      class="btn btn-success"
      @click="toggleDropdown"
    >
      <span class="tw-relative">
        <i :class="['fas', showDropdown ? 'fa-times' : 'fa-bell', { 'fa-spinner-third fa-spin': loading }]" />
        <span
          v-if="notifications.count > 0"
          class="tw-absolute tw-py-0.5 tw-w-5 tw-text-center tw-rounded-full tw-bg-red-600 tw-text-xs tw--top-5 tw--right-5"
        >
          {{ notifications.count }}
        </span>
      </span>
    </button>
    <div
      v-if="showDropdown"
      ref="dropdown"
      :style="dropdownStyle"
      class="tw-my-2.5 tw-absolute tw-z-30 tw-w-[80vw] tw-max-w-sm tw-shadow-card tw-rounded-md tw-bg-white"
    >
      <div v-if="notifications.count" class="tw-p-4 tw-w-full tw-border-b">
        <h2 class="tw-font-semibold tw-m-0">{{ notifications.count }} {{ getNotificationSingularPlural(notifications.count) }}</h2>
        <div class="tw-text-xs">{{ newNotifications.length }} ongelezen {{ getNotificationSingularPlural(newNotifications.length) }}</div>
      </div>

      <div class="tw-overflow-auto tw-max-h-[70vh]">
        <transition-group name="fade" mode="out-in">
          <div
            v-for="notification in notifications.results"
            :key="notification.id"
            class="tw-py-2.5 tw-px-4 tw-border-b tw-flex tw-flex-row tw-justify-between tw-gap-2 tw-relative"
          >
            <router-link
              :to="getRouterLinkDetails(notification)"
              target="_blank"
              class="tw-text-tg-color hover:tw-text-success !tw-no-underline"
            >
              <span :class="[{ 'tw-hidden': notification.read_on }, 'tw-flex tw-w-2 tw-h-2 tw-absolute tw-top-1 tw-left-1']">
                <span class="tw-absolute tw-animate-ping tw-h-full tw-w-full tw-bg-red-600 tw-bg-opacity-75 tw-rounded-full" />
                <span class="tw-h-1.5 tw-w-1.5 tw-rounded-full tw-bg-red-600 tw-m-auto" />
              </span>
              <div>
                <h4 class="tw-m-0">{{ notification.type_display }}</h4>
                <!-- The HTML being rendered is coming from a trusted source (back-end) -->
                <span v-html="notification.description" />
              </div>
            </router-link>
            <div class="tw-my-auto">
              <button
                type="button"
                :title="notification.is_archivable
                  ? 'Archiveren'
                  : 'Deze notificatie kan niet manueel gearchiveerd worden'
                "
                :disabled="!notification.is_archivable"
                class="action tw-bg-success tw-rounded-full tw-m-0"
                @click="
                  notification.type_key === 'survey_completed'
                    ? showSurveyFeedbackForm(notification)
                    : archiveHandler(notification.id)
                "
              >
                <i class="fas fa-check" />
              </button>
            </div>
          </div>
          <InfiniteLoading :identifier="identifier" key="infiniteLoader" spinner="waveDots" @infinite="infiniteLoad">
            <div slot="no-more"><!-- Empty div to not render anything --></div>
            <template #no-results>Geen notificaties</template>
            <template #error="{ trigger }">
              Fout bij laden van notificaties,
              <button type="button" class="link tw-font-semibold" @click.prevent="trigger">
                probeer het opnieuw.
              </button>
            </template>
          </InfiniteLoading>
        </transition-group>
      </div>
    </div>

    <BaseModal ref="surveyFeedback" title="Ingevulde klantenbevraging">
      <FormulateForm
        #default="{ isLoading }"
        name="surveyFeedbackForm"
        @submit="submitFeedback"
      >
        <h4 class="tw-my-0">Type bevraging</h4>
        <p v-if="surveyDetails.type">{{ surveyDetails.type.name }}</p>

        <h4 class="tw-my-0">Contact</h4>
        <p v-if="surveyDetails.contact">
          <router-link
            :to="{ name: 'ContactDetails', params: { id: surveyDetails.contact.id } }" target="_blank"
          >
            {{ surveyDetails.contact.display_name }}
          </router-link>
        </p>

        <h4 class="tw-my-0">Pand</h4>
        <p v-if="surveyDetails.property">
          <router-link :to="{ name: 'PropertyDetails', params: { id: surveyDetails.property.id } }" target="_blank">
            {{ surveyDetails.property.reference}} ({{ surveyDetails.property.street }} {{surveyDetails.property.number }}{{ surveyDetails.property.box ? ` ${surveyDetails.property.box}` : '' }}, {{ surveyDetails.property.city.zip_code }} {{ surveyDetails.property.city.name_nl }})
          </router-link>
        </p>

        <template v-if="surveyDetails.activity">
          <h4 class="tw-my-0">Activiteit</h4>
          <p>
            <router-link :to="{ name: 'LeadDetails', params: { id: surveyDetails.activity.match_id } }" target="_blank">
               {{ surveyDetails.activity.type }} op {{ formatDateNlBe(surveyDetails.activity.start_date, 'dd-MM-yyyy') }}
            </router-link>
          </p>
        </template>

        <h4 class="tw-my-0">Reactie van contact</h4>
        <p>
          {{ surveyDetails.comment }}
        </p>
        <img :src="emojiUrl" height="20" width="20" />

        <FormulateInput
          type="textarea"
          name="comment"
          label="Ondernomen acties door Dewaele"
          placeholder="Ondernomen acties door Dewaele"
        />

        <FormulateErrors class="tw-text-right" />

        <div class="tw-flex tw-justify-end">
          <FormulateInput
            type="submit"
            :disabled="isLoading"
            outer-class="tw-mt-2"
          >
            <i
              :class="[
                'fas tw-mr-1',
                isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
              ]"
            />
            Opslaan
          </FormulateInput>
        </div>
      </FormulateForm>
    </BaseModal>
  </div>
</template>

<script>
import uniqueId from 'lodash/uniqueId'
import InfiniteLoading from 'vue-infinite-loading'

import { errorModal } from '@/modalMessages'
import {
  getNotifications,
  getNotificationCount,
  updateNotification,
  markNotificationsAsRead
} from '@/services/notifications'
import { getSurveyDetails, createSurveyComment } from '@/services/surveys'
import { formatDateNlBe } from '@/utils/helpers'

let fetchCountInterval

export default {
  name: 'NotificationDropdown',
  components: {
    InfiniteLoading
  },
  constants: {
    ESTIMATION_REQUEST_OVERDUE: 1,
    POTENTIAL_POWERSALE: 2,
    MANDATE_EXPIRES_SIX_WEEKS: 3,
    MANDATE_EXPIRES_TWO_WEEKS: 4
  },
  data () {
    return {
      identifier: uniqueId('notifications_'),
      loading: false,
      showDropdown: false,
      notifications: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      surveyDetails: {},
      surveyNotification: {},
      dropdownStyle: {
        left: 'auto',
        right: 'auto'
      }
    }
  },
  computed: {
    newNotifications () {
      return this.notifications.results
        .filter(notification => notification.read_on === null)
        .map(notification => notification.id)
    },
    emojiUrl () {
      return this.surveyDetails?.emoji_url
    }
  },

  created () {
    this.fetchNotificationCount()
    fetchCountInterval = setInterval(() => {
      this.fetchNotificationCount()
    }, 10 * 60 * 1000)
  },

  mounted () {
    document.addEventListener('resize', this.handleResize)
    document.addEventListener('click', this.handleClickOutside)
  },

  beforeDestroy () {
    clearInterval(fetchCountInterval)
  },

  destroyed () {
    document.removeEventListener('resize', this.handleResize)
    document.removeEventListener('click', this.handleClickOutside)
  },
  methods: {
    formatDateNlBe,
    handleClickOutside (evt) {
      if (!this.$el.contains(evt.target)) this.hide()
    },
    toggleDropdown () {
      this.showDropdown ? this.hide() : this.show()
    },
    getNotificationSingularPlural (count) {
      return count === 1 ? 'melding' : 'meldingen'
    },
    getRouterLinkDetails (notification) {
      if (notification.match) return { name: 'LeadDetails', params: { id: notification.match } }
      if (notification.property) return { name: 'PropertyDetails', params: { id: notification.property } }
      if (notification.contact) return { name: 'ContactDetails', params: { id: notification.contact } }
      return {}
    },

    positionDropdown () {
      if (!this.$refs.dropdown || !this.$refs.triggerButton) return

      const viewportWidth = window.innerWidth
      const dropdownRect = this.$refs.dropdown.getBoundingClientRect()
      const triggerRect = this.$refs.triggerButton.getBoundingClientRect()

      // Space on the right is calculated from the left edge of the trigger button, since the notification dropdown starts from the left edge of the trigger
      const spaceOnRight = viewportWidth - triggerRect.left

      // Check if there's enough space on the right side
      if (spaceOnRight >= dropdownRect.width) {
        this.dropdownStyle = { left: '0', right: 'auto' }
      } else {
        // If not enough space on right, position to the left
        this.dropdownStyle = { left: 'auto', right: '0' }
      }
    },

    handleResize () {
      if (this.showDropdown) this.positionDropdown()
    },

    async show () {
      this.loading = true
      const notifications = await this.fetchNotifications()
      this.notifications = notifications
      this.showDropdown = true
      this.$nextTick(() => {
        this.positionDropdown()
      })
      this.loading = false
    },
    async hide () {
      if (!this.showDropdown) return false // Don't trigger API calls when it's already hidden but clicked outside
      this.showDropdown = false
      await this.markNotificationsAsRead()
      this.$set(this.notifications, 'results', [])
    },
    async showSurveyFeedbackForm (notification) {
      this.surveyNotification = notification
      const response = await getSurveyDetails(notification.survey)
      this.surveyDetails = response.data
      this.$refs.surveyFeedback.show()
      return response
    },
    async fetchNotificationCount () {
      const response = await getNotificationCount()
      this.$set(this.notifications, 'count', response.data.count)
      return response.data
    },
    async fetchNotifications (payload) {
      const response = await getNotifications(payload)
      return response.data
    },
    async infiniteLoad ($infinite) {
      try {
        const next = this.notifications.next
        if (!next) {
          // No more data and state is loaded
          if (this.notifications.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const notifications = await this.fetchNotifications({ url: next })
        const results = [...this.notifications.results, ...notifications.results]
        this.notifications = { ...notifications, results }
        $infinite.loaded()
        return notifications
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    },
    async markNotificationsAsRead () {
      const payload = { notification_id_list: this.newNotifications }
      const response = await markNotificationsAsRead(payload)
      return response
    },
    async archiveHandler (id) {
      try {
        const response = await updateNotification(id, {
          archived_on: new Date()
        })
        this.notifications = await this.fetchNotifications()
        this.identifier = uniqueId('refreshed_notifications_')
        return response.data
      } catch {
        errorModal('Kon notificatie niet archiveren')
      }
    },
    async submitFeedback (data) {
      try {
        const response = await createSurveyComment(this.surveyDetails.id, data)
        await this.archiveHandler(this.surveyNotification.id)
        this.$refs.surveyFeedback.hide()
        return response
      } catch (error) {
        this.$formulate.handle(error, 'surveyFeedbackForm')
      }
    }
  }
}
</script>
