 <template>
  <span>
    {{ value.display_name }}
    <span class="label" :class="status">GDPR</span>
    <span class="tw-mx-1 tw-px-1 tw-py-0.5 tw-uppercase tw-bg-success tw-rounded-sm tw-text-[10px] tw-font-semibold tw-inline-block tw-text-white">
      {{ value.language }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'RecipientRenderer',
  props: ['value'],
  computed: {
    status () {
      if (this.value.global_unsubscribe) {
        return 'label-danger'
      } else if (this.value.consent_asked) {
        return 'label-primary'
      } else {
        return 'label-warning'
      }
    }
  }
}
</script>

<style scoped>

</style>
