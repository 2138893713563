<template>
  <div @click="clicked" class="iam-search-result">
    <component v-bind:is="RenderComponent" :value="value"></component>
  </div>
</template>

<script>
export default {
  name: 'SearchResult',
  props: ['value', 'RenderComponent'],
  methods: {
    clicked: function () {
      this.$emit('result-clicked')
    }
  }
}
</script>

<style scoped>

</style>
