<script>
import { contacts } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import ContactRenderer from '@/components/iam/ContactPickerRenderComponent'

export default {
  name: 'ContactPicker',
  mixins: [PickerMixin],
  props: {
    placeholder: {
      default () {
        return 'Selecteer een contact'
      }
    },
    selectionRenderComponent: {
      default () {
        return ContactRenderer
      }
    },
    type: {
      default () {
        return 'contacts'
      }
    },
    canClear: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      source: contacts.ttAdapter(),
      suggestionRenderComponent (value) {
        return `
          <div>
            <strong>${value.display_name}</strong><br>
            <i class="fa fa-phone"></i> ${value.phone || '<em>geen telefoon</em>'} <br>
            <i class="fa fa-mobile"></i> ${value.mobile || '<em>geen gsm</em>'} <br>
            <i class="fa fa-envelope"></i> ${value.email || '<em>geen email</em>'} <br>
          </div>
        `
      }
    }
  }
}
</script>
