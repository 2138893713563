import { httpClient } from './axiosClients'
import { commaSerializer } from './paramSerializers'

export function getSalesActivityTypes () {
  return httpClient.get('/api/sales/activity-types')
}

export function getLeads (payload = {}) {
  const { url = '/api/sales/match', params } = payload
  return httpClient.get(url, { params })
}

export function getLeadIds (payload) {
  return httpClient.get('/api/sales/match/ids', payload)
}

export function getLeadById (leadId) {
  return httpClient.get(`/api/sales/match/${leadId}`)
}

export function updateLead (leadId, payload) {
  return httpClient.patch(`/api/sales/match/${leadId}`, payload)
}

export function getLeadFollowers (leadId) {
  return httpClient.get(`/api/sales/match/${leadId}/followers`)
}

export function removeLeadFollower (leadId, payload) {
  return httpClient.post(`/api/sales/match/${leadId}/followers/remove`, payload)
}

export function deleteLead (leadId) {
  return httpClient.delete(`/api/sales/match/${leadId}`)
}

export function breakLead (leadId, payload) {
  return httpClient.post(`/api/sales/match/${leadId}/break`, payload)
}

export function breakLeads (payload) {
  return httpClient.post('/api/sales/match/break', payload)
}

export function getRelationsForLeadsMailclient (payload = {}) {
  const { url = '/api/sales/match/mailclient', params } = payload
  return httpClient.get(url, { params })
}

export function getActivity (activityId) {
  return httpClient.get(`/api/sales/activities/${activityId}`)
}

export function createActivity (leadId, payload) {
  return httpClient.post(
    `/api/sales/match/${leadId}/activities`, payload
  )
}

export function updateActivity (activityId, payload) {
  return httpClient.patch(
    `/api/sales/activities/${activityId}`, payload
  )
}

export function getLeadActivity (activityId) {
  return httpClient.get(`/api/sales/activities/${activityId}`)
}

export function deleteSalesActivity (activityId) {
  return httpClient.delete(`/api/sales/activities/${activityId}`)
}

export function deleteSalesIncome (incomeId) {
  return httpClient.delete(`/api/sales/income/${incomeId}`)
}

export function setLeadStatus (leadId, payload) {
  return httpClient.post(`/api/sales/match/${leadId}/set-status`, payload)
}

export function exportLeadContacts (payload) {
  return httpClient.post('/api/sales/export-lead-contacts', payload)
}

export function getPropertyVisits (propertyId, date) {
  return httpClient.get(`/api/sales/property/${propertyId}/visits/${date}`)
}

export function getMatchesForIds (params) {
  return httpClient.get('/api/sales/matches-for-ids', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function getLeadFiles (leadId) {
  return httpClient.get(`/api/sales/matches/${leadId}/files`)
}

export function markDeal (leadId) {
  return httpClient.post(`/api/sales/match/${leadId}/deal`)
}

export function updateRentioLead (leadId) {
  return httpClient.post(`/api/sales/match/${leadId}/rentio`)
}

export function getBreakReasons () {
  return httpClient.get('/api/match-break-reasons')
}

export function getSmsData (params) {
  return httpClient.get('/api/sales/sms-data', {
    params,
    paramsSerializer: commaSerializer
  })
}

export function sendBulkSms (payload) {
  return httpClient.post('/api/sales/send-bulk-sms', payload)
}

export function isFirstActivityOfType (leadId, params) {
  return httpClient.get(`/api/sales/matches/${leadId}/is-first-activity`, { params })
}

export function transferLeads (payload) {
  return httpClient.post('/api/sales/match-transfer', payload)
}

export function createHmodhPropertyLeads (payload) {
  return httpClient.post('/api/sales/hmodh-property-matches', payload)
}

export function triggerHmodhCreation (leadId) {
  return httpClient.post(`/api/sales/match/${leadId}/hmodh-creation`)
}

export function getCancellationReasons () {
  return httpClient.get('/api/activity-cancellation-reasons')
}

export function cancelActivity (activityId, payload) {
  return httpClient.post(`/api/sales/activities/${activityId}/cancel`, payload)
}

export function sendOpenLeadsNotification (payload) {
  return httpClient.post('/api/sales/open-leads-notification', payload)
}

export function assignLead (leadId, payload) {
  return httpClient.post(`/api/sales/match/${leadId}/assign`, payload)
}
