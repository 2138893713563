export default [
  {
    code: '+31',
    country: 'Netherlands'
  },
  {
    code: '+32',
    country: 'Belgium'
  },
  {
    code: '+33',
    country: 'France'
  },
  {
    code: '+34',
    country: 'Spain'
  },
  {
    code: '+39',
    country: 'Italy'
  },
  {
    code: '+44',
    country: 'United Kingdom'
  },
  {
    code: '+49',
    country: 'Germany'
  },
  {
    code: '+352',
    country: 'Luxembourg'
  },
  {
    country: '===========',
    disabled: true
  },
  {
    code: '+1',
    country: 'American Samoa'
  },
  {
    code: '+1',
    country: 'Anguilla'
  },
  {
    code: '+1',
    country: 'Antigua & Barbuda'
  },
  {
    code: '+1',
    country: 'Bahamas'
  },
  {
    code: '+1',
    country: 'Barbados'
  },
  {
    code: '+1',
    country: 'Bermuda'
  },
  {
    code: '+1',
    country: 'British Virgin Islands'
  },
  {
    code: '+1',
    country: 'Canada'
  },
  {
    code: '+1',
    country: 'Cayman Islands'
  },
  {
    code: '+1',
    country: 'Dominica'
  },
  {
    code: '+1',
    country: 'Dominican Republic'
  },
  {
    code: '+1',
    country: 'Grenada'
  },
  {
    code: '+1',
    country: 'Guam'
  },
  {
    code: '+1',
    country: 'Jamaica'
  },
  {
    code: '+1',
    country: 'Montserrat'
  },
  {
    code: '+1',
    country: 'Northern Mariana Islands'
  },
  {
    code: '+1',
    country: 'Puerto Rico'
  },
  {
    code: '+1',
    country: 'Sint Maarten'
  },
  {
    code: '+1',
    country: 'St. Kitts & Nevis'
  },
  {
    code: '+1',
    country: 'St. Lucia'
  },
  {
    code: '+1',
    country: 'St. Vincent & Grenadines'
  },
  {
    code: '+1',
    country: 'Trinidad & Tobago'
  },
  {
    code: '+1',
    country: 'Turks & Caicos Islands'
  },
  {
    code: '+1',
    country: 'U.S. Virgin Islands'
  },
  {
    code: '+1',
    country: 'US'
  },
  {
    code: '+7',
    country: 'Kazakhstan'
  },
  {
    code: '+7',
    country: 'Russia'
  },
  {
    code: '+20',
    country: 'Egypt'
  },
  {
    code: '+27',
    country: 'South Africa'
  },
  {
    code: '+30',
    country: 'Greece'
  },
  {
    code: '+36',
    country: 'Hungary'
  },
  {
    code: '+39',
    country: 'Vatican City'
  },
  {
    code: '+40',
    country: 'Romania'
  },
  {
    code: '+41',
    country: 'Switzerland'
  },
  {
    code: '+43',
    country: 'Austria'
  },
  {
    code: '+44',
    country: 'Guernsey'
  },
  {
    code: '+44',
    country: 'Isle of Man'
  },
  {
    code: '+44',
    country: 'Jersey'
  },
  {
    code: '+45',
    country: 'Denmark'
  },
  {
    code: '+46',
    country: 'Sweden'
  },
  {
    code: '+47',
    country: 'Bouvet Island'
  },
  {
    code: '+47',
    country: 'Norway'
  },
  {
    code: '+47',
    country: 'Svalbard & Jan Mayen'
  },
  {
    code: '+48',
    country: 'Poland'
  },
  {
    code: '+51',
    country: 'Peru'
  },
  {
    code: '+52',
    country: 'Mexico'
  },
  {
    code: '+53',
    country: 'Cuba'
  },
  {
    code: '+54',
    country: 'Argentina'
  },
  {
    code: '+55',
    country: 'Brazil'
  },
  {
    code: '+56',
    country: 'Chile'
  },
  {
    code: '+57',
    country: 'Colombia'
  },
  {
    code: '+58',
    country: 'Venezuela'
  },
  {
    code: '+60',
    country: 'Malaysia'
  },
  {
    code: '+61',
    country: 'Australia'
  },
  {
    code: '+61',
    country: 'Christmas Island'
  },
  {
    code: '+61',
    country: 'Cocos (Keeling) Islands'
  },
  {
    code: '+62',
    country: 'Indonesia'
  },
  {
    code: '+63',
    country: 'Philippines'
  },
  {
    code: '+64',
    country: 'New Zealand'
  },
  {
    code: '+65',
    country: 'Singapore'
  },
  {
    code: '+66',
    country: 'Thailand'
  },
  {
    code: '+81',
    country: 'Japan'
  },
  {
    code: '+82',
    country: 'South Korea'
  },
  {
    code: '+84',
    country: 'Vietnam'
  },
  {
    code: '+86',
    country: 'China'
  },
  {
    code: '+90',
    country: 'Turkey'
  },
  {
    code: '+91',
    country: 'India'
  },
  {
    code: '+92',
    country: 'Pakistan'
  },
  {
    code: '+93',
    country: 'Afghanistan'
  },
  {
    code: '+94',
    country: 'Sri Lanka'
  },
  {
    code: '+95',
    country: 'Myanmar'
  },
  {
    code: '+98',
    country: 'Iran'
  },
  {
    code: '+211',
    country: 'South Sudan'
  },
  {
    code: '+212',
    country: 'Morocco'
  },
  {
    code: '+212',
    country: 'Western Sahara'
  },
  {
    code: '+213',
    country: 'Algeria'
  },
  {
    code: '+216',
    country: 'Tunisia'
  },
  {
    code: '+218',
    country: 'Libya'
  },
  {
    code: '+220',
    country: 'Gambia'
  },
  {
    code: '+221',
    country: 'Senegal'
  },
  {
    code: '+222',
    country: 'Mauritania'
  },
  {
    code: '+223',
    country: 'Mali'
  },
  {
    code: '+224',
    country: 'Guinea'
  },
  {
    code: '+225',
    country: "Côte d'Ivoire"
  },
  {
    code: '+226',
    country: 'Burkina Faso'
  },
  {
    code: '+227',
    country: 'Niger'
  },
  {
    code: '+228',
    country: 'Togo'
  },
  {
    code: '+229',
    country: 'Benin'
  },
  {
    code: '+230',
    country: 'Mauritius'
  },
  {
    code: '+231',
    country: 'Liberia'
  },
  {
    code: '+232',
    country: 'Sierra Leone'
  },
  {
    code: '+233',
    country: 'Ghana'
  },
  {
    code: '+234',
    country: 'Nigeria'
  },
  {
    code: '+235',
    country: 'Chad'
  },
  {
    code: '+236',
    country: 'Central African Republic'
  },
  {
    code: '+237',
    country: 'Cameroon'
  },
  {
    code: '+238',
    country: 'Cape Verde'
  },
  {
    code: '+239',
    country: 'Sao Tome and Principe'
  },
  {
    code: '+240',
    country: 'Equatorial Guinea'
  },
  {
    code: '+241',
    country: 'Gabon'
  },
  {
    code: '+242',
    country: 'Congo - Brazzaville'
  },
  {
    code: '+243',
    country: 'Congo - Kinshasa'
  },
  {
    code: '+244',
    country: 'Angola'
  },
  {
    code: '+245',
    country: 'Guinea-Bissau'
  },
  {
    code: '+246',
    country: 'British Indian Ocean Territory'
  },
  {
    code: '+248',
    country: 'Seychelles'
  },
  {
    code: '+249',
    country: 'Sudan'
  },
  {
    code: '+250',
    country: 'Rwanda'
  },
  {
    code: '+251',
    country: 'Ethiopia'
  },
  {
    code: '+252',
    country: 'Somalia'
  },
  {
    code: '+253',
    country: 'Djibouti'
  },
  {
    code: '+254',
    country: 'Kenya'
  },
  {
    code: '+255',
    country: 'Tanzania'
  },
  {
    code: '+256',
    country: 'Uganda'
  },
  {
    code: '+257',
    country: 'Burundi'
  },
  {
    code: '+258',
    country: 'Mozambique'
  },
  {
    code: '+260',
    country: 'Zambia'
  },
  {
    code: '+261',
    country: 'Madagascar'
  },
  {
    code: '+262',
    country: 'French Southern Territories'
  },
  {
    code: '+262',
    country: 'Mayotte'
  },
  {
    code: '+262',
    country: 'Réunion'
  },
  {
    code: '+263',
    country: 'Zimbabwe'
  },
  {
    code: '+264',
    country: 'Namibia'
  },
  {
    code: '+265',
    country: 'Malawi'
  },
  {
    code: '+266',
    country: 'Lesotho'
  },
  {
    code: '+267',
    country: 'Botswana'
  },
  {
    code: '+268',
    country: 'Swaziland'
  },
  {
    code: '+269',
    country: 'Comoros'
  },
  {
    code: '+290 n',
    country: 'St. Helena'
  },
  {
    code: '+291',
    country: 'Eritrea'
  },
  {
    code: '+297',
    country: 'Aruba'
  },
  {
    code: '+298',
    country: 'Faroe Islands'
  },
  {
    code: '+299',
    country: 'Greenland'
  },
  {
    code: '+350',
    country: 'Gibraltar'
  },
  {
    code: '+351',
    country: 'Portugal'
  },
  {
    code: '+353',
    country: 'Ireland'
  },
  {
    code: '+354',
    country: 'Iceland'
  },
  {
    code: '+355',
    country: 'Albania'
  },
  {
    code: '+356',
    country: 'Malta'
  },
  {
    code: '+357',
    country: 'Cyprus'
  },
  {
    code: '+358',
    country: 'Finland'
  },
  {
    code: '+358',
    country: 'Ãland Islands'
  },
  {
    code: '+359',
    country: 'Bulgaria'
  },
  {
    code: '+370',
    country: 'Lithuania'
  },
  {
    code: '+371',
    country: 'Latvia'
  },
  {
    code: '+372',
    country: 'Estonia'
  },
  {
    code: '+373',
    country: 'Moldova'
  },
  {
    code: '+374',
    country: 'Armenia'
  },
  {
    code: '+375',
    country: 'Belarus'
  },
  {
    code: '+376',
    country: 'Andorra'
  },
  {
    code: '+377',
    country: 'Monaco'
  },
  {
    code: '+378',
    country: 'San Marino'
  },
  {
    code: '+380',
    country: 'Ukraine'
  },
  {
    code: '+381',
    country: 'Serbia'
  },
  {
    code: '+382',
    country: 'Montenegro'
  },
  {
    code: '+385',
    country: 'Croatia'
  },
  {
    code: '+386',
    country: 'Slovenia'
  },
  {
    code: '+387',
    country: 'Bosnia'
  },
  {
    code: '+389',
    country: 'Macedonia'
  },
  {
    code: '+420',
    country: 'Czech Republic'
  },
  {
    code: '+421',
    country: 'Slovakia'
  },
  {
    code: '+423',
    country: 'Liechtenstein'
  },
  {
    code: '+500',
    country: 'Falkland Islands'
  },
  {
    code: '+500',
    country: 'South Georgia & South Sandwich Islands'
  },
  {
    code: '+501',
    country: 'Belize'
  },
  {
    code: '+502',
    country: 'Guatemala'
  },
  {
    code: '+503',
    country: 'El Salvador'
  },
  {
    code: '+504',
    country: 'Honduras'
  },
  {
    code: '+505',
    country: 'Nicaragua'
  },
  {
    code: '+506',
    country: 'Costa Rica'
  },
  {
    code: '+507',
    country: 'Panama'
  },
  {
    code: '+508',
    country: 'St. Pierre & Miquelon'
  },
  {
    code: '+509',
    country: 'Haiti'
  },
  {
    code: '+590',
    country: 'Guadeloupe'
  },
  {
    code: '+590',
    country: 'Saint Barthélemy'
  },
  {
    code: '+590',
    country: 'St. Martin'
  },
  {
    code: '+591',
    country: 'Bolivia'
  },
  {
    code: '+592',
    country: 'Guyana'
  },
  {
    code: '+593',
    country: 'Ecuador'
  },
  {
    code: '+594',
    country: 'French Guiana'
  },
  {
    code: '+595',
    country: 'Paraguay'
  },
  {
    code: '+596',
    country: 'Martinique'
  },
  {
    code: '+597',
    country: 'Suriname'
  },
  {
    code: '+598',
    country: 'Uruguay'
  },
  {
    code: '+599',
    country: 'Caribbean Netherlands'
  },
  {
    code: '+599',
    country: 'Curaçao'
  },
  {
    code: '+670',
    country: 'Timor-Leste'
  },
  {
    code: '+672',
    country: 'Antarctica'
  },
  {
    code: '+672',
    country: 'Heard & McDonald Islands'
  },
  {
    code: '+672',
    country: 'Norfolk Island'
  },
  {
    code: '+673',
    country: 'Brunei'
  },
  {
    code: '+674',
    country: 'Nauru'
  },
  {
    code: '+675',
    country: 'Papua New Guinea'
  },
  {
    code: '+676',
    country: 'Tonga'
  },
  {
    code: '+677',
    country: 'Solomon Islands'
  },
  {
    code: '+678',
    country: 'Vanuatu'
  },
  {
    code: '+679',
    country: 'Fiji'
  },
  {
    code: '+680',
    country: 'Palau'
  },
  {
    code: '+681',
    country: 'Wallis & Futuna'
  },
  {
    code: '+682',
    country: 'Cook Islands'
  },
  {
    code: '+683',
    country: 'Niue'
  },
  {
    code: '+685',
    country: 'Samoa'
  },
  {
    code: '+686',
    country: 'Kiribati'
  },
  {
    code: '+687',
    country: 'New Caledonia'
  },
  {
    code: '+688',
    country: 'Tuvalu'
  },
  {
    code: '+689',
    country: 'French Polynesia'
  },
  {
    code: '+690',
    country: 'Tokelau'
  },
  {
    code: '+691',
    country: 'Micronesia'
  },
  {
    code: '+692',
    country: 'Marshall Islands'
  },
  {
    code: '+850',
    country: 'North Korea'
  },
  {
    code: '+852',
    country: 'Hong Kong'
  },
  {
    code: '+853',
    country: 'Macau'
  },
  {
    code: '+855',
    country: 'Cambodia'
  },
  {
    code: '+856',
    country: 'Laos'
  },
  {
    code: '+870',
    country: 'Pitcairn Islands'
  },
  {
    code: '+880',
    country: 'Bangladesh'
  },
  {
    code: '+886',
    country: 'Taiwan'
  },
  {
    code: '+960',
    country: 'Maldives'
  },
  {
    code: '+961',
    country: 'Lebanon'
  },
  {
    code: '+962',
    country: 'Jordan'
  },
  {
    code: '+963',
    country: 'Syria'
  },
  {
    code: '+964',
    country: 'Iraq'
  },
  {
    code: '+965',
    country: 'Kuwait'
  },
  {
    code: '+966',
    country: 'Saudi Arabia'
  },
  {
    code: '+967',
    country: 'Yemen'
  },
  {
    code: '+968',
    country: 'Oman'
  },
  {
    code: '+970',
    country: 'Palestine'
  },
  {
    code: '+971',
    country: 'United Arab Emirates'
  },
  {
    code: '+972',
    country: 'Israel'
  },
  {
    code: '+973',
    country: 'Bahrain'
  },
  {
    code: '+974',
    country: 'Qatar'
  },
  {
    code: '+975',
    country: 'Bhutan'
  },
  {
    code: '+976',
    country: 'Mongolia'
  },
  {
    code: '+977',
    country: 'Nepal'
  },
  {
    code: '+992',
    country: 'Tajikistan'
  },
  {
    code: '+993',
    country: 'Turkmenistan'
  },
  {
    code: '+994',
    country: 'Azerbaijan'
  },
  {
    code: '+995',
    country: 'Georgia'
  },
  {
    code: '+996',
    country: 'Kyrgyzstan'
  },
  {
    code: '+998',
    country: 'Uzbekistan'
  }
]
