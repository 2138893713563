<!--
This components allows the user to enter internal and external feedback after completing an activity. The component
does the hard work of registering the feedback and setting the activity details. Events are emitted after the
actions are performed.

Properties
  title         Title of the modal, defaults to 'Activiteit afpunten'
  lead          lead
  activity      activity to finish
  size          Size of the modal, defaults to 'lg'. See bootstrap modal sizes
  showCheckbox  Display checkbox 'Activiteit afwerken op datum van afpunten'
  showDetailsButton   Display a button 'Details' that navigates to the details of the lead

Methods
  show()        Show the modal
  hide()        Hide the modal
  clearForm()   Clear the form

Events
  onFinish
  onBreak
  activityFinished    Emmited when the activity is finished, passes the activity along with it
-->
<template>
  <Modal :size="size" :title="title" ref="modal">
    <div class="row text-left" slot="header" v-if="lead && activity">
      <div class="col-sm-4"><h3>Pand</h3>
        <p v-if="activity">
          <strong>{{ activity.type.name }}</strong> {{ activity.description }}<br>
        </p>
        <p v-else><strong>Lead:</strong> {{ lead.description }}</p>
        <p v-if="address"><strong>Adres:</strong> {{ address }}</p>
        <p v-if="activity"><strong>Ingepland:</strong> {{ activity.start_date | datetime}}</p></div>

      <div class="col-sm-4" v-if="lead.owner"><h3>Eigenaar</h3>
        <p><strong>{{ lead.owner.display_name }}</strong></p>

        <a v-if="lead.owner.phone" :href="`tel:${lead.owner.phone}`">
          <i class="fa fa-phone" /> {{ lead.owner.phone }}
        </a>
        <br />

        <a v-if="lead.owner.mobile" :href="`tel:${lead.owner.mobile}`">
          <i class="fa fa-mobile" /> {{ lead.owner.mobile }}
        </a>
        <br />

        <span v-if="lead.owner.email"><i class="fa fa-email"></i> {{ lead.owner.email }}</span></div>

      <div class="col-sm-4" v-if="lead.candidate"><h3>Kandidaat</h3>
        <p><strong>{{ lead.candidate.display_name }}</strong></p>

        <a v-if="lead.candidate.phone" :href="`tel:${lead.candidate.phone}`">
          <i class="fa fa-phone" /> {{ lead.candidate.phone }}
        </a>
        <br />

        <a v-if="lead.candidate.mobile" :href="`tel:${lead.candidate.mobile}`">
          <i class="fa fa-mobile" /> {{ lead.candidate.mobile }}
        </a>
        <br />

        <span v-if="lead.candidate.email"><i class="fa fa-email"></i> {{ lead.candidate.email }}</span></div>
    </div>

    <div slot="footer">
      <span>
        <a @click="showDetails" class="btn btn-default" target="_blank" type="button"
           v-if="showDetailsButton && !multiMode">Details</a>
        <a @click="onFinish" class="btn btn-success" type="button" v-if="showFinishButton">Afpunten</a>
        <a @click="onBreak" class="btn btn-danger" type="button" v-if="showBreakButton">Break</a>
        <a @click="onBreakToDewaeleDIY" class="btn btn-danger" type="button"
           v-if="showBreakButton && showDewaeleDIYBreakButton">Break naar Dewaele DIY</a>
      </span>

    </div>

    <div class="row" v-if="activity || multiMode">
      <div class="col-sm-12">
        <div class="form-group">
          <label class="col-sm-2 control-label">Feedback<br>rapportering</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="feedbackReporting" rows="5" v-model="feedbackReporting"></textarea>
          </div>
        </div>
        <div class="form-group">
          <label class="col-sm-2 control-label">Interne<br>feedback</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="feedbackInternal" rows="5" v-model="feedbackInternal"></textarea>
          </div>
        </div>
        <div v-if="isOutgoingPhoneCall" class="form-group col-sm-10 tw-mt-2">
          <input v-model="voicemail" type="checkbox" id="voicemail" />
          <label
            for="voicemail"
            class="tw-ml-1"
          >
            Voicemail
          </label>
        </div>
        <div v-if="showBreakReasonSelect" class="form-group col-sm-10 tw-mt-2">
          <label class="tw-block">Reden voor het breaken van de intakelead (verplicht)</label>

          <select name="reasons" id="reasons" v-model="selectedBreakReason">
            <option value="" class="tw-text-center">--- Selecteer een reden ---</option>
            <option
              v-for="reason in breakReasons"
              :key="reason.id"
              :value="reason.id"
              class="tw-pl-2"
            >
              &nbsp;{{ reason.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import $ from 'jquery'
import moment from 'moment'

import EventBus from '@/components/iam/bus'
import Modal from '@/components/iam/Modal'

import { assignLead, getBreakReasons, updateActivity } from '@/services/sales'
import { breakActivity } from '@/utils/sales'
import { startLoadingModal, stopLoadingModal, successModal, errorModal } from '@/modalMessages'
import { createLog } from '@/services/apiService'

export default {
  name: 'ActivityFeedbackModal',
  components: {
    Modal
  },
  props: {
    title: {
      default: 'Activiteit afpunten'
    },
    lead: {
      default: null
    },
    entity: {
      default: null
    },
    activity: {
      default: null
    },
    size: {
      default: 'lg'
    },
    showCheckbox: {
      default: true
    },
    showFinishButton: {
      default: true
    },
    showDetailsButton: {
      default: true
    },
    showBreakButton: {
      default: false
    },
    showDealButton: {
      default: true
    },
    showDewaeleDIYBreakButton: {
      default: true
    },
    multiMode: {
      default: false
    },
    handleSuccessModal: {
      default: true
    }
  },
  data () {
    return {
      feedbackReporting: '',
      feedbackInternal: '',
      description: '',
      buttonsCreated: false,
      dateRangePickerCreated: false,
      customCallback: null,
      breakReasons: [],
      selectedBreakReason: '',
      voicemail: false
    }
  },
  computed: {
    address () {
      if (this.entity) {
        return `${this.entity.street} ${this.entity.number} ${this.entity.box}`
      }
      return ''
    },
    showBreakReasonSelect () {
      return this.lead?.stage?.funnel === 1
    },
    isOutgoingPhoneCall () {
      return this.activity?.type?.id === 126
    }
  },
  watch: {
    buttonsCreated () {
      this.setActivityType()
    },
    dateRangePickerCreated () {
      this.setDates()
    }
  },
  methods: {
    async show () {
      this.buttonsCreated = false
      this.dateRangePickerCreated = false
      if (this.showBreakReasonSelect) {
        const response = await getBreakReasons()
        this.breakReasons = response?.data?.results
      }
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },
    setActivityType () {
      this.$refs.activityType.selectActivity(this.activity.type.id)
    },
    onButtonsCreated () {
      this.buttonsCreated = true
    },
    setDates () {
      const startDate = this.activity.start_date ? moment(this.activity.start_date).format('DD-MM-YYYY HH:mm') : null
      const endDate = this.activity.end_date ? moment(this.activity.end_date).format('DD-MM-YYYY HH:mm') : null
      this.$refs.dateRangePicker.setStartDate(startDate)
      this.$refs.dateRangePicker.setEndDate(endDate)
    },
    onDateRangePickerCreated () {
      this.dateRangePickerCreated = true
    },
    toggleFeedbackReporting (value) {
      $(this.$refs.feedbackReporting).prop('disabled', value)
      if (value) {
        this.feedbackReporting = ''
      }
    },
    clearForm () {
      this.feedbackReporting = ''
      this.feedbackInternal = ''
    },
    showDetails () {
      this.$emit('gotoLead', this.lead)
    },
    newActivity () {
      this.$emit('new-activity')
    },

    /* Contains all payload property for all activity operations */
    listActivityPayload () {
      const activityId = this.activity ? this.activity.id : null
      const leadId = this.lead ? this.lead.id : null
      return {
        feedbackInternal: this.feedbackInternal,
        feedbackReporting: this.feedbackReporting,
        activityId,
        leadId
      }
    },
    /* When an activity is finished two options are possible:
      1) Multimode = true: We don't have an activity, because we have to wait for the emailclient to create an activity.
                           We hold off the finishing of the activity until the activity is created.
      2) Multimode = false: We can finish the activity immediatly */
    async finishCurrentActivity (toDewaeleDIY = false) {
      if (this.multiMode) {
        this.hide()
        const emitPayload = {
          toDewaeleDIY,
          activity: this.activity
        }
        if (this.showBreakReasonSelect) {
          emitPayload.breakReason = this.selectedBreakReason
        }
        this.$emit('finished', emitPayload)
        return Promise.resolve(null)
      } else {
        const payload = {
          finished: true,
          status: this.voicemail ? 3 : 4, // 3 = aborted, 4 = finished
          feedback: this.feedbackInternal,
          feedback_reporting: this.feedbackReporting
        }
        const response = await updateActivity(this.activity.id, payload)
        return response
      }
    },

    async onFinish () {
      try {
        startLoadingModal('Activiteit afpunten...')
        const response = await this.finishCurrentActivity()
        this.$emit('activity-finished', this.activity, this.lead)
        EventBus.$emit('sales-activity-finished', this.lead, this.activity, this.entity)
        this.clearForm()

        if (!this.customCallback) {
          return successModal(
            'Deze activiteit is afgepunt en de feedback is geregistreerd',
            true, // Show toastr
            { timeOut: 500, positionClass: 'toast-bottom-right' } // options override
          )
        }
        stopLoadingModal()
        this.customCallback()
        this.customCallback = null
        return response
      } catch (error) {
        errorModal('Helaas ging er iets mis bij het afpunten van deze activiteit. Gelieve nogmaals te proberen, als het probleem zich blijft voordoen wil je dit dan melden via de helpdesk?')
      }
    },
    checkBreakReason () {
      if (!this.showBreakReasonSelect) return true
      return !!this.selectedBreakReason
    },
    async onBreak () {
      try {
        if (!this.checkBreakReason()) {
          return errorModal('Reden voor het breaken van de lead is verplicht')
        }
        if (!this.activity) {
          this.hide()
          const emitPayload = {
            toDewaeleDIY: false
          }
          if (this.showBreakReasonSelect) {
            emitPayload.breakReason = this.selectedBreakReason
          }
          this.$emit('finished', emitPayload) // The receiving component handles the general lead break
        } else {
          startLoadingModal()
          const payload = this.listActivityPayload()
          if (this.selectedBreakReason) {
            payload.break_reason = this.selectedBreakReason
          }
          if (this.voicemail) {
            payload.status = 3 // Aborted
          }
          const [updateResponse, breakResponse] = await breakActivity(payload)
          const activityData = updateResponse.data
          this.clearForm()
          this.$set(this.lead, 'status', 2) // Set lead object to status break before emitting it
          EventBus.$emit('sales-activity-break', this.lead, activityData)
          this.$emit('activity-finished', activityData, this.lead)
          stopLoadingModal()
          return [breakResponse, updateResponse]
        }
        if (this.handleSuccessModal) {
          successModal(
            'Breaken van lead geslaagd',
            true, // Show toastr
            { timeOut: 500, positionClass: 'toast-bottom-right' } // options override
          )
        }
      } catch (error) {
        console.error(error)
        errorModal('Helaas ging er iets mis bij het afpunten van deze activiteit. Gelieve nogmaals te proberen, als het probleem zich blijft voordoen wil je dit dan melden via de helpdesk?')
      }
    },

    async onBreakToDewaeleDIY () {
      try {
        const activity = await this.finishCurrentActivity(true)
        this.clearForm()
        // "575" is the "id" of "Louise Souvage", she is the person responsible for Dewaele DIY
        // Leads are assigned to her if they are "Breaked" to Dewaele DIY
        const assignResponse = await assignLead(this.lead.id, { collaborator: 575 })
        const logPayload = {
          action_id: 41, // "Lead break to DIY" log ID
          content_object_id: this.lead.id,
          content_object_class: 'match'
        }
        const logResponse = await createLog(logPayload)
        successModal(
          'Breaken van lead is geslaagd, de feedback is geregistreerd en de lead is overgeplaatst naar Dewaele DIY',
          true, // Show toastr
          { timeOut: 500, positionClass: 'toast-bottom-right' } // options override
        )
        this.hide()

        const DEWAELE_DIY = true
        this.$emit('activity-finished', activity, assignLead, DEWAELE_DIY)
        return [assignResponse, logResponse]
      } catch (error) {
        errorModal('Helaas ging er iets mis bij het afpunten van deze activiteit. Gelieve nogmaals te proberen, als het probleem zich blijft voordoen wil je dit dan melden via de helpdesk?')
      }
    }
  }
}
</script>
