import BaseApi from './api'

class PropertyApi extends BaseApi {
  constructor () {
    super({
      url: '/api/property',
      timeout: 2 * 60 * 1000,
      name: 'iam-properties-property'
    })
  }
}

class ProjectApi extends BaseApi {
  constructor () {
    super({
      url: '/api/project',
      timeout: 2 * 60 * 1000,
      name: 'iam-properties-project'
    })
  }
}

export { PropertyApi, ProjectApi }
