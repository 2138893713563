<template>
  <div class="tw-relative tw-z-[100]">
    <div class="tw-fixed tw-flex tw-flex-col tw-gap-2 tw-items-end tw-bottom-14 tw-right-6">
      <FormulateForm
        v-show="showChat"
        v-model="values"
        name="mia"
        class="tw-p-4 tw-bg-white tw-max-w-md tw-shadow-card tw-rounded-xl tw-overflow-hidden"
        @submit="chat"
      >
        <div
          ref="messages"
          class="tw-min-h-100 tw-max-h-130 tw-flex tw-flex-col tw-items-end tw-gap-4 tw-overflow-scroll"
        >
          <div
            v-for="(message, index) in messages"
            :key="index"
            :class="[
              'tw-px-2 tw-prose tw-max-w-[90%]',
              message.role === 'user'
                ? 'tw-rounded-bl-xl tw-rounded-tl-xl tw-rounded-tr-lg tw-ml-auto tw-bg-success tw-text-white'
                : 'tw-rounded-br-xl tw-rounded-tr-xl tw-rounded-tl-lg tw-mr-auto tw-bg-gray-e9'
            ]"
          >
            <VueMarkdown :html="true" :source="message.content" />
          </div>
          <div
            v-if="loading"
            class="tw-px-2 tw-rounded-br-xl tw-rounded-tr-xl tw-rounded-tl-lg tw-mr-auto tw-bg-gray-e9"
          >
            <i class="fas fa-ellipsis-h" />
          </div>
        </div>
        <div class="tw-mt-4 tw-sticky tw-bottom-0 tw-bg-white tw-flex tw-flex-row tw-gap-2">
          <FormulateInput
            type="text"
            name="message"
            placeholder="Typ een bericht"
            :input-class="['tw-mt-0 tw-text-sm']"
            outer-class="tw-m-0 tw-flex-grow"
          />
          <FormulateInput
            type="submit"
            title="Versturen"
            :input-class="['tw-px-4 hover:tw-bg-darken-success']"
            outer-class="tw-m-0"
          >
            <i class="fas fa-paper-plane" />
          </FormulateInput>
        </div>
      </FormulateForm>
      <button
        type="button"
        class="tw-h-12 tw-w-12 tw-text-xl tw-text-white tw-rounded-full tw-shadow-card tw-bg-success hover:tw-bg-darken-success"
        @click="toggleChat"
      >
        <i
          :class="[
            'far',
            showChat ? 'fa-times' : 'fa-comments'
          ]"
        />
      </button>
    </div>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import { getChatCompletions } from '@/services/mia'

export default {
  name: 'Mia',
  components: {
    VueMarkdown
  },
  data () {
    return {
      loading: false,
      showChat: false,
      values: {},
      messages: [
        {
          role: 'assistant',
          content:
            'Hallo, ik ben Mia. Jouw digitale assistent van Dewaele Vastgoed. Ik kan je helpen je vragen rond vastgoed, legal topics en IAM te beantwoorden. Hoe kan ik je vandaag helpen?'
        }
      ]
    }
  },
  methods: {
    toggleChat () {
      this.showChat = !this.showChat
    },
    scrollToNewMessage () {
      const el = this.$refs.messages.lastElementChild
      el.scrollIntoView({ behavior: 'smooth' })
    },
    async chat (data) {
      if (!data.message) return
      this.messages.push({
        role: 'user',
        content: data.message
      })
      this.$set(this.values, 'message', '')

      this.loading = true
      this.$nextTick(() => this.scrollToNewMessage()) // To scroll to loading.

      const response = await getChatCompletions(this.messages)
      const stream = response.body
      if (!stream) return

      const reader = stream.getReader()
      const decoder = new TextDecoder()
      let isFirst = true

      const message = {
        role: 'assistant',
        content: ''
      }

      while (true) {
        const { value, done } = await reader.read()
        if (done) break

        const chunkValue = decoder.decode(value)
        if (isFirst) {
          isFirst = false
          this.loading = false
          message.content = chunkValue
          this.messages.push(message)
        } else {
          message.content = message.content + chunkValue
        }
        this.$nextTick(() => this.scrollToNewMessage())
      }
      this.$emit('new-message', response)
      return response
    }
  }
}
</script>
