<template>
  <span>{{ value.reference }} {{ value.street }} {{ value.number }} {{ value.box }}<template v-if="value.city">, {{ value.city.name }}</template></span>
</template>

<script>
export default {
  name: 'ProjectPickerRenderComponent',
  props: ['value']
}
</script>

<style scoped>

</style>
