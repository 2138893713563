export const transactionRoutes = [
  {
    path: '/transactions/:transactionId/approval/:approvalId',
    name: 'TransactionApproval',
    props: true,
    component: () => import(/* webpackChunkName: "TransactionApproval" */ '@/views/transactions/TransactionApproval'),
    meta: {
      navigationGroupName: 'Transactions'
    }
  },
  {
    path: '/transactions/advances',
    name: 'TransactionAdvances',
    component: () => import(/* webpackChunkName: "TransactionAdvances" */ '@/views/transactions/TransactionAdvances'),
    meta: {
      navigationGroupName: 'Transactions'
    }
  },
  {
    path: '/transactions/costs',
    name: 'TransactionListCosts',
    component: () => import(/* webpackChunkName: "TransactionListCosts" */ '@/views/transactions/TransactionListCosts'),
    meta: {
      navigationGroupName: 'Transactions'
    }
  },
  {
    path: '/transactions/invoices',
    name: 'TransactionListInvoices',
    component: () => import(/* webpackChunkName: "TransactionListInvoices" */ '@/views/transactions/TransactionListInvoices'),
    meta: {
      navigationGroupName: 'Transactions'
    }
  }
]
