export const salesRoutes = [
  {
    path: '/sales',
    name: 'Sales',
    component: () => import(/* webpackChunkName: "Funnel" */ '@/views/sales/Funnel'),
    meta: {
      navigationGroupName: 'Sales'
    }
  },
  {
    path: '/sales/lead/:id',
    name: 'LeadDetails',
    component: () => import(/* webpackChunkName: "Lead" */ '@/views/sales/Lead'),
    meta: {
      navigationGroupName: 'Sales'
    }
  }
]
