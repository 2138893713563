import { breakLead, updateActivity } from '@/services/sales'

export async function breakActivity (payload) {
  const updatePayload = {
    finished: true,
    status: payload.status || 4, // 4 = finished
    feedback: payload.feedbackInternal,
    feedback_reporting: payload.feedbackReporting,
    update_finish_date: payload.updateFinishDate || false
  }
  const breakPayload = {}
  if (payload.break_reason) breakPayload.break_reason = payload.break_reason
  const updateResponse = await updateActivity(payload.activityId, updatePayload)
  const breakResponse = await breakLead(payload.leadId, breakPayload)
  return [updateResponse, breakResponse]
}

export function isFutureIntakeOrVisitActivity (activity) {
  if (!activity) return false
  const activityStatusOpen = 1
  const visitActivityTypeId = 2
  const intakeMeetingActivityTypeId = 14
  const typeId = typeof activity.type === 'object' ? activity.type?.id : activity.type
  return activity.status === activityStatusOpen &&
    [visitActivityTypeId, intakeMeetingActivityTypeId].includes(typeId) &&
    new Date(activity.start_date) > new Date()
}
