<template>
  <div>
    <input :disabled="disabled" :placeholder="placeholder" :style="{border: errorBorder}" class="form-control"
           ref="input"
           type="text" v-model="val">
  </div>
</template>

<script>
import 'typeahead.js'
import $ from 'jquery'

export default {
  name: 'SuggestionPicker',
  props: ['source', 'suggestionRenderComponent', 'placeholder', 'type', 'value'],
  data () {
    return {
      errorBorder: '',
      disabled: false,
      val: this.value
    }
  },
  methods: {
    clear () {
      this.val = ''
      $(this.$refs.input).typeahead('val', '')
    },
    setError () {
      this.errorBorder = '1px solid red'
    },
    removeError () {
      this.errorBorder = ''
    },
    disable () {
      this.disabled = true
    },
    enable () {
      this.disabled = false
      $(this.$refs.input).typeahead('val', '')
    }
  },
  mounted () {
    const v = this
    $(this.$refs.input).typeahead({
      minLength: 3,
      highlight: false,
      templates: {}
    }, {
      limit: 14,
      display: (data) => {
        if (v.type === 'cities') {
          return `${data.zip_code} ${data.name}`
        } else if (v.type === 'collaborators') {
          return `${data.voornaam} ${data.naam}`
        } else if (v.type === 'properties') {
          return `${data.reference} ${data.street} ${data.number} ${data.city.name}`
        } else if (v.type === 'projects') {
          return `${data.reference} ${data.street} ${data.number} ${data.city.name}`
        } else if (v.type === 'offices') {
          return `${data.reference}-${data.name}`
        } else {
          return data.display_name
        }
      },
      templates: {
        suggestion: this.suggestionRenderComponent
      },
      source: v.source
    }
    ).on('typeahead:asyncrequest', () => $(v.$refs.input).addClass('loadinggif'))
      .on('typeahead:asynccancel typeahead:asyncreceive', () => $(v.$refs.input).removeClass('loadinggif'))

    $(this.$refs.input).on('typeahead:select', (event, suggestion) => {
      if (suggestion.children && suggestion.children.length > 0) {
        suggestion.children.forEach(child => {
          v.$emit('select', child)
          v.$emit('input', child)
        })
      } else {
        v.$emit('select', suggestion)
        v.$emit('input', suggestion)
      }
    })
    $(this.$refs.input).on('typeahead:autocomplete', (event, suggestion) => v.$emit('select', suggestion))
  }
}
</script>
<style scoped>
</style>
