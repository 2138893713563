/**
 * filters.js
 *
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 *  Arne Lanckriet <arne.lanckriet@dewaele.com> (26-09-2018)
 */

import Vue from 'vue'
import moment from 'moment'

/**
 * Display a value by datetime format 'DD-MM-YYYY HH:mm:ss', ie 15-04-1985 17:23:04
 * @param value Value to filter as a datetime
 */
Vue.filter('datetime', function (value) {
  if (!value) return ''
  return moment(value).format('DD-MM-YYYY HH:mm')
})

/**
 * Display a value by date format 'DD-MM-YYYY', ie 15-04-1985
 * @param value Value to filter as a date
 */
Vue.filter('date-day', function (value) {
  if (!value) return ''
  return moment(value).startOf('day').format('DD-MM-YYYY')
})

/**
 * Display a value by time format 'HH:mm', ie 12:435
 * @param value Value to filter as a date
 */
Vue.filter('time', function (value) {
  if (!value) return ''
  return moment(value).format('HH:mm')
})

Vue.filter('day-hour', function (value) {
  if (!value) return ''
  return moment(value).format('DD-MM-YYYY HH:mm')
})

// Vue filter: Currency
Vue.filter('currency', function (value) {
  const c = parseFloat(value).toFixed(0)
  return c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
})

// Vue filter: Currency
Vue.filter('currency-verbose', function (value) {
  if (value) {
    let c = parseFloat(value).toFixed(0)
    c = c.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    return `€ ${c}`
  }
  return '-'
})

Vue.filter('icon', function (value) {
  return 'fa-' + value
})

Vue.filter('floatformat', function (value, decimalPlaces) {
  if (value) {
    return value.toFixed(decimalPlaces)
  } else {
    return 'n/a'
  }
})

Vue.filter('intcomma', function (value) {
  const numberWithCommas = (x) => {
    var parts = x.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }
  return numberWithCommas(value)
})

Vue.filter('yesno', function (value) {
  if (value) {
    return 'Ja'
  } else {
    return 'Nee'
  }
})

Vue.filter('yes_no_unknown', function (value) {
  if (value === 1) {
    return 'Ja'
  } else if (value === 2) {
    return 'Nee'
  } else {
    return 'Onbekend'
  }
})

Vue.filter('yesnounknownsideview', function (value) {
  if (value === 1) {
    return 'Ja'
  } else if (value === 2) {
    return 'Nee'
  } else if (value === 3) {
    return 'Zijdelings'
  } else {
    return 'Onbekend'
  }
})

Vue.filter('yes_no_not_entered', function (value) {
  if (value === 1) {
    return 'Ja'
  } else if (value === 2) {
    return 'Nee'
  } else {
    return 'Niet ingegeven'
  }
})

Vue.filter('yes_no_request', function (value) {
  if (value === 1) {
    return 'Ja'
  } else if (value === 2) {
    return 'Nee'
  } else if (value === 4) {
    return 'In aanvraag'
  } else {
    return 'Niet ingegeven'
  }
})

Vue.filter('yes_no_both', function (value) {
  const yes_no_both_mapping = {
    1: 'Ja',
    2: 'Nee',
    5: 'Ja/Nee'
  }
  return yes_no_both_mapping[value]
})

Vue.filter('default', function (value, defaultValue) {
  if (!value) {
    return defaultValue
  } else {
    return value
  }
})

Vue.filter('default_if_none', function (value, defaultValue) {
  if (!value) {
    return defaultValue
  } else {
    return value
  }
})

Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
})

Vue.filter('capitalize', function (value) {
  return value[0].toUpperCase() + value.slice(1)
})
