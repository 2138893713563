<script>
import { projects } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import ProjectRenderer from '@/components/iam/ProjectPickerRenderComponent'

export default {
  name: 'ProjectPicker',

  mixins: [PickerMixin],
  props: {
    placeholder: {
      default: function () {
        return 'Selecteer een project'
      }
    },
    selectionRenderComponent: {
      default: function () {
        return ProjectRenderer
      }
    },
    type: {
      default: function () {
        return 'properties'
      }
    }
  },
  data: function () {
    return {
      source: projects.ttAdapter(),
      suggestionRenderComponent: function (value) {
        return `<div><strong>${value.reference} ${value.street} ${value.number}, ${value.city.name}</strong></div>`
      }
    }
  }
}
</script>

<style scoped>

</style>
