<template>
  <div>
    <span v-if="value.type === 'P'"><i class="fa fa-user"></i> <strong>{{ value.first_name }} {{ value.last_name }}</strong><br></span>
    <span v-if="value.type === 'B'"><i class="fa fa-users"></i> <strong>{{ value.company_name }}</strong><br></span>
    <span v-if="value.phone"><i class="fa"></i> {{ value.phone }}<br></span>
    <span v-if="value.mobile"><i class="fa"></i> {{ value.mobile }}<br></span>
    <span v-if="value.email"><i class="fa"></i> {{ value.email }}<br></span>
    <span><i class="fa"></i> {{ value.street }} {{ value.number }} {{ value.box }}<template v-if="value.city">, {{ value.city.zip_code }} {{ value.city.name}}</template></span>
  </div>
</template>

<script>
export default {
  name: 'ContactRenderComponent',
  props: ['value']
}
</script>

<style scoped>
  i.fa {
    width: 15px;
  }
</style>
