const COMPANY_TYPES = [
  { value: 0, label: 'bv' },
  { value: 6, label: 'bvba' },
  { value: 4, label: 'comm.v' },
  { value: 8, label: 'comm.va' },
  { value: 2, label: 'cv' },
  { value: 7, label: 'cvba' },
  { value: 9, label: 'cvoa' },
  { value: 10, label: 'e-bvba' },
  { value: 15, label: 'esv' },
  { value: 14, label: 'lv' },
  { value: 5, label: 'maatschap' },
  { value: 17, label: 'natuurlijk persoon' },
  { value: 1, label: 'nv' },
  { value: 11, label: 's-bvba' },
  { value: 12, label: 'stille vennootschap' },
  { value: 13, label: 'tijdelijke vennootschap' },
  { value: 18, label: 'VME' },
  { value: 3, label: 'vof' },
  { value: 16, label: 'vzw' }
]

export default COMPANY_TYPES
