export const propertyRoutes = [
  {
    path: '/portfolio/groups',
    name: 'PropertyGroups',
    component: () => import(/* webpackChunkName: "PropertyGroups" */ '@/views/portfolio/PropertyGroups'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/publication-search',
    name: 'PublicationSearch',
    component: () => import(/* webpackChunkName: "PublicationSearch" */ '@/views/portfolio/PublicationSearch'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/properties',
    name: 'PropertyList',
    component: () => import(/* webpackChunkName: "PropertyList" */ '@/views/portfolio/PropertyList'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/properties/search',
    name: 'PropertySearch',
    component: () => import(/* webpackChunkName: "PropertySearch" */ '@/views/portfolio/PropertySearch'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/owner-report-detail/:id',
    name: 'OwnerReportDetail',
    component: () => import(/* webpackChunkName: "OwnerReportDetail" */ '@/views/portfolio/OwnerReportDetail'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/checklist',
    name: 'ChecklistOverview',
    component: () => import(/* webpackChunkName: "ChecklistOverview" */ '@/views/portfolio/ChecklistOverview'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/properties/:id', // id is watched for in the App.vue as key for router-view
    component: () => import(/* webpackChunkName: "PropertyLayout" */ '@/layouts/PropertyLayout'),
    children: [
      {
        path: 'general',
        name: 'PropertyDetails',
        component: () => import(/* webpackChunkName: "PropertyGeneral" */ '@/views/portfolio/PropertyGeneral'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'general/edit/:formName',
        name: 'PropertyGeneralEdit',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PortfolioEdit'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyDetails' }
        }
      },
      {
        path: 'general/owners',
        name: 'PropertyOwnersEdit',
        component: () => import(/* webpackChunkName: "EntityContactsForm" */ '@/views/portfolio/EntityContactsForm'),
        meta: {
          entity_type: 'property',
          role: 'owner',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyDetails' }
        }
      },
      {
        path: 'general/buyers',
        name: 'PropertyBuyersEdit',
        component: () => import(/* webpackChunkName: "EntityContactsForm" */ '@/views/portfolio/EntityContactsForm'),
        meta: {
          entity_type: 'property',
          role: 'buyer',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyDetails' }
        }
      },
      {
        path: 'general/renters',
        name: 'PropertyRentersEdit',
        component: () => import(/* webpackChunkName: "EntityContactsForm" */ '@/views/portfolio/EntityContactsForm'),
        meta: {
          entity_type: 'property',
          role: 'renter',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyDetails' }
        }
      },
      {
        path: 'building',
        name: 'PropertyBuilding',
        component: () => import(/* webpackChunkName: "PropertyBuilding" */ '@/views/portfolio/PropertyBuilding'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'building/edit/energy-values',
        name: 'PropertyEnergyValuesEdit',
        component: () => import(/* webpackChunkName: "PropertyEnergyValuesEdit" */ '@/views/portfolio/PropertyEnergyValuesEdit'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyBuilding' }
        }
      },
      {
        path: 'building/edit/legal',
        name: 'PropertyLegalEdit',
        component: () => import(/* webpackChunkName: "PropertyLegalEdit" */ '@/views/portfolio/PortfolioLegalEdit'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyBuilding' }
        }
      },
      {
        path: 'building/edit/energy',
        name: 'PropertyEnergyEdit',
        component: () => import(/* webpackChunkName: "PropertyEnergyEdit" */ '@/views/portfolio/PropertyEnergyEdit'),
        meta: {
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyBuilding' }
        }
      },
      {
        path: 'building/edit/:formName',
        name: 'PropertyBuildingEdit',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PortfolioEdit'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyBuilding' }
        }
      },
      {
        path: 'layout',
        name: 'PropertyRooms',
        component: () => import(/* webpackChunkName: "PropertyRooms" */ '@/views/portfolio/PropertyRooms'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      ...process.env.VUE_APP_ENVIRONMENT === 'prod'
        ? []
        : [
          {
            path: 'rooms',
            name: 'NewPropertyRooms',
            component: () => import(/* webpackChunkName: "NewPropertyRooms" */ '@/views/portfolio/NewPropertyRooms'),
            meta: {
              navigationGroupName: 'Portfolio'
            }
          }
        ],
      {
        path: 'media',
        name: 'PropertyMedia',
        component: () => import(/* webpackChunkName: "MediaPanel" */ '@/views/portfolio/MediaPanel'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'files',
        name: 'PropertyFiles',
        component: () => import(/* webpackChunkName: "PropertyFiles" */ '@/views/portfolio/PropertyFiles'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'marketing',
        name: 'PropertyMarketing',
        component: () => import(/* webpackChunkName: "MarketingPanel" */ '@/views/portfolio/MarketingPanel'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'texts',
        name: 'PropertyTexts',
        component: () => import(/* webpackChunkName: "EntityTexts" */ '@/views/portfolio/EntityTexts'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'communication',
        name: 'PropertyCommunication',
        component: () => import(/* webpackChunkName: "EntityCommunication" */ '@/views/portfolio/EntityCommunication'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'leads',
        name: 'PropertyLeads',
        component: () => import(/* webpackChunkName: "LeadPanel" */ '@/views/portfolio/LeadPanel'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'checklists',
        name: 'PropertyChecklist',
        component: () => import(/* webpackChunkName: "EntityChecklist" */ '@/views/portfolio/EntityChecklist'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'slots',
        name: 'PropertyVisitSlots',
        component: () => import(/* webpackChunkName: "PropertyVisitSlots" */ '@/views/portfolio/PropertyVisitSlots'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'owner-report',
        name: 'PropertyOwnerReportForm',
        component: () => import(/* webpackChunkName: "EntityOwnerReportForm" */ '@/views/portfolio/EntityOwnerReportForm'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'owner-report-old',
        name: 'PropertyOwnerReportFormOld',
        component: () => import(/* webpackChunkName: "EntityOwnerReportFormOld" */ '@/views/portfolio/EntityOwnerReportFormOld'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'print',
        name: 'PropertyPrint',
        component: () => import(/* webpackChunkName: "EntityPrint" */ '@/views/portfolio/EntityPrint'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'history',
        name: 'PropertyHistory',
        component: () => import(/* webpackChunkName: "PropertyHistory" */ '@/views/portfolio/PropertyHistory'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'financial',
        name: 'PropertyFinancial',
        component: () => import(/* webpackChunkName: "PropertyFinancial" */ '@/views/portfolio/PropertyFinancial'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'financial/calculator',
        name: 'PropertyFinancialCalculator',
        component: () => import(/* webpackChunkName: "PropertyFinancialCalculator" */ '@/views/portfolio/PropertyFinancialCalculator'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'financial/edit/purchase-costs',
        name: 'PropertyFinancialEditPurchaseCosts',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PropertyFinancialEditPurchaseCosts'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'financial/edit/:formName',
        name: 'PropertyFinancialEdit',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PortfolioEdit'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'PropertyFinancial' }
        }
      },
      {
        path: 'invoice',
        name: 'PropertyInvoice',
        component: () => import(/* webpackChunkName: "PortfolioInvoice" */ '@/views/portfolio/PortfolioInvoice'),
        meta: {
          entity_type: 'property',
          navigationGroupName: 'Portfolio'
        }
      },
      // Redirects, should stay at the bottom.
      {
        path: '', // This is added to route from /portfolio/properties/:id to '/portfolio/properties/:id/general
        redirect: { name: 'PropertyDetails' }
      },
      {
        path: '*', // For catching all non-existent routes
        redirect: { name: 'PropertyDetails' }
      }
    ]
  }
]
