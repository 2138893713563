import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import { budgetRoutes } from './modules/budgetRoutes'
import { commissionRoutes } from './modules/commissionRoutes'
import { contactsRoutes } from './modules/contactsRoutes'
import { marketingRoutes } from './modules/marketingRoutes'
import { organizationRoutes } from './modules/organizationRoutes'
import { profileRoutes } from './modules/profileRoutes'
import { projectRoutes } from './modules/projectRoutes'
import { propertyRoutes } from './modules/propertyRoutes'
import { salesRoutes } from './modules/salesRoutes'
import { toolsRoutes } from './modules/toolsRoutes'
import { transactionRoutes } from './modules/transactionRoutes'

Vue.use(VueRouter)

const routes = [
  ...budgetRoutes,
  ...commissionRoutes,
  ...contactsRoutes,
  ...marketingRoutes,
  ...organizationRoutes,
  ...profileRoutes,
  ...projectRoutes,
  ...propertyRoutes,
  ...salesRoutes,
  ...toolsRoutes,
  ...transactionRoutes,
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard')
  },
  {
    path: '/planner',
    name: 'Planner',
    component: () => import(/* webpackChunkName: "Planner" */ '@/views/Planner')
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: () => import(/* webpackChunkName: "AccessDenied" */ '@/views/AccessDenied')
  },
  // Redirect any other routes to Sales view
  { path: '/*', redirect: { name: 'Sales' } }
]

const router = new VueRouter({
  routes
})

router.beforeEach(async (to, from, next) => {
  if (!store.state.account.user) await store.dispatch('account/loadUser')
  const collaborator = store.getters['account/collaborator']

  if (collaborator) return to.name === 'AccessDenied' ? next({ name: 'Sales' }) : next()

  // If the page is already navigating to AccessDenied, don't push it to the same route again.
  return to.name === 'AccessDenied' ? next() : next({ name: 'AccessDenied' })
})

export default router
