export const projectRoutes = [
  {
    path: '/portfolio/projects/search',
    name: 'ProjectSearch',
    component: () => import(/* webpackChunkName: "ProjectSearch" */ '@/views/portfolio/ProjectSearch'),
    meta: {
      navigationGroupName: 'Portfolio'
    }
  },
  {
    path: '/portfolio/projects/:id', // id is watched for in the App.vue as key for router-view
    component: () => import(/* webpackChunkName: "ProjectLayout" */ '@/layouts/ProjectLayout'),
    children: [
      {
        path: 'general',
        name: 'ProjectDetails',
        component: () => import(/* webpackChunkName: "ProjectGeneral" */ '@/views/portfolio/ProjectGeneral'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'general/edit/:formName',
        name: 'ProjectGeneralEdit',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PortfolioEdit'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'ProjectDetails' }
        }
      },
      {
        path: 'general/owners',
        name: 'ProjectOwnersEdit',
        component: () => import(/* webpackChunkName: "EntityContactsForm" */ '@/views/portfolio/EntityContactsForm'),
        meta: {
          entity_type: 'project',
          role: 'owner',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'ProjectDetails' }
        }
      },
      {
        path: 'info',
        name: 'ProjectInfo',
        component: () => import(/* webpackChunkName: "ProjectInfo" */ '@/views/portfolio/ProjectInfo'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'info/edit/legal',
        name: 'ProjectLegalEdit',
        component: () => import(/* webpackChunkName: "ProjectLegalEdit" */ '@/views/portfolio/PortfolioLegalEdit'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'ProjectInfo' }
        }
      },
      {
        path: 'info/edit/:formName',
        name: 'ProjectInfoEdit',
        component: () => import(/* webpackChunkName: "PortfolioEdit" */ '@/views/portfolio/PortfolioEdit'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio',
          backRoute: { name: 'ProjectInfo' }
        }
      },
      {
        path: 'entities',
        name: 'ProjectEntities',
        component: () => import(/* webpackChunkName: "ProjectEntities" */ '@/views/portfolio/ProjectEntities'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'entities/import',
        name: 'ProjectEntitiesImport',
        component: () => import(/* webpackChunkName: "ProjectEntities" */ '@/views/portfolio/ProjectEntitiesImport'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'media',
        name: 'ProjectMedia',
        component: () => import(/* webpackChunkName: "MediaPanel" */ '@/views/portfolio/MediaPanel'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'files',
        name: 'ProjectFiles',
        component: () => import(/* webpackChunkName: "ProjectFiles" */ '@/views/portfolio/ProjectFiles'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'marketing',
        name: 'ProjectMarketing',
        component: () => import(/* webpackChunkName: "MarketingPanel" */ '@/views/portfolio/MarketingPanel'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'texts',
        name: 'ProjectTexts',
        component: () => import(/* webpackChunkName: "EntityTexts" */ '@/views/portfolio/EntityTexts'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'communication',
        name: 'ProjectCommunication',
        component: () => import(/* webpackChunkName: "EntityCommunication" */ '@/views/portfolio/EntityCommunication'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'leads',
        name: 'ProjectLeads',
        component: () => import(/* webpackChunkName: "LeadPanel" */ '@/views/portfolio/LeadPanel'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'checklists',
        name: 'ProjectChecklist',
        component: () => import(/* webpackChunkName: "EntityChecklist" */ '@/views/portfolio/EntityChecklist'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'owner-report',
        name: 'ProjectOwnerReportForm',
        component: () => import(/* webpackChunkName: "EntityOwnerReportForm" */ '@/views/portfolio/EntityOwnerReportForm'),
        meta: {
          entity_type: 'project',
          navigationGroupName: 'Portfolio'
        }
      },
      {
        path: 'owner-report-old',
        name: 'ProjectOwnerReportFormOld',
        component: () => import(/* webpackChunkName: "EntityOwnerReportFormOld" */ '@/views/portfolio/EntityOwnerReportFormOld'),
        meta: {
          navigationGroupName: 'Portfolio'
        }
      },

      // Redirects, should stay at the bottom.
      {
        path: '', // This is added to route from /portfolio/properties/:id to '/portfolio/properties/:id/general
        redirect: { name: 'ProjectDetails' }
      },
      {
        path: '*', // For catching all non-existent routes
        redirect: { name: 'ProjectDetails' }
      }
    ]
  }
]
