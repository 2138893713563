/**
 * sales.js
 * Sales store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */
import $ from 'jquery'
import Vue from 'vue'

// Initial state
const state = {
  leads: {},
  leadsForProperty: {},
  leadsForProject: {},
  renterProfiles: {},
  candidateIncomes: {},
  activitiesForLead: {},
  showPictures: JSON.parse(localStorage.getItem('iam-sales-funnel-show-pictures') || 'true'),
  followerLeads: localStorage.getItem('iam-sales-funnel-follower-leads') || 'M',
  filterCollaborator: null,
  filterOffice: null
}

// Getters
const getters = {
  getLeadsForProject: (state) => (id) => {
    return state.leadsForProject[parseInt(id)]
  },
  getLeadsForProperty: (state) => (id) => {
    return state.leadsForProperty[parseInt(id)]
  },

  getRenterProfileForLead: (state) => (id) => {
    if (id in state.renterProfiles) {
      return state.renterProfiles[id]
    }
    return null
  },
  getCandidateIncomes: (state) => (payload) => {
    if (payload.id in state.candidateIncomes) {
      const incomes = state.candidateIncomes[payload.id]
      if (payload.phase) {
        return incomes.filter((income) => income.phase === payload.phase)
      } else {
        return incomes
      }
    }
    return null
  },

  getActivitiesForLeadById: (state) => (id) => {
    if (id) {
      if (id in state.activitiesForLead) {
        return state.activitiesForLead[id]
      }
    }
    return null
  },

  getFunnelFilters: state => {
    return {
      collaborator: state.filterCollaborator,
      office: state.filterOffice
    }
  }
}

// Actions
const actions = {
  loadActivitiesForLead ({ commit }, id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/sales/match/' + id + '/activities'
      }).done((activities) => {
        commit('setActivitiesForLead', { lead: id, activities: activities.results })
        resolve(activities.results)
      }).fail((error) => {
        reject(error)
      })
    })
  },
  shareLead ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/api/sales/match/' + payload.lead.id + '/share',
        data: {
          collaborator: payload.collaborator.id
        }
      }).done((lead) => {
        commit('setLead', lead)
        resolve()
      }).fail((error) => {
        reject(error)
      })
    })
  },
  /**
    * Create a new lead
    * @param commit
    * @param payload Object that takes the form
    *  {
    *    candidates: [id, id, id of contacts],
    *    property: propertyId,
    *  }
    * @returns {Promise<any>}
    */
  createLead ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        data: {
          agent: payload.agent,
          description: payload.description,
          spontanuous: payload.spontanuous,
          candidates: payload.candidates,
          property: payload.property,
          project: payload.project
        },
        url: '/api/sales/match',
        traditional: true
      }).done(lead => {
        commit('setLead', lead)
        resolve(lead)
      }).fail((error) => {
        reject(error)
      })
    })
  },
  checkExistingLead (context, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        data: payload,
        url: '/api/sales/match/existence'
      })
        .done(data => {
          if (data.lead_exists) {
            resolve(data.match)
          } else {
            resolve(null)
          }
        })
        .fail(error => {
          reject(error)
        })
    })
  },
  loadRenterProfileForLead ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/sales/match/' + payload.id + '/renterprofile'
      }).done(data => {
        if (data) {
          commit('setRenterProfile', data)
          resolve(data)
        }
      }).fail(error => {
        reject(error)
      })
    })
  },
  saveCandidate ({ dispatch }, payload) {
    $.ajax({
      type: 'POST',
      url: '/api/sales/candidates',
      data: {
        match: payload.match,
        relation: payload.contact,
        role: payload.role
      }
    }).done(() => {
      dispatch('loadRenterProfileForLead', { id: payload.match })
    })
  },
  removeCandidate ({ dispatch }, payload) {
    $.ajax({
      type: 'DELETE',
      url: '/api/sales/candidates/' + payload.id
    }).done(() => {
      dispatch('loadRenterProfileForLead', { id: payload.match })
    })
  },
  saveIncome ({ dispatch }, payload) {
    $.ajax({
      type: 'POST',
      url: '/api/sales/income',
      data: {
        candidate: payload.candidate,
        phase: payload.phase,
        value: payload.value,
        type: payload.type
      }
    }).done(() => {
      dispatch('getCandidateIncomes', payload.candidate)
    })
  },
  getCandidateIncomes ({ commit }, candidateId) {
    $.ajax({
      type: 'GET',
      url: '/api/sales/income',
      data: {
        candidate: candidateId
      }
    }).done(incomes => {
      commit('setCandidateIncomes', { candidate: candidateId, incomes: incomes.results })
    })
  },
  createRenterProfile ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/api/sales/match/' + payload.match + '/renterprofile'
      }).done(data => {
        commit('setRenterProfile', data)
        resolve(data)
      }).fail(error => {
        reject(error)
      })
    })
  },
  saveRenterProfile ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'PATCH',
        url: '/api/sales/renterprofile/' + payload.id,
        data: payload
      }).done(data => {
        commit('setRenterProfile', data)
        resolve(data)
      }).fail(error => {
        reject(error)
      })
    })
  },
  saveTenancy ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      let type = 'POST'
      let url = '/api/sales/renterprofile/' + payload.renter_profile + '/tenancy'

      if (payload.id) {
        type = 'PATCH'
        url += '/' + payload.id
      }

      $.ajax({
        type: type,
        url,
        data: payload
      }).done(() => {
        resolve()
      }).fail(error => {
        reject(error)
      })
    })
  },
  sendRenterProfileReports ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/api/sales/renterprofile/send-report',
        data: payload,
        traditional: true
      }).done(data => {
        resolve()
      }).fail(error => {
        reject(error)
      })
    })
  }
}

// Mutations
const mutations = {
  setLead (state, lead) {
    Vue.set(state.leads, lead.id, lead)
  },
  showFunnelPictures (state) {
    state.showPictures = true
    localStorage.setItem('iam-sales-funnel-show-pictures', true)
  },
  setFollowerLeads (state, value) {
    state.followerLeads = value
    localStorage.setItem('iam-sales-funnel-follower-leads', value)
  },
  setRenterProfile (state, profile) {
    Vue.set(state.renterProfiles, profile.match, profile)
  },
  setCandidateIncomes (state, payload) {
    Vue.set(state.candidateIncomes, payload.candidate, payload.incomes)
  },
  setActivitiesForLead (state, payload) {
    Vue.set(state.activitiesForLead, payload.lead, payload.activities)
  },
  storeActivity (state, payload) {
    let activities = state.activitiesForLead[payload.match]
    if (activities) {
      activities = activities.filter(activity => activity.id !== payload.id)
      activities.push(payload)
    } else {
      activities = [payload]
    }
    Vue.set(state.activitiesForLead, payload.match, activities)
  },
  setFunnelFilterCollaborator (state, collaborator) {
    Vue.set(state, 'filterCollaborator', collaborator)
  },

  setFunnelFilterOffice (state, office) {
    Vue.set(state, 'filterOffice', office)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
