<template>
  <div>
    <div class="col-xs-1 no-padding" style="padding-top: 5px!important;">
      <button class="btn btn-xs btn-danger" type="button" @click="remove">
        <i class="fa fa-minus"></i>
      </button>
    </div>
    <div class="col-xs-11 no-padding">
      <p class="selected_recipient" style="padding-top:8px; margin-bottom: 0px; display: none"></p>
      <ContactPicker v-model="selectedRecipient" :selectionRenderComponent="renderComponent" :canClear="false"
                     @deSelect="clearPicker" @input="select"></ContactPicker>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import RecipientRenderer from './RecipientRenderer'
import ContactPicker from '@/components/contacts/ContactPicker'

import { contacts } from '@/components/iam/SearchProviders'
import { errorModal } from '@/modalMessages'

export default {
  name: 'RecipientPicker',
  components: { ContactPicker },
  props: ['recipient'],
  data () {
    return {
      selectedRecipient: null,
      renderComponent: RecipientRenderer
    }
  },
  mounted () {
    const v = this

    $(this.$el).find('.recipient-picker').typeahead({
      minLength: 3,
      highlight: false
    }, {
      items: 10,
      source: contacts.ttAdapter(),
      display: function (data) {
        return data.display_name
      },
      templates: {
        suggestion: function (value) {
          var str = `<div><strong>${value.display_name}</strong>`
          if (value.mobile) str += `<br><span><i class="glyphicon glyphicon-phone"></i> ${value.mobile}</span>`
          if (value.phone) str += `<br><span><i class="glyphicon glyphicon-earphone"></i> ${value.phone}</span>`
          if (value.email) str += `<br><span><i class="glyphicon glyphicon-envelope"></i> ${value.email}</span>`
          str += '</div>'
          return str
        }
      }
    }
    ).on('typeahead:select', function (event, suggestion) {
      const currentRecipient = v.recipient
      v.selected_recipient = suggestion
      $(v.$el).find('.twitter-typeahead').hide()
      $(v.$el).find('.selected_recipient').append(suggestion.display_name).show()
      this.$emit('add-recipient', currentRecipient, suggestion)
    }).on('typeahead:asyncrequest', function () {
      $(v.$refs.recipient_picker).addClass('loadinggif')
    }).on('typeahead:asynccancel typeahead:asyncreceive', function () {
      $(v.$refs.recipient_picker).removeClass('loadinggif')
    })
    if (v.recipient.recipient) {
      v.selectedRecipient = v.recipient.recipient
    }
  },
  methods: {
    remove () {
      this.$emit('remove-recipient', this.recipient)
    },
    clearPicker () {
      this.selectedRecipient = null
    },
    select () {
      if (this.selectedRecipient.email) {
        this.$emit('select', this.recipient, this.selectedRecipient)
      } else {
        errorModal('Dit contact heeft geen e-mailadres en kan je niet dus gebruiken in de mailclient.')
        this.clearPicker()
        this.remove()
      }
    }
  }
}
</script>

<style scoped>

</style>
