<script>
import { properties } from '@/components/iam/SearchProviders'
import PickerMixin from '@/components/iam/PickerMixin'
import PropertyRenderer from '@/components/iam/PropertyPickerRenderComponent'

export default {
  name: 'PropertyPicker',

  mixins: [PickerMixin],
  props: {
    placeholder: {
      default: function () {
        return 'Selecteer een pand'
      }
    },
    selectionRenderComponent: {
      default: function () {
        return PropertyRenderer
      }
    },
    type: {
      default: function () {
        return 'properties'
      }
    }
  },
  data: function () {
    return {
      source: properties.ttAdapter(),
      suggestionRenderComponent: function (value) {
        return `<div><strong>${value.reference} ${value.street} ${value.number} ${value.box} ${value.city.name}</strong></div>`
      }
    }
  }
}
</script>

<style scoped>

</style>
