export const commissionRoutes = [
  {
    path: '/commission/lists',
    name: 'CommissionLists',
    component: () => import(/* webpackChunkName: "CommissionLists" */ '@/views/commission/CommissionLists'),
    meta: {
      navigationGroupName: 'Commission'
    }
  },
  {
    path: '/commission/lists/:id',
    name: 'CommissionList',
    component: () => import(/* webpackChunkName: "CommissionList" */ '@/views/commission/CommissionList'),
    meta: {
      navigationGroupName: 'Commission'
    }
  },
  {
    path: '/commission/transactions',
    name: 'CommissionTransactions',
    component: () => import(/* webpackChunkName: "CommissionTransactions" */ '@/views/commission/CommissionTransactions'),
    meta: {
      navigationGroupName: 'Commission'
    }
  },
  {
    path: '/commission/transactions2',
    name: 'CommissionTransactions2',
    component: () => import(/* webpackChunkName: "CommissionTransactions" */ '@/views/commission/CommissionTransactions2'),
    meta: {
      navigationGroupName: 'Commission'
    }
  }
]
