/**
 * organization.js
 * Organization store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */
import Vue from 'vue'
import { CollaboratorApi } from '@/api/organization'
import $ from 'jquery'

// Initial state
const state = {
  collaborator: null,
  collaborators: {}
}

// Getters
const getters = {
  getCollaboratorById: (state) => (id) => {
    return state.collaborators[id]
  },
  getCollaboratorByIds: (state) => (payload) => {
    return Object.values(state.collaborators).filter((c) => payload.indexOf(c.id) > -1)
  }
}

// Actions
const actions = {
  getAllColleagues ({ commit }) {
    return new Promise((resolve, reject) => {
      const api = new CollaboratorApi()

      api.getData().then((collaborators) => {
        commit('setCollaborators', collaborators)
        resolve()
      })
    })
  },
  loadCollaboratorById ({ commit, state }, id) {
    return new Promise((resolve, reject) => {
      if (id) {
        const api = new CollaboratorApi()

        api.getItem(id).then((collaborator) => {
          commit('storeCollaborator', collaborator)
          resolve(collaborator)
        })
      } else {
        resolve(null)
      }
    })
  },
  getLogsByCollaboratorId ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/collaborator/' + payload.collaborator.id + '/action_logs',
        type: 'GET'
      }).done((logs) => {
        commit('setLogs', { collaborator: payload.collaborator, logs })
        resolve(logs)
      })
    })
  }
}

// Mutations
const mutations = {
  setColleagues (state, colleagues) {
    state.colleagues = colleagues
  },
  setCollaborators (state, collaborators) {
    collaborators.forEach((collaborator) => {
      Vue.set(state.collaborators, collaborator.id, collaborator)
    })
  },
  storeCollaborator (state, collaborator) {
    Vue.set(state.collaborators, collaborator.id, collaborator)
  },
  setLogs (state, payload) {
    const collaborator = state.collaborators[payload.collaborator.id]
    collaborator.logs = payload.logs
    Vue.set(state.collaborators, collaborator.id, collaborator)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
