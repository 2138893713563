import Vue from 'vue'
import { warningModal, errorModal } from '@/modalMessages'

import { getUserOffices } from '@/services/user'
import { searchProjects } from '@/services/projects'
import { searchTemplates } from '@/services/gutenborgService'
import { fetchCities, createCity } from '@/services/apiService'
import { getOffices, searchCollaborators } from '@/services/organization'
import { mapboxAddressSearch, mapboxCitySearch } from '@/services/mapbox'
import { getContacts, getBirthPlaces, createBirthPlace } from '@/services/contacts'
import { getPropertiesGroup, searchProperties, getPublicationActionTypes } from '@/services/properties'
import { getPoules } from '@/services/poules'

Vue.component('mapbox-option-render', {
  functional: true,
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  render (createElement, context) {
    const place = context.props.value.place_name_nl
    return createElement('span', place)
  }
})

async function searchOrCreateCity (params, payload) {
  // Search if the city already exists
  const response = await fetchCities({ params })
  const cityFound = response?.data?.results[0]
  if (cityFound) return cityFound

  // If city does not exist, create a new one
  return (await createCity(payload)).data
}

async function searchOrCreateBirthPlace (params, payload) {
  // Search if the birtplace already exists
  const response = await getBirthPlaces({ params })
  const birthPlaceFound = response?.data?.results[0]
  if (birthPlaceFound) return birthPlaceFound

  // If birthplace does not exist, create a new one
  return (await createBirthPlace(payload)).data
}

const types = {
  city: {
    textLoading: 'Steden laden',
    textNoResultsFound: 'Geen steden gevonden',
    displayFormat (city) {
      return `${city.zip_code} ${city.name}`
    },
    fetchResults: fetchCities
  },
  office: {
    textLoading: 'Laden van kantoren',
    textNoResultsFound: 'Geen kantoren gevonden',
    displayFormat (office) {
      return office.display_name || `${office.reference} - ${office.name}`
    },
    selectionsLabel (office) {
      return office?.reference || ''
    },
    fetchResults: getOffices
  },
  'user-offices': {
    textLoading: 'Laden van kantoren',
    textNoResultsFound: 'Geen kantoren gevonden',
    displayFormat (office) {
      return office.display_name || ''
    },
    selectionsLabel (office) {
      return office?.display_name || ''
    },
    fetchResults: getUserOffices
  },
  collaborator: {
    textLoading: 'Medewerkers laden',
    textNoResultsFound: 'Geen medewerker gevonden',
    displayFormat: collaborator => collaborator.display_name,
    optionRender: () => import(/* webpackChunkName: "ContactSearchRenderComponent" */ '@/components/iam/ContactSearchRenderComponent'),
    fetchResults: searchCollaborators
  },
  contact: {
    textLoading: 'Contacten laden',
    textNoResultsFound: 'Geen contact gevonden',
    displayFormat: contact => contact.display_name,
    optionRender: () => import(/* webpackChunkName: "ContactSearchRenderComponent" */ '@/components/iam/ContactSearchRenderComponent'),
    fetchResults: getContacts
  },
  group: {
    textLoading: 'Groepen laden',
    textNoResultsFound: 'Geen groepen gevonden',
    displayFormat: group => group.name,
    fetchResults: getPropertiesGroup
  },
  street: {
    textLoading: 'Adressen laden',
    textNoResultsFound: 'Adres niet gevonden',
    optionsKey: 'features',
    displayFormat: feature => feature.text_nl,
    optionRender: 'mapbox-option-render',
    allowText: true,
    fetchResults: mapboxAddressSearch,
    async selectionCallback (result) {
      try {
        const { center, address, context } = result
        if (!(center && context)) return null

        const [longitude, latitude] = center

        let locality = {}
        let city = {}
        let zip_code = ''
        let country = ''

        context.forEach(value => {
          const key = value?.id?.replace(/[^A-Za-z]/g, '') // extract only text string and remove everything else
          switch (key) {
            case 'locality':
              locality = value
              break
            case 'place':
              city = value
              break
            case 'postcode':
              zip_code = value.text
              break
            case 'country':
              country = value.short_code
              break
            default:
              break
          }
        })

        const params = { zip_code, name: city?.text }

        let cityResponse = {}

        if (country === 'be') {
          // For Belgian cities: retrieve them from the database only. We should not allow creating new Belgian cities
          // as the list is considered to be complete
          // For Belgian cities the value under `locality` is considered to be more precise than the one under `place`
          if (Object.keys(locality).length) {
            params.name = locality.text
          }
          const response = await fetchCities({ params })
          const results = response?.data?.results
          if (!results.length) {
            return warningModal(`Belgische gemeente bestaat niet. Contacteer de IT-helpdesk met de vraag om volgende gemeente aan te maken: <span class="tw-font-bold">${params.zip_code} ${params.name}</span>`)
          }
          cityResponse = results[0]
        } else {
          const payload = {
            latitude,
            longitude,
            zip_code,
            country,
            name: city?.text,
            name_en: city?.text_en,
            name_fr: city?.text_fr
          }
          cityResponse = await searchOrCreateCity(params, payload)
        }

        return { city: cityResponse, number: address }
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het maken van de stad, probeer het opnieuw.')
      }
    }
  },
  project: {
    textLoading: 'Projecten laden',
    textNoResultsFound: 'Geen projecten gevonden',
    displayFormat: project => `${project.reference} ${project.street} ${project.number}${project.city ? ', ' + project.city.name : ''}`,
    optionRender: () => import(/* webpackChunkName: "ProjectPickerRenderComponent" */ '@/components/iam/ProjectPickerRenderComponent'),
    fetchResults: searchProjects
  },
  property: {
    textLoading: 'Panden laden',
    textNoResultsFound: 'Geen panden gevonden',
    displayFormat: property => `${property.reference} ${property.street} ${property.number}${property.city ? ', ' + property.city.name : ''}`,
    optionRender: () => import(/* webpackChunkName: "PropertyPickerRenderComponent" */ '@/components/iam/PropertyPickerRenderComponent'),
    fetchResults: searchProperties
  },
  birthplace: {
    textLoading: 'Steden laden',
    textNoResultsFound: 'Geen steden gevonden',
    optionsKey: 'features',
    displayFormat: feature => feature.text_nl || feature.name,
    optionRender: 'mapbox-option-render',
    fetchResults: mapboxCitySearch,
    async selectionCallback (city) {
      try {
        const { context } = city
        if (!context) return null

        let country = ''
        context.forEach(value => {
          const key = value?.id?.replace(/[^A-Za-z]/g, '') // extract only text string and remove everything else
          if (key === 'country') country = value.short_code
        })

        const params = { name: city.text, country }
        const payload = {
          country,
          name: city.text,
          name_en: city.text_en,
          name_fr: city.text_fr
        }

        return await searchOrCreateBirthPlace(params, payload)
      } catch (error) {
        console.log(error)
        errorModal('Fout bij het maken van de stad, probeer het opnieuw.')
      }
    }
  },
  templates: {
    textLoading: 'Templates zoeken ...',
    textNoResultsFound: 'Geen templates gevonden',
    displayFormat (template) {
      return template.name
    },
    fetchResults: searchTemplates
  },
  publicationTypes: {
    textLoading: 'Publicatietypes zoeken ... ',
    textNoResultsFound: 'Geen publicatietypes gevonden',
    displayFormat: type => type.name_nl,
    fetchResults: getPublicationActionTypes
  },
  poules: {
    textLoading: 'Poules zoeken ...',
    textNoResultsFound: 'Geen poules gevonden',
    displayFormat (poule) {
      return poule.poule_name
    },
    fetchResults: getPoules
  }
}

export function getAutoCompleteType (type) {
  return types[type]
}
