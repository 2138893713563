const OrganizationLayout = () =>
  import(/* webpackChunkName: "OrganizationLayout" */ '@/layouts/OrganizationLayout')

export const organizationRoutes = [
  {
    path: '/organization/office',
    name: 'OrganizationOfficeList',
    component: () => import(/* webpackChunkName: "OrganizationOfficeList" */ '@/views/OrganizationOfficeList'),
    meta: {
      layout: OrganizationLayout,
      navigationGroupName: 'Organization'
    }
  }
]
