/**
 * contacts.js
 * Contacts store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */
import Vue from 'vue'
import $ from 'jquery'

import {
  createContact,
  updateContact,
  getContactExportStatus,
  getContactById,
  getContactLinks
} from '@/services/contacts'
import { formatContact } from '@/utils/helpers'

// Initial state
const state = {
  contact: {},
  contacts: {},
  contactsInGroup: {},
  groups: {
    results: []
  },
  contactProperties: {},
  hmodhMailableProperties: {},
  hmodhs: {},
  contactLinks: [],
  contactLeads: {}
}

// Getters
const getters = {
  formattedGroups: state => {
    const groups = state.groups.results
    return groups.map(group => {
      return { value: group.id, name: group.name }
    })
  },
  getContactDocumentsById: state => id => {
    return state.contactDocuments[parseInt(id)]
  },
  getPropertiesForContactById: state => contactId => {
    return state.contactProperties[contactId] || []
  }
}

// Actions
const actions = {
  loadContactById ({ commit }, contactId) {
    commit('setContact', {}) // Clear old state

    return new Promise((resolve, reject) => {
      getContactById(contactId)
        .then(response => {
          commit('setContact', response.data)
          resolve(response)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },

  /****************************
   *        Links             *
   ****************************/

  /**
   * Delete a link from a contact
   * @param commit
   * @param <Link> link Object containing the link information
   * @returns {Promise<any>}
   */
  deleteLink ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `api/contacts/link/${payload.id}`,
        type: 'DELETE'
      }).done(() => {
        commit('deleteLink', payload)
        resolve()
      }).fail((e) => {
        console.error(e)
        reject(e)
      })
    })
  },

  /****************************
   *        Contacts          *
   ****************************/

  async saveContact (context, payload) {
    const contact = formatContact(payload)
    const response = contact.id ? await updateContact(contact) : await createContact(contact)
    return response.data
  },

  /* Updates a HMODH with new values */
  updateHmodh ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `/api/hmodh/${payload.id}`,
        type: 'PATCH',
        data: payload,
        traditional: true
      }).done(data => {
        resolve(data)
      }).fail((e) => {
        console.error(e)
        reject(e)
      })
    })
  },

  /* Creates a new HMODH */
  createHmodh ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/hmodh',
        type: 'POST',
        data: payload,
        traditional: true
      }).done((data) => {
        resolve(data.results)
      }).fail((e) => {
        console.error(e)
        reject(e)
      })
    })
  },

  loadLinksForContactById ({ commit }, contactId) {
    return new Promise((resolve, reject) => {
      getContactLinks(contactId)
        .then(response => {
          const links = response?.data?.results
          commit('setContactLinks', links)
          resolve(links)
        })
        .catch(error => {
          console.error(error)
          reject(error)
        })
    })
  },
  pollContactExportStatus ({ dispatch, commit }, jobId) {
    return new Promise((resolve, reject) => {
      let url = null
      let status = 0
      const poll = window.setInterval(async () => {
        if (!status) {
          const result = await getContactExportStatus(jobId)
          status = result.data.status
          url = result.data.url
        } else {
          clearInterval(poll)
          if (status === 1) {
            const win = window.open(url)
            resolve(win)
          } else {
            reject(new Error('Something went wrong during export'))
          }
        }
      }, 1000)
    })
  }
}

// Mutations
const mutations = {
  setContact (state, payload) {
    state.contact = payload
  },

  setContactScore (state, score) {
    Vue.set(state.contact, 'score', score)
  },

  setContactConsentAsked (state, consent_asked) {
    Vue.set(state.contact, 'consent_asked', consent_asked)
  },

  setContactGlobalUnsubscribe (state, global_unsubscribe) {
    Vue.set(state.contact, 'global_unsubscribe', global_unsubscribe)
  },

  // Groups
  setGroups (state, payload) {
    state.groups = payload
  },

  setArchivedHmodhs (state, hmodhs) {
    hmodhs.forEach(hmodh => Vue.set(state.archivedHmodhs, hmodh.id, hmodh))
  },

  setContactLinks (state, links) {
    state.contactLinks = links
  },

  deleteLink (state, payload) {
    state.contactLinks = state.contactLinks.filter(link => link.id !== payload.id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
