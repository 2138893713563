<template>
  <Trumbowyg
    ref="comp"
    v-model="body"
    :config="editorConfig"
    v-bind="$attrs"
    @tbw-blur="onBlur"
  />
</template>

<script>
import $ from 'jquery'
import Trumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/langs/nl.min.js'
import 'trumbowyg/dist/ui/trumbowyg.css'
import 'trumbowyg/dist/plugins/colors/ui/trumbowyg.colors.css'
import 'trumbowyg/dist/plugins/colors/trumbowyg.colors.min.js'
import 'trumbowyg/dist/plugins/fontfamily/trumbowyg.fontfamily.min.js'

export default {
  name: 'Editor',
  components: {
    Trumbowyg
  },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'input'
  },
  computed: {
    body: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    editorConfig () {
      return {
        btns: [
          ['fontfamily'],
          ['bold', 'italic', 'underline', 'strikethrough'],
          ['foreColor', 'backColor'],
          ['link'],
          ['unorderedList', 'orderedList'],
          ['undo', 'redo']
        ],
        lang: 'nl'
      }
    }
  },
  methods: {
    onBlur () {
      $(this.$refs.comp.$el).trumbowyg('saveRange')
    },
    addToBody (body) {
      $(this.$refs.comp.$el).trumbowyg('restoreRange')
      $(this.$refs.comp.$el).trumbowyg('execCmd', { cmd: 'insertHtml', param: body })
    },
    setBody (body) {
      $(this.$refs.comp.$el).trumbowyg('html', body)
    },
    clear () {
      $(this.$refs.comp.$el).trumbowyg('empty')
    }
  }
}
</script>
