import { httpClient } from './axiosClients'

export function getUser () {
  return httpClient.get('/api/user')
}

export function getUserById (userId) {
  return httpClient.get(`/api/user/${userId}`)
}

export function getUserOffices (payload) {
  const { url = '/api/collaborator/me/offices', params } = payload
  return httpClient.get(url, { params })
}
