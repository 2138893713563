import $ from 'jquery'
import Vue from 'vue'

/**
 * orders.js
 * Authors:
 *  Arne Lanckriet <arne.lanckriet@dewaele.com> (08-06-2020)
 */

const state = {
  orders: {},
  ordersByTodo: {}
}

const getters = {
  getOrdersForTodo: (state) => (id) => {
    return state.ordersByTodo[parseInt(id)]
  }
}

const actions = {
  loadOrdersForTodo ({ commit, state }, todoId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/orders',
        type: 'GET',
        data: {
          todo_id: todoId
        }
      }).done(orders => {
        const payload = {
          todo_id: todoId,
          orders: orders.results
        }
        commit('setOrdersForTodo', payload)
        resolve(orders)
      }).fail(error => {
        reject(error)
      })
    })
  },
  placeOrder ({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/orders/order',
        type: 'POST',
        traditional: true,
        data: {
          todo_id: payload.todoId,
          params: payload.params
        }
      }).done(order => {
        resolve(order)
      }).fail(error => {
        reject(error)
      })
    })
  }
}

const mutations = {
  setOrdersForTodo (state, payload) {
    Vue.set(state.ordersByTodo, payload.todo_id, payload.orders)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
