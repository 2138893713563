<template>
  <div />
</template>

<script>
import moment from 'moment'
import EventBus from '@/components/iam/bus'

import { mapActions, mapGetters } from 'vuex'
import { errorModal, startLoadingModal, stopLoadingModal, warningModal } from '@/modalMessages'
import { createActivity, getLeadById, updateRentioLead } from '@/services/sales'

export default {
  name: 'Controller',
  data () {
    return {
      matchId: null,
      activityId: null,
      mailModalType: null,
      activity: null,
      lead: null,
      property: null,
      project: null
    }
  },
  created () {
    EventBus.$on('sales-confirm-activity', (matchId, activityId) => {
      this.matchId = matchId
      this.activityId = activityId
      this.mailModalType = 'confirm activity'
      this.openMailModal('Bevestiging aan het opstellen...')
    })

    EventBus.$on('sales-send-rapport', (matchId, activityId) => {
      this.matchId = matchId
      this.activityId = activityId
      this.mailModalType = 'send rapport'
      this.openMailModal('E-mail aan het opstellen...')
    })

    EventBus.$on('sales-activity-break', (match, activity) => {
      // Break bij de activiteit screening?
      let type = null
      if (typeof activity.type === 'object') {
        type = activity.type.id
      } else {
        type = parseInt(activity.type)
      }
      if (type === 115 && match.status === 2) {
        const properties = []
        let project = null
        if (match.entity_type === 'property') {
          properties.push(this.$store.getters['properties/getPropertyById'](match.entity))
        }
        if (match.entity_type === 'project') {
          project = this.$store.getters['properties/getProjectById'](match.entity)
        }
        const options = {
          contacts: [{
            type: 'to',
            contact: match.candidate
          }],
          subject: `Jouw bezoekaanvraag voor het pand gelegen te ${match.address}`,
          context: { properties, project },
          template: {
            id: 100,
            language: match.candidate.language.toLowerCase(),
            variables: {
              firstname: match.candidate.first_name,
              property: match.address
            }
          },
          callback: async () => {
            try {
              const activityPayload = {
                type: 114,
                name: 'Afwimpelmail verstuurd',
                start_date: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
                end_date: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
                description: 'Mail verstuurd om de kandidaat te laten weten dat hij/zij niet de financiële ' +
                  'middelen heeft om dit pand te huren.',
                finished: true,
                change_to: 'Break'
              }
              await createActivity(match.id, activityPayload)
              EventBus.$emit('sales-reload-lead')
            } catch (error) {
              console.error(error)
              errorModal('Activiteit voor afwimpelmail kon niet worden aangemaakt.')
            }
          }
        }
        EventBus.$emit('mailclient-prefill-and-show', options)
      }
    })

    EventBus.$on('sales-activity-finished', (match, activity, entity) => {
      /**
       * Lead voor verhuur?
       */
      // no stage if lead is already a deal
      if (match?.stage?.funnel === 3) {
        if (activity.type.id === 2) {
          // Bezoek in de verhuur fase is afgewerkt!

          const options = {
            contacts: [{
              type: 'candidate',
              contact: match.candidate
            }],
            context: {},
            subject: `Interesse om je kandidaat te stellen als huurder voor het pand gelegen te ${match.address}?`,
            template: {
              id: 101,
              language: match.candidate ? match.candidate.language.toLowerCase() : 'nl',
              variables: {
                url: `https://iam.dewaele.com/portal/property?id=${match.entity}`,
                firstname: match.candidate?.first_name,
                lastname: match.candidate?.last_name,
                property: match.address
              }
            },
            callback: async () => {
              try {
                const activityPayload = {
                  type: 114,
                  name: 'Kandidatuurmail verstuurd',
                  start_date: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
                  end_date: moment(Date.now()).format('YYYY-MM-DDTHH:mm'),
                  description: 'Mail verstuurd om de kandidaat te vragen of hij/zij zich kandidaat wil stellen ' +
                    'na een bezoek aan dit pand.',
                  finished: true,
                  status: 4 // finished
                }
                await createActivity(match.id, activityPayload)
                EventBus.$emit('sales-reload-lead')
              } catch (error) {
                console.error(error)
                errorModal('Activiteit voor kandidatuurmail kon niet worden aangemaakt.')
              }
              try {
                await updateRentioLead(match.id)
              } catch (error) {
                // Back-end will throw an error if the lead does not have Rentio renter profile.
                // Notify the user that the application follow-up needs to happen manually.
                console.error(error)
                warningModal('Deze kandidaat heeft geen Rentio-huurprofiel. De kandidatuurstelling moet manueel opgevolgd worden.')
              }
            }
          }

          if (match.entity_type === 'property') options.context.properties = [entity]
          if (match.entity_type === 'project') options.context.project = entity
          EventBus.$emit('mailclient-prefill-and-show', options)
        }
      }
    })
  },
  computed: {
    ...mapGetters('account', ['collaborator'])
  },
  methods: {
    ...mapActions('sales', ['loadActivitiesForLead']),
    ...mapActions('properties', ['loadProject', 'loadProperty']),
    /* Opens the mail modal */
    async openMailModal (swalMessage) {
      try {
        startLoadingModal(swalMessage)
        const lead = await this.loadLead(this.matchId)
        const activities = await this.loadActivitiesForLead(lead.id)
        this.activity = activities.filter(activity => activity.id === this.activityId)[0]

        let entity = null
        if (lead.entity_type === 'property') {
          entity = await this.loadProperty(lead.entity)
        } else if (lead.entity_type === 'project') {
          entity = await this.loadProject(lead.entity)
        } else {
          throw new Error(`Invalid entity passed ${lead.entity}.`)
        }
        stopLoadingModal()
        this.fillMailModal(entity)
        return entity
      } catch (e) {
        console.error(e)
        errorModal('Kan mailclient niet openen, gelieve opnieuw te proberen.')
      }
    },

    async loadLead (leadId) {
      const response = await getLeadById(leadId)
      this.lead = response.data
      return response.data
    },

    /* Send emit to start the confirmation */
    fillMailModal (entity) {
      // Get entity, lead, contact and activity
      if (this.lead.entity_type === 'property') this.property = entity
      if (this.lead.entity_type === 'project') this.project = entity
      const lead = this.lead
      const activity = this.activity

      // Build mail modal fill content
      let subject = null
      let templateId = null
      let contactType = 'to'
      let contacts = []
      let external_feedback = ''
      const address = `${entity.city.name}, ${entity.street} ${entity.number} ${entity.box}`

      const visitConfirmationTranslations = {
        nl: `Bevestiging van jouw bezoek voor het pand gelegen te ${address}`,
        fr: `Confirmation de votre visite pour le bien situé à ${address}`,
        en: `Confirmation of your visit for the property located in ${address}`
      }

      const reportTranslations = {
        nl: `Rapport voor het pand gelegen te ${address}`,
        fr: `Rapport pour le bien situé à ${address}`,
        en: `Report for the property located in ${address}`
      }

      if (this.mailModalType === 'confirm activity') {
        templateId = 110
        contactType = 'candidate'
        contacts = lead.candidates || [lead.owner]
        subject = visitConfirmationTranslations[contacts[0]?.language]
      } else if (this.mailModalType === 'send rapport') {
        templateId = 111
        contacts = [lead.owner]
        if (this.activity.external_feedback) {
          external_feedback = this.activity.external_feedback
        }
        subject = reportTranslations[contacts[0]?.language]
      } else {
        throw new Error(`Invalid mail modal type specified: '${this.mailModalType}'`)
      }

      const candidate_name = lead.candidate?.display_name
      const emitData = {
        contacts: contacts.map(contact => {
          return {
            type: contactType,
            contact
          }
        }),
        subject,
        template: {
          id: templateId,
          language: contacts[0].language.toLowerCase(),
          variables: {
            firstname: contacts.length === 1 ? contacts[0].first_name : '',
            property: lead.address,
            date: moment(activity.start_date).format('DD-MM-YYYY'),
            time: moment(activity.start_date).format('HH:mm'),
            collaborator: this.collaborator.display_name,
            external_feedback,
            candidate_name,
            address
          }
        },
        context: {
          properties: null,
          project: null,
          confirmationForActivity: activity.id
        },
        callback: {
          callback: () => EventBus.$emit('sales-reload-lead')
        }
      }

      if (this.lead.entity_type === 'property') emitData.context.properties = [this.property]
      if (this.lead.entity_type === 'project') emitData.context.project = this.project
      // Emit fill content and have the mailmodal built
      EventBus.$emit('mailclient-prefill-and-show', emitData)
    }
  }
}
</script>
