<template>
  <span>{{ value.display_name }}</span>
</template>

<script>
export default {
  name: 'ContactPickerRenderComponent',
  props: ['value']
}
</script>
