<template>
  <div :data-type="context.type" class="tw-mt-1">
    <button
      v-for="lang in LANGS"
      :key="`tab-button-${lang}`"
      type="button"
      :title="lang"
      :aria-selected="isLangSelected(lang)"
      :class="[
        'tw-my-1 tw-px-4 tw-py-1 tw-rounded tw-font-semibold tw-uppercase hover:tw-underline',
        { 'tw-bg-white tw-shadow': isLangSelected(lang) }
      ]"
      @click.stop="activeLang = lang"
    >
      {{ lang }}
    </button>
    <textarea
      v-for="lang in LANGS"
      v-show="isLangSelected(lang)"
      :value="getValue(lang)"
      :id="`${context.attributes.id}_${lang}`"
      :key="`tab-content-${lang}`"
      :name="`${context.attributes.name}_${lang}`"
      :placeholder="getPlaceholder(lang)"
      v-bind="context.attributes"
      class="tw-h-20"
      @input="setValue(lang, $event)"
      @blur="context.blurHandler"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseInputTranslatable',
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  constants: {
    LANGS: ['nl', 'fr', 'en']
  },
  data () {
    return {
      activeLang: 'nl'
    }
  },
  methods: {
    isLangSelected (lang) {
      return this.activeLang === lang
    },
    getPlaceholder (lang) {
      const placeholder = this.context.attributes.placeholder
      if (typeof placeholder === 'string') return placeholder

      const defaultPlaceholder = {
        nl: 'Nederlandse vertaling',
        fr: 'Traduction française',
        en: 'English translation'
      }
      return placeholder?.[lang] || defaultPlaceholder[lang]
    },
    getValue (lang) {
      return this.context.model[`text_${lang}`] || ''
    },
    setValue (lang, $event) {
      const value = $event.target.value
      const { text_nl = '', text_fr = '', text_en = '' } = this.context.model
      // We do this in order to set the default or updated values for the fields, if the context model was cleared
      this.context.model = Object.assign({}, this.context.model, { text_nl, text_fr, text_en, [`text_${lang}`]: value })
    }
  }
}
</script>
