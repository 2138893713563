/**
 * index.js
 * Vuex store module for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (26-09-2018)
 */
import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import contacts from './modules/contacts'
import organization from './modules/organization'
import properties from './modules/properties'
import sales from './modules/sales'
import budget from './modules/budget'
import flyer from './modules/flyer'
import orders from './modules/orders'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    account,
    contacts,
    organization,
    properties,
    sales,
    budget,
    flyer,
    orders
  },
  strict: debug
})
