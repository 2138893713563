import Vue from 'vue'
import VueFormulate from '@braid/vue-formulate'
import { nl } from '@braid/vue-formulate-i18n'
import { validatePropertyReference } from '@/services/properties'

import ERROR_CODE_MAP from '@/forms/errorCodes.json'

function handleErrorCode (error) {
  let code = ''
  if (Array.isArray(error?.response?.data?.code)) {
    code = error?.response?.data?.code?.[0]
  } else {
    code = error?.response?.data?.code
  }
  return ERROR_CODE_MAP[code] || ''
}

// ErrorHandler
const apiErrorHandler = function (error) {
  const status = error?.response?.status
  const genericErr = `${status}: Er is iets misgegaan.`

  const errorData = {
    inputErrors: error?.response?.data,
    formErrors: []
  }

  let nonFieldErrorsString = ''
  const nonFieldErrors = error?.response?.data?.non_field_errors
  if (nonFieldErrors) {
    nonFieldErrorsString = nonFieldErrors.join(' | ')
  }

  const customErrorCodeValue = handleErrorCode(error)

  status === 500
    ? errorData.formErrors.push(
      `${status}: Interne serverfout, probeer aub opnieuw.`
    )
    : errorData.formErrors.push(
        `${customErrorCodeValue || error?.response?.data?.detail || nonFieldErrorsString || genericErr}`
    )

  return errorData
}

const options = {
  plugins: [nl],
  errorHandler: apiErrorHandler,

  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  rules: {
    phone: ({ value }) =>
      !!value.match(
        /^\s*(?:\+?(\d{1,3}))?[ (]*(\d{3,4})[-. )]*(\d{3,4})[-. ]*(\d{3,4})\s*$/
      ),
    requiredTranslations: ({ value }, ...langs) => langs.every(lang => value?.['text_' + lang]?.trim()),
    landShareOrInfrastructureIsRequired ({ getFormValues }) {
      const values = getFormValues()
      const priceLandShare = values.price_land_share
      const priceInfrastructure = values.price_infrastructure
      return !!(parseFloat(priceInfrastructure) || parseFloat(priceLandShare))
    },
    validVatNumber ({ value }) {
      const isBeVat = value.toLowerCase().includes('be')
      if (!isBeVat) return true

      const regex = /^BE\s[01]\d{3}\.\d{3}\.\d{3}$/
      return regex.test(value)
    },
    async validatePropertyReference ({ value }, excludedReference) {
      // We allow excluding the current reference of the property as when editing, that validation will always fail.
      if (excludedReference && (value === excludedReference)) return true
      const response = await validatePropertyReference(value)
      return response.data?.valid_reference
    },
    validInvoicingShares ({ value }) {
      if (!value.length) return true
      const values = value.map(value => (value.invoicing_share != null ? parseFloat(value.invoicing_share) : 0))
      const sum = values.reduce((acc, num) => acc + num, 0)
      return sum === 100
    }
  },

  locale: 'nl',
  locales: {
    nl: {
      phone: () => 'Voer een geldig nummer in',
      requiredTranslations ({ name, value, args }) {
        const pending = args.filter(arg => !value?.['text_' + arg]).join(', ')
        return `${name} vereist de volgende waarden: ${pending}`
      },
      landShareOrInfrastructureIsRequired: () => 'Prijs grondaandeel of prijs constructiewaarde is verplicht',
      validVatNumber: ({ name }) => `${name} is ongeldig, gebruik het juiste formaat.`,
      validatePropertyReference: () => 'Er bestaat al een pand met deze referentie',
      validInvoicingShares: () => 'De som van de verdeelsleutel voor de facturatie moet 100% zijn.'
    }
  },

  library: {
    autocomplete: {
      classification: 'text',
      component: 'BaseInputAutoComplete',
      slotProps: {
        component: [
          'autoCompleteType',
          'multiple',
          'params',
          'unpackChildren',
          'showResultsOnFocus',
          'stayOpenAfterSelection',
          'hideClearButton'
        ]
      }
    },
    dial_code_tel: {
      classification: 'text',
      component: 'BaseInputDialCodeTel'
    },
    toggle: {
      classification: 'toggle',
      component: 'BaseInputToggle'
    },
    timepicker: {
      classification: 'select',
      component: 'BaseInputTimePicker',
      slotProps: {
        component: ['hours', 'minutes']
      }
    },
    multiselect: {
      classification: 'select',
      component: 'BaseInputMultipleSelect'
    },
    translatable: {
      classification: 'textarea',
      component: 'BaseInputTranslatable'
    },
    tagselect: {
      classification: 'tagselect',
      component: 'BaseInputTagSelect',
      slotProps: {
        component: ['taggable', 'allowRemove', 'showTagsOnClose', 'excludeAutomatedTags']
      }
    }
  },

  slotComponents: {
    label: 'BaseInputLabel'
  },

  slotProps: {
    label: ['labelIcon', 'showRequiredLabel', 'infoText']
  },

  classes: {
    outer: 'tw-my-4',
    wrapper ({ classification }) {
      const styles = {
        toggle: 'tw-flex tw-items-center tw-gap-2'
      }

      return styles[classification]
    },
    element ({ classification }) {
      const styles = {
        box: 'tw-w-auto tw-inline-block tw-align-bottom'
      }

      return styles[classification]
    },
    input ({ classification }) {
      const styles = {
        text: 'formulate-text',
        textarea: 'formulate-textarea',
        select: 'formulate-select',
        button: 'formulate-button'
      }

      return styles[classification]
    },
    label: 'formulate-label',
    help: 'formulate-help',
    error: 'input-error tw-whitespace-pre-wrap' // Whitespace in the input shows as a single whitespace if we don't use whitespace-pre
  }
}

export function setupFormulate () {
  Vue.use(VueFormulate, options)
}
