import { httpClient } from './axiosClients'

export function getSurveys (payload) {
  const { url = '/api/surveys', params } = payload
  return httpClient.get(url, { params })
}

export function getSurveysAverage (params) {
  return httpClient.get('/api/surveys/average', { params })
}

export function getSurveyDetails (surveyId) {
  return httpClient.get(`/api/surveys/${surveyId}`)
}

export function createSurveyComment (surveyId, payload) {
  return httpClient.post(`/api/surveys/${surveyId}/comments`, payload)
}

export function updateSurveyComment (commentId, payload) {
  return httpClient.patch(`/api/survey-comments/${commentId}`, payload)
}
