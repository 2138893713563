/**
 * budget.js
 * Budget store for IAM.
 * Authors:
 *  Michael Anckaert <michael.anckaert@sinax.be> (27-12-2018)
 */

/**
 * Local store for the Budget state. This state contains all user and account related state
 */
import $ from 'jquery'
import Vue from 'vue'

/**
 * Initial state of the Account.
 * @type {{user: {}}}
 */
const state = {
  suppliers: null,
  distributions: null,
  budgets: null,
  categories: null, // Array of categories
  products: null, // Array of products
  productOrders: null,
  invoices: null,
  financialReport: null
}

// Getters
const getters = {
  getDistributions: (state) => () => {
    return state.distributions
  },
  getBudgets: (state) => () => {
    return state.budgets
  },
  getSuppliers: (state) => () => {
    return state.suppliers
  },
  getBudget:
    (state) => (id) => {
      if (state.budgets) {
        return state.budgets.filter((budget) => budget.id === parseInt(id))[0]
      }
      return null
    },
  getCategories:
    (state) => (payload) => {
      if (state.categories) {
        return state.categories.filter((category) => category.strategic_level === payload.strategic_level)
      }
      return null
    },
  getProducts:
    (state, getters, rootState) => (payload) => {
      if (state.products) {
        if (rootState.account.user && rootState.account.user.is_staff) {
          return state.products.filter((prod) => prod.category.id === payload.category)
        } else {
          return state.products.filter((prod) => prod.category.id === payload.category && prod.restricted === false)
        }
      }
      return null
    },
  getProductOrders:
    (state) => (budgetId) => {
      return state.productOrders
    },
  getProductOrdersForLevel:
    (state) => (level) => {
      if (!level) {
        return []
      }
      if (state.productOrders && level) {
        return state.productOrders.filter((order) => order.strategic_level === level.id)
      }
    },
  getFinancialReport: (state) => () => {
    return state.financialReport
  }
}

// Actions
const actions = {
  async loadFinancialReport ({ commit }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: '/api/budget/report-financial'
      }).done((report) => {
        commit('setFinancialReport', report)
        resolve(report)
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadSuppliers ({ commit }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/budget/suppliers'
      }).done((data) => {
        commit('setSuppliers', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadDistributions ({ commit }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/budget/distributions'
      }).done((data) => {
        commit('setDistributions', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadBudgets ({ commit }, year) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/budget/budgets',
        data: { year: year }
      }).done((data) => {
        commit('setBudgets', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadBudget ({ commit }, budgetId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: `/api/budget/budgets?budget=${budgetId}`
      }).done((data) => {
        commit('setBudgets', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadCategories ({ commit }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/budget/categories'
      }).done((data) => {
        commit('setCategories', data.results)
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadProducts ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: `/api/budget/products?strategic_level=${payload.strategic_level}`
      }).done((data) => {
        commit('setProducts', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadProductOrders ({ commit }, budgetId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: `/api/budget/productorders?budget=${budgetId}`
      }).done((data) => {
        commit('setProductOrders', data.results)
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  saveProductOrder ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      let action = 'POST'
      let url = '/api/budget/productorders'

      if (payload.order.id) {
        action = 'PATCH'
        url = url + '/' + payload.order.id
      }

      $.ajax({
        type: action,
        url: url,
        data: payload.order
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  deleteProductOrder ({ dispatch, commit }, orderId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'DELETE',
        url: `/api/budget/productorders/${orderId}`
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  saveOrder ({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      let action = 'POST'
      let url = '/api/budget/orders'

      if (payload.order.id) {
        action = 'PATCH'
        url = url + '/' + payload.order.id
      }

      $.ajax({
        type: action,
        url: url,
        data: payload.order
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  deleteOrder ({ dispatch, commit }, orderId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'DELETE',
        url: `/api/budget/orders/${orderId}`
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  reOpenOrder ({ dispatch, commit }, orderId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: `/api/budget/orders/${orderId}/reopen`
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  mutateBudget ({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/api/budget/mutation',
        data: payload
      }).done(() => {
        resolve()
      }).fail((error) => {
        console.error(error)
        reject(error)
      })
    })
  },
  saveInvoice ({ dispatch, commit }, payload) {
    let action = 'POST'
    let url = '/api/budget/invoices'

    if (payload.id) {
      action = 'PATCH'
      url = url + '/' + payload.id
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        type: action,
        url: url,
        data: payload
      }).done(() => {
        resolve()
      }).fail((error) => {
        reject(error)
      })
    })
  },
  resetInvoice ({ dispatch, commit }, invoiceId) {
    const action = 'POST'
    const url = `/api/budget/invoices/${invoiceId}/reset`

    return new Promise((resolve, reject) => {
      $.ajax({
        type: action,
        url: url,
        data: { id: invoiceId }
      }).done(() => {
        resolve()
      }).fail((error) => {
        reject(error)
      })
    })
  },
  deleteInvoice ({ dispatch, commit }, invoiceId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'DELETE',
        url: `/api/budget/invoices/${invoiceId}`
      }).done(() => {
        resolve()
      }).fail((error) => {
        reject(error)
      })
    })
  }
}

// Mutations
const mutations = {
  setSuppliers (state, suppliers) {
    if (suppliers.length > 0) {
      state.suppliers = suppliers
    } else {
      state.suppliers = null
    }
  },
  setDistributions (state, distributions) {
    if (distributions.length > 0) {
      state.distributions = distributions
    } else {
      state.distributions = null
    }
  },
  setBudgets (state, budgets) {
    if (budgets.length > 0) {
      state.budgets = budgets
    } else {
      state.budgets = null
    }
  },
  setCategories (state, categories) {
    if (categories.length > 0) {
      state.categories = categories
    } else {
      state.categories = null
    }
  },
  setProducts (state, products) {
    if (products.length > 0) {
      state.products = products
    } else {
      state.products = null
    }
  },
  setProductOrders (state, orders) {
    if (orders.length > 0) {
      state.productOrders = orders
    } else {
      state.productOrders = null
    }
  },
  setProductOrder (state, order) {
    const existingOrder = state.productOrders.filter((o) => o.id === order.id)[0]
    if (existingOrder) {
      const idx = state.productOrders.indexOf(existingOrder)
      state.productOrders.splice(idx, 1)
    }
    if (!state.productOrders) {
      state.productOrders = []
    }
    state.productOrders.push(order)
  },
  setFinancialReport (state, report) {
    Vue.set(state, 'financialReport', report)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
