const ContactsLayout = () =>
  import(/* webpackChunkName: "ContactsLayout" */ '@/layouts/ContactsLayout')

export const contactsRoutes = [
  {
    path: '/contacts',
    name: 'ContactSearch',
    component: () => import(/* webpackChunkName: "ContactSearch" */ '@/views/contacts/ContactSearch'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/contacts',
    name: 'ContactList',
    component: () => import(/* webpackChunkName: "ContactList" */ '@/views/contacts/ContactList'),
    meta: {
      layout: ContactsLayout,
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/connector',
    name: 'ContactConnector',
    component: () => import(/* webpackChunkName: "ContactConnector" */ '@/views/contacts/ContactConnector'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/hmodh/search',
    name: 'HmodhSearch',
    component: () => import(/* webpackChunkName: "HmodhSearch" */ '@/views/contacts/hmodh/HmodhSearch'),
    meta: {
      layout: ContactsLayout,
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/groups',
    name: 'ContactGroups',
    component: () => import(/* webpackChunkName: "ContactGroups" */ '@/views/contacts/ContactGroups'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/export',
    name: 'ContactExport',
    component: () => import(/* webpackChunkName: "ContactExport" */ '@/views/contacts/ContactExport'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/tags/:id',
    name: 'ContactTagDetails',
    component: () => import(/* webpackChunkName: "ContactTagDetails" */ '@/views/contacts/ContactTagDetails'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/contact/:id/edit',
    name: 'ContactEdit',
    component: () => import(/* webpackChunkName: "ContactEdit" */ '@/views/contacts/ContactEdit'),
    meta: {
      navigationGroupName: 'Contacts'
    }
  },
  {
    path: '/contacts/contact/:id', // id is watched for in the ContactsLayout as key for router-view
    component: () => import(/* webpackChunkName: "ContactDetailsLayout" */ '@/layouts/ContactDetailsLayout'),
    children: [
      {
        path: 'general',
        name: 'ContactDetails',
        component: () => import(/* webpackChunkName: "ContactGeneral" */ '@/views/contacts/ContactGeneral'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'properties',
        name: 'ContactEntities',
        component: () => import(/* webpackChunkName: "ContactEntities" */ '@/views/contacts/ContactEntities'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'rentals',
        name: 'ContactRentals',
        component: () => import(/* webpackChunkName: "ContactRentals" */ '@/views/contacts/ContactRentals'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'communication',
        name: 'ContactCommunication',
        component: () => import(/* webpackChunkName: "ContactCommunication" */ '@/views/contacts/ContactCommunication'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'leads',
        name: 'ContactLeads',
        component: () => import(/* webpackChunkName: "LeadPanel" */ '@/views/portfolio/LeadPanel'),
        meta: {
          navigationGroupName: 'Contacts',
          isContactRoute: true
        }
      },
      {
        path: 'files',
        name: 'ContactFiles',
        component: () => import(/* webpackChunkName: "ContactFiles" */ '@/views/contacts/ContactFiles'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'newsletters',
        name: 'Newsletters',
        component: () => import(/* webpackChunkName: "Newsletters" */ '@/views/contacts/Newsletters'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'my-properties',
        name: 'ContactProperties',
        component: () => import(/* webpackChunkName: "ContactProperties" */ '@/views/contacts/ContactProperties'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'tag-history',
        name: 'ContactTagHistory',
        component: () => import(/* webpackChunkName: "ContactTagHistory" */ '@/views/contacts/ContactTagHistory'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      {
        path: 'invoices',
        name: 'ContactInvoices',
        component: () => import(/* webpackChunkName: "TransactionListInvoices" */ '@/views/transactions/TransactionListInvoices'),
        meta: {
          navigationGroupName: 'Contacts'
        }
      },
      // Redirects, should stay at the bottom.
      {
        path: '', // This is added to route from /portfolio/properties/:id to '/portfolio/properties/:id/general
        redirect: { name: 'ContactDetails' }
      },
      {
        path: '*', // For catching all non-existent routes
        redirect: { name: 'ContactDetails' }
      }
    ]
  }
]
