<template>
  <li style="margin-bottom: 5px;">
    <i class="fa fa-file tw-mr-1" />
    <span class="file-name">{{attachment.filename}}</span>
    <br>
    <span class="file-title">{{attachment.title}}</span>
    <a class="btn btn-xs btn-danger" style="margin-left: 5px; margin-right: 15px; position: absolute; right: 5px;"
       @click="remove">
      <i class="fa fa-times"></i>
    </a>
  </li>
</template>

<script>
export default {
  name: 'Attachment',
  props: ['attachment'],
  data () {
    return {}
  },
  methods: {
    remove () {
      this.$emit('remove', this.attachment)
    }
  }
}
</script>

<style scoped>

.file-name {
  font-weight: bold;
}

.file-title {
  font-style: italic;
  padding-bottom: 3px;
}
</style>
