import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/styles/main.css'
import '@/utils/filters'
import { importGlobalComponents } from '@/components/base/globals'
import { setupFormulate } from '@/forms/formulate'
import { installConstantsPlugin } from '@/plugins/constants'

import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import './registerServiceWorker'

const sentryConfig = {
  Vue,
  dsn: 'https://854e05c3503547fcac59a8e1b4dac34a@o382168.ingest.sentry.io/5210613',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: 'core@' + process.env.VUE_APP_VERSION,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.001
}

if (process.env.VUE_APP_ENVIRONMENT) Sentry.init(sentryConfig)

importGlobalComponents() // First import all global components
installConstantsPlugin() // Install support for constants inside vue components
setupFormulate() // Then setup formulate with those components as base

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
