<template>
  <div>
    <span><i class="fa fa-building"></i> <strong>{{ value.name }}</strong><br></span>
    <span><i class="fa"></i> {{ value.street }} {{ value.number }} {{value.box}}, {{ value.city.name }}<br></span>
    <span><i class="fa"></i> {{ value.reference }}<br></span>
  </div>
</template>

<script>
export default {
  name: 'ProjectRenderComponent',
  props: ['value']
}
</script>

<style scoped>
  i.fa {
    width: 15px;
  }
</style>
