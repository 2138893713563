<template>
  <!-- Toggle Button -->
  <label :for="context.id" :data-type="context.type" :class="['tw-my-auto tw-cursor-pointer', context.classes.element]">
    <!-- toggle -->
    <div class="tw-relative">
      <!-- input -->
      <input
        v-model="context.model"
        type="checkbox"
        v-bind="context.attributes"
        class="toggle__line tw-hidden tw-absolute tw-rounded-full tw-border-none tw-cursor-pointer"
        @blur="context.blurHandler"
      />
      <!-- line -->
      <div
        class="toggle__line tw-bg-gray-cc tw-border-2 tw-border-gray-cc tw-rounded-full"
      />
      <!-- dot -->
      <div
        class="toggle__dot tw-absolute tw-w-5 tw-h-5 tw-my-auto tw-bg-white tw-rounded-full tw-inset-y-0"
      />
    </div>
  </label>
</template>

<script>
export default {
  name: 'BaseInputToggle',
  props: {
    context: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.toggle__dot {
  left: 2px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16),
    0px 3px 1px rgba(0, 0, 0, 0.1);
}
input:checked ~ .toggle__dot {
  transform: translateX(14px);
}
.toggle__line {
  /* To override margins via bootstrap */
  margin: 0;
  width: 38px;
  height: 24px;
  transition: all 0.3s ease-in-out;
}
input:checked ~ .toggle__line {
  @apply tw-bg-success;
  @apply tw-border-success;
}
</style>
