import Vue from 'vue'
import $ from 'jquery'

// Initial state
const state = {
  templates: [],
  savedFlyers: {}
}

// Getters
const getters = {
  getSavedFlyers: (state) => () => {
    return state.savedFlyers
  }
}

// Actions
const actions = {
  saveFlyerData ({ commit }, data) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'POST',
        url: '/api/print/saved',
        data: {
          name: data.flyerName,
          TYPE: 0,
          office: data.office,
          selected_template: data.selectedTemplate,
          data: JSON.stringify(data.itemsFormatted),
          collaborator: data.collaborator
        }
      }).done(savedFlyers => {
        commit('setSavedFlyers', [savedFlyers])
        resolve(savedFlyers)
      }).fail(error => {
        console.error(error)
        reject(error)
      })
    })
  },
  loadSavedFlyers ({ commit }) {
    return new Promise((resolve, reject) => {
      $.ajax({
        type: 'GET',
        url: '/api/print/saved'
      }).done(savedFlyers => {
        commit('setSavedFlyers', savedFlyers.results)
        resolve(savedFlyers.results)
      }).fail(error => {
        console.error(error)
        reject(error)
      })
    })
  }
}

// Mutations
const mutations = {
  setTemplates (state, templates) {
    state.templates = templates
  },
  setSavedFlyers (state, savedFlyers) {
    for (const flyer of savedFlyers) {
      Vue.set(state.savedFlyers, flyer.id, flyer)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
