import swal from 'sweetalert2'
import toastr from 'toastr'

function successModal (message, asToastr = true, optionsOverride) {
  if (asToastr) {
    toastr.success(message, '', optionsOverride)
    swal.close()
  } else {
    swal({ html: message, type: 'success' })
  }
}

function errorModal (message, asToastr) {
  if (asToastr) {
    toastr.error(message)
    swal.close()
  } else {
    swal({ title: 'Fout', html: message, type: 'error' })
  }
}

function infoModal (message, asToastr) {
  if (asToastr) {
    toastr.info(message)
    swal.close()
  } else {
    swal({ title: 'Ter info', html: message, type: 'info' })
  }
}

function warningModal (message, asToastr) {
  if (asToastr) {
    toastr.warning(message)
    swal.close()
  } else {
    swal({ title: 'Opgelet', html: message, type: 'warning' })
  }
}

async function questionModal (message, title = 'Ben je zeker?') {
  return swal({
    title,
    html: message,
    type: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Ja',
    cancelButtonText: 'Nee'
  })
}

function startLoadingModal (message) {
  swal({ title: 'Even geduld a.u.b.', html: message, allowOutsideClick: false })
  swal.showLoading()
}

function stopLoadingModal () {
  swal.close()
}

const ERRORS_PORTAL_AUTH = {
  GENERAL: 'Er heeft zich een fout voorgedaan. Probeer het later opnieuw.',
  PORTAL_EMAIL_ALREADY_EXISTS: 'Een ander contact heeft al een portaalaccount met dit e-mailadres.',
  PASSWORDS_DO_NOT_MATCH: 'De wachtwoorden komen niet overeen.',
  PASSWORDS_REQUIRED: 'Beide wachtwoorden moeten worden ingevuld'
}

export { successModal, errorModal, infoModal, warningModal, questionModal, startLoadingModal, stopLoadingModal, ERRORS_PORTAL_AUTH }
