<template>
  <div
    :class="['tw-flex tw-flex-row tw-items-center', context.classes.element]"
    :data-type="context.type"
  >
    <div class="tw-w-10">
      <select
        v-model="hh"
        v-bind="context.attributes"
        @blur="context.blurHandler"
        @change="setTime($event.target.value, 'hh')"
      >
        <option disabled value="">hh</option>
        <option
          v-for="hr in hours"
          :key="hr"
          :value="hr.toString().padStart(2, '0')"
        >
          {{ hr.toString().padStart(2, '0') }}
        </option>
      </select>
    </div>
    <div class="tw-mx-1">:</div>
    <div class="tw-w-10">
      <select
        v-model="mm"
        v-bind="context.attributes"
        @blur="context.blurHandler"
        @change="setTime($event.target.value, 'mm')"
      >
        <option disabled value="">mm</option>
        <option
          v-for="min in minutes"
          :key="min"
          :value="min.toString().padStart(2, '0')"
        >
          {{ min.toString().padStart(2, '0') }}
        </option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseInputTimePicker',
  props: {
    context: {
      type: Object,
      required: true
    },
    hours: {
      type: Array,
      default: () => {
        return Array.from(Array(24).keys())
      }
    },
    minutes: {
      type: Array,
      default: () => {
        return Array.from(Array(60).keys())
      }
    }
  },
  data () {
    return {
      hh: this.hours[0].toString().padStart(2, '0'),
      mm: this.minutes[0].toString().padStart(2, '0')
    }
  },
  computed: {
    model: {
      get () {
        return this.context.model
      },
      set (val) {
        this.context.model = val
      }
    }
  },
  created () {
    this.setTime()
  },
  methods: {
    setTime (time, type) {
      const hh = type === 'hh' ? time : this.hh
      const mm = type === 'mm' ? time : this.mm

      this.model = parseInt(hh) * 3_600_000 + parseInt(mm) * 60_000
    }
  }
}
</script>

<style scoped>
select,
select > option {
  @apply tw-p-0;
  @apply tw-appearance-none;
  @apply tw-bg-none;
  @apply tw-text-center;
  @apply tw-text-base;
  text-align: -webkit-center;
  text-align-last: center;
}
</style>
