export function getChatCompletions (payload) {
  return fetch(process.env.VUE_APP_MIA_API_URL + '/chat', {
    headers: {
      'Content-Type': 'application/json',
      Authorization:
        'Basic ' +
        btoa(
          process.env.VUE_APP_MIA_API_USERNAME +
            ':' +
            process.env.VUE_APP_MIA_API_PASSWORD
        )
    },
    method: 'POST',
    body: JSON.stringify(payload)
  })
}
