export const toolsRoutes = [
  {
    path: '/tools/transfer',
    name: 'TransferTools',
    component: () => import(/* webpackChunkName: "TransferTools" */ '@/views/tools/TransferTools'),
    meta: {
      navigationGroupName: 'Tools'
    }
  },
  {
    path: '/tools/organimmo-import',
    name: 'OrganimmoImport',
    component: () => import(/* webpackChunkName: "OrganimmoImport" */ '@/views/tools/OrganimmoImport'),
    meta: {
      navigationGroupName: 'Tools'
    }
  }
]
